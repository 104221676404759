import React from 'react'
import { Container, Row, Col } from "reactstrap";

const Footer = () => (
  <footer className="footer">
    <Container fluid>
      <Row className="text-muted">
        <Col xs="12" sm="6" className="text-left text-center-xs">
          <p className="mb-0 text-center text-left-sm">
            <span className="text-muted">
              IEEPA.ORG{' '}
            </span>
            <span className="text-muted">
              京ICP备17051542号
            </span>
          </p>
        </Col>
        <Col xs="12" sm="6">
          <p className="mb-0 text-center text-right-sm">
            &copy; {new Date().getFullYear()}{" "}
            <span className="text-muted">
              北京市国际生态经济协会
            </span>
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
