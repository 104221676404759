import React, { useEffect, useState, useContext, useMemo, useCallback } from 'react'

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  Input,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  Badge,
  ModalFooter,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap'

import classnames from 'classnames'

import Datetime from 'react-datetime'

import { Value } from 'slate'

import { useImmer } from 'use-immer'
import { AppContext } from '../App'
import delay from 'delay'

import { LOCALE_LABELS, SPACE_CHAR } from '../api'

import ActionButton from '../components/Button'
import { Icon } from '../utils'
import ValueInput from '../components/ValueInput'

import { api, useGlobal } from '../store'
import { openFile, uploadFile } from '../utils/file'

import {
  isPlainObject,
  isError,
  isDate,
  get,
  set,
  pick,
  uniq,
  omit,
  snakeCase,
  lowerCase,
  defaults,
  find,
  extend,
  cloneDeep,
  isArray,
  isEmpty,
  times,
  last,
} from 'lodash'

import queryString from 'query-string'
import { Link } from 'react-router-dom'
import moment, { Moment } from 'moment'
import ReactQuill from 'react-quill'
import Spacer from '../components/Spacer'

import { KeywordsEditor } from './Post'
import TextEditor from '../components/TextEditor'
import CategoryDropdown from '../components/CategoryDropdown'

import LangNav from '../components/LangNav'

import config from '../config'
import { cleanNodes, FILE_ICON } from '../utils'

const PostEditor = ({ id, history, onClose, updateRows, defaultLang }) => {
  const app = useContext(AppContext)

  const [state, actions] = useGlobal()
  const [data, updateData] = useImmer<any>(undefined)
  const [lang, setLang] = useState<string>(defaultLang)

  const setLanguages = useCallback(
    (languages) => {
      updateData((draft) => {
        draft.languages = languages
      })
    },
    [updateData]
  )

  const loadData = useCallback(async () => {
    let data = {
      date: new Date(),
      languages: [lang],
      site_id: state.site,
      files: [],
    }

    if (id) {
      try {
        await app.loading(async () => {
          data = await api.post.get(id, {
            query: {
              $lang: lang,
              $site: state.site,
              $populate: 'category',
            },
          })
        })
      } catch (e) {
        updateData((draft) => new Error('error'))
      }
    }

    config?.languages?.forEach((lang) => {
      let body = get(data, `content$${lang}.body`)

      let nodes = isArray(body) && !isEmpty(body) ? body : []

      let nodes2 = times(2 * nodes.length + 1)
      nodes2.forEach((o, i, arr) => {
        if (i % 2 === 1) {
          arr[i] = nodes[(i - 1) / 2]
        } else {
          arr[i] = {
            object: 'block',
            type: 'paragraph',
            nodes: [],
          } as any
        }
      })

      set(
        data,
        `content$${lang}.body`,
        Value.fromJSON({
          object: 'value',
          document: {
            object: 'document',
            nodes: nodes2 as any,
          },
        })
      )
    })

    updateData((draft) => data)
  }, [id, updateData])

  useEffect(() => {
    loadData()
  }, [loadData])

  let image = get(data, `image.url`, null)
  let doc = get(data, `doc.url`, null)

  return (
    <>
      <ModalHeader toggle={onClose} className="align-items-center">
        <div className="d-flex flex-row align-items-center">
          <h1 className="h3 m-1">{id ? `编辑` : `新建`}文章</h1>
          {data && (
            <LangNav lang={lang} setLang={setLang} languages={data.languages} setLanguages={setLanguages as any} />
          )}
          <div className="ml-4" />
          <Button color="link" className="mx-1" onClick={loadData}>
            <Icon name="fas:sync-alt" /> 刷新
          </Button>
        </div>
      </ModalHeader>
      <ModalBody>
        <Container fluid className="p-0">
          {data === null && (
            <div className="text-center">
              <Link to="/post">找不到此文章，返回文章列表</Link>
            </div>
          )}
          {isError(data) && (
            <div className="text-center">
              <Button color="link" spinner={false} onClick={loadData}>
                网络异常， 重新加载
              </Button>
            </div>
          )}
          {isPlainObject(data) && (
            <>
              <Row>
                <Col>
                  <Card>
                    <CardHeader className="d-flex flex-row align-items-center">
                      <CardTitle className="lead mb-0">基本信息</CardTitle>
                      <Spacer />
                    </CardHeader>

                    <CardBody>
                      <Label>发表日期</Label>
                      {/* <ValueInput type="text" value={moment(data.date).format('YYYY-MM-DD')} onChange={e => {
                let v = e.target.value
                updateData(draft => { 
                  let d = moment(v, 'YYYY-MM-DD').toDate()
                  isDate(d) && (draft.date = d)
                })
              }} placeholder="标题" /> */}
                      <Container className="p-0">
                        <Row>
                          <Col md={6} lg={4}>
                            <Datetime
                              inputProps={{ readOnly: true }}
                              value={moment(data.date)}
                              onChange={
                                ((date: Moment) =>
                                  updateData((draft) => {
                                    draft.date = date.toDate()
                                  })) as any
                              }
                              locale="zh-cn"
                              dateFormat={'YYYY-MM-DD'}
                              timeFormat={false}
                              closeOnSelect={true}
                              // input={false}
                            />
                          </Col>
                        </Row>
                      </Container>
                    </CardBody>

                    <CardBody>
                      <Label>板块</Label>
                      <CategoryDropdown
                        className="mr-2"
                        lang={lang}
                        value={data.category}
                        placeholder={
                          {
                            zh_cn: '请选择...',
                            en_us: 'Select...',
                          }[lang] as any
                        }
                        onChange={(value) =>
                          updateData((row) => {
                            console.log(value)
                            row.category_id = value._id
                            row.category = value
                          })
                        }
                      />
                    </CardBody>
                  </Card>

                  <Card>
                    <CardHeader className="d-flex flex-row align-items-center">
                      <CardTitle className="lead mb-0">标题</CardTitle>
                      <Spacer />
                      {/* { row.page && <a href={`http://www.ieepa.org/news/html/${row.page}`} target="_blank">原文链接</a> } */}
                    </CardHeader>

                    <CardBody>
                      <Label>标题</Label>
                      <Input
                        type="text"
                        value={get(data, `content$${lang}.title`, '')}
                        onChange={(e) => {
                          let v = e.target.value || ''
                          updateData((row) => set(row, `content$${lang}.title`, v))
                        }}
                        placeholder="标题"
                      />

                      <div className="form-group form-check my-3">
                        <input
                          type="checkbox"
                          checked={data.link != null}
                          onChange={({ target: { checked = false } }) => {
                            updateData((data) => {
                              data.link = checked ? '' : undefined
                            })
                          }}
                        />
                        <label className="form-check-label ml-2" htmlFor="exampleCheck1">
                          是否外部链接
                        </label>
                      </div>
                    </CardBody>

                    {data.link != null && (
                      <CardBody>
                        <Label>外部链接</Label>
                        <Input
                          type="text"
                          value={data.link || ''}
                          onChange={({ target: { value = '' } }) => {
                            updateData((data) => {
                              data.link = value
                            })
                          }}
                          placeholder="http://"
                        />
                      </CardBody>
                    )}

                    <CardBody>
                      <Label>
                        简称
                        <small className="text-muted ml-3">(用于菜单索引等特定场合)</small>
                      </Label>
                      <Input
                        type="text"
                        value={get(data, `content$${lang}.name`, '')}
                        onChange={(e) => {
                          let v = e.target.value
                          updateData((row) => set(row, `content$${lang}.name`, v))
                        }}
                        placeholder="简称"
                      />
                    </CardBody>

                    <CardBody>
                      <Label>来源</Label>
                      <Input
                        type="text"
                        value={get(data, `content$${lang}.from`, '')}
                        onChange={(e) => {
                          let v = e.target.value
                          updateData((row) => set(row, `content$${lang}.from`, v))
                        }}
                        placeholder="来源"
                      />
                    </CardBody>

                    <CardBody>
                      <div className="d-flex flex-row align-items-center justify-content-start py-2">
                        <Label className="m-0 mr-2">关键词</Label>
                        {get(data, `tags`, []).map((w) => (
                          <Badge key={w} onClick={() => {}} color="info" pill className="m-1">
                            {w}
                          </Badge>
                        ))}
                      </div>
                      <KeywordsEditor
                        label="编辑关键字..."
                        value={get(data, `tags`, [])}
                        onChange={(words) => {
                          updateData((row) => set(row, `tags`, words))
                        }}
                      />
                    </CardBody>
                  </Card>

                  <Card>
                    <CardHeader className="d-flex align-items-center">
                      <CardTitle className="lead mb-0">
                        图片
                        <small className="text-danger ml-3">（图片大小请勿超过10M）</small>
                      </CardTitle>
                      <Spacer />
                      <Button
                        color="link"
                        onClick={async () => {
                          let imgFile = await openFile(['jpg', 'png', 'svg'])

                          if (imgFile) {
                            let url = await uploadFile(imgFile)
                            updateData((row) => set(row, `image.url`, url))
                          }
                        }}
                      >
                        {image ? '重新' : ''}上传...
                      </Button>
                      {image && (
                        <Button
                          className="ml-2"
                          color="danger"
                          onClick={() => {
                            updateData((row) => set(row, `image.url`, undefined))
                          }}
                        >
                          删除
                        </Button>
                      )}
                    </CardHeader>
                    {image && (
                      <CardBody>
                        {
                          <div className="d-flex flex-column align-items-start justify-content-start">
                            <img
                              className="m-2 mb-3 w-100"
                              src={process.env.REACT_APP_CDN_URL + '/img/' + image}
                              style={{
                                maxWidth: '48rem',
                              }}
                            />
                            <Input
                              type="text"
                              value={get(data, `content$${lang}.caption`, '')}
                              onChange={(e) => {
                                let v = e.target.value
                                updateData((row) => set(row, `content$${lang}.caption`, v))
                              }}
                              placeholder="图片标题"
                            />
                          </div>
                        }
                      </CardBody>
                    )}
                  </Card>

                  <Card>
                    <CardHeader className="d-flex align-items-center">
                      <CardTitle className="lead mb-0">
                        PDF
                        <small className="text-danger ml-3">（PDF大小请勿超过10M）</small>
                      </CardTitle>
                      <Spacer />
                      <Button
                        color="link"
                        onClick={async () => {
                          let docFile = await openFile(['pdf'])

                          if (docFile) {
                            let url = await uploadFile(docFile, { dir: 'doc' })
                            updateData((row) => set(row, `doc.url`, url))
                          }
                        }}
                      >
                        {doc ? '重新' : ''}上传...
                      </Button>
                      {doc && (
                        <Button
                          className="ml-2"
                          color="danger"
                          onClick={() => {
                            updateData((row) => set(row, `doc.url`, undefined))
                          }}
                        >
                          删除
                        </Button>
                      )}
                    </CardHeader>
                    {doc && (
                      <CardBody>
                        {
                          <div className="d-flex flex-column align-items-start justify-content-start">
                            <iframe
                              className="m-2 mb-3 w-100"
                              src={`${process.env.REACT_APP_CDN_URL}/doc/${doc}#view=FitH`}
                              style={{
                                border: 0,
                                maxWidth: '48rem',
                                minHeight: '48rem',
                              }}
                            />
                            {/* <Input
                              type="text"
                              value={get(data, `content$${lang}.caption`, "")}
                              onChange={e => {
                                let v = e.target.value;
                                updateData(row =>
                                  set(row, `content$${lang}.caption`, v)
                                );
                              }}
                              placeholder="图片标题"
                            /> */}
                          </div>
                        }
                      </CardBody>
                    )}
                  </Card>

                  <Card>
                    <CardHeader className="d-flex align-items-center">
                      <CardTitle className="lead mb-0">视频</CardTitle>
                      <Spacer />
                      <Button
                        color="link"
                        onClick={async () => {
                          let url = prompt('视频地址', get(data, `video.url`, ''))
                          if (url != null) {
                            if (url.startsWith('<')) {
                              url = url.replace(/.*src='([^']+)'.*/, '$1')
                            }
                            updateData((draft) => set(draft, `video.url`, url))
                          }
                        }}
                      >
                        {data.video ? '更改...' : '添加...'}
                      </Button>
                      {data.video && (
                        <Button
                          className="ml-2"
                          color="danger"
                          onClick={() => {
                            // updateData(draft => { set(draft, '$unset.video', '') })
                            updateData((draft) => {
                              draft.video = undefined
                            })
                          }}
                        >
                          删除
                        </Button>
                      )}
                    </CardHeader>
                    {data.video && data.video.url && (
                      <CardBody>
                        <iframe
                          height={498}
                          className="w-100"
                          src={data.video.url}
                          frameBorder={0}
                          allowFullScreen
                        ></iframe>
                      </CardBody>
                    )}
                  </Card>

                  <Card>
                    <CardHeader className="d-flex align-items-center">
                      <CardTitle className="lead mb-0">
                        附件
                        <small className="text-danger ml-3">
                          （支持后缀jpg/png/pdf/docx/xlsx/pptx/zip, 大小请勿超过50M）
                        </small>
                      </CardTitle>
                      <Spacer />
                      <Button
                        color="link"
                        onClick={async () => {
                          let file = await openFile(['jpg', 'png', 'pdf', 'docx', 'xlsx', 'pptx', 'zip'], {
                            size: 50,
                          })

                          if (file) {
                            let url = await uploadFile(file, {
                              dir: 'file',
                              onProgress: (n) => {
                                console.log(n)
                              },
                            })
                            updateData((row) => {
                              row.files.push({
                                url: '/file/' + url,
                                name: file.name,
                                size: file.size,
                                mime: file.type,
                                type: last(file.name.split('.')),
                              })
                            })
                          }
                        }}
                      >
                        添加...
                      </Button>
                    </CardHeader>
                    <CardBody>
                      <table className="table">
                        {/* <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">文件名</th>
                            <th scope="col" align="right">大小</th>
                          </tr>
                        </thead> */}
                        <tbody>
                          {data &&
                            data.files &&
                            data.files.map(({ url, name, mime, size, type }, idx) => (
                              <tr key={url}>
                                {/* <td scope="row">{idx+1}</td> */}
                                <td>
                                  <i className={`lead fad fa-fw fa-${FILE_ICON[type]}`} /> {name}
                                </td>
                                {/* <td align="right">{`${size/1024/1024}M`}</td> */}
                                <td align="right">
                                  <Button
                                    color="link"
                                    onClick={() => {
                                      window.open(process.env.REACT_APP_CDN_URL + url)
                                    }}
                                  >
                                    下载
                                  </Button>
                                  <Button
                                    color="link"
                                    onClick={() => {
                                      updateData((row) => {
                                        row.files.splice(idx, 1)
                                      })
                                    }}
                                  >
                                    删除
                                  </Button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardHeader>
                      <CardTitle className="lead mb-0">摘要</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Input
                        type="textarea"
                        value={get(data, `content$${lang}.intro`, '')}
                        onChange={(e) => {
                          let v = e.target.value
                          updateData((row) => set(row, `content$${lang}.intro`, v))
                        }}
                        placeholder="编辑摘要"
                        style={{ height: '7rem' }}
                      />
                    </CardBody>
                  </Card>

                  {data.link == null && (
                    <Card>
                      <CardHeader>
                        <CardTitle className="lead mb-0">
                          正文
                          <small className="text-danger ml-3">（图片大小请勿超过10M）</small>
                        </CardTitle>
                      </CardHeader>
                      <CardBody>
                        {
                          <TextEditor
                            //@ts-ignore
                            app={app}
                            value={data[`content$${lang}`].body}
                            placeholder="编辑文章内容"
                            onChange={({ value }) => {
                              updateData((draft) => {
                                draft[`content$${lang}`].body = value
                              })
                            }}
                          />
                        }
                      </CardBody>
                    </Card>
                  )}
                </Col>
              </Row>
            </>
          )}
        </Container>
      </ModalBody>
      <ModalFooter>
        {isPlainObject(data) && (
          <ActionButton
            color="primary"
            className="mx-1"
            onClick={async () => {
              let doc: any = cloneDeep(omit(data, '_id', 'category'))
              config.languages.forEach((lang) => {
                if (doc[`content$${lang}`]) {
                  doc[`content$${lang}`].body = cleanNodes(data[`content$${lang}`].body.toJS().document.nodes)
                }
              })

              for (let k in doc) {
                if (doc[k] === undefined) {
                  delete doc[k]
                  set(doc, ['$unset', k], '')
                }
              }

              if (id) {
                let up = await api.post.patch(id, doc)

                console.log(up)

                updateRows((rows) => {
                  extend(find(rows, ['_id', id]), up)
                })
                return '保存成功'
              } else {
                let res: any = await api.post.create(
                  defaults({}, doc, {
                    status: 'public',
                    rank: 1,
                    version: 1,
                  }),
                  {
                    query: {
                      $site: state.site,
                    },
                  }
                )

                if (res._id) {
                  history.replace(`/post/${res._id}`)
                  return '创建成功'
                }
              }
            }}
          >
            <Icon name="fas:save" /> {id ? '保存' : '创建'}
          </ActionButton>
        )}
      </ModalFooter>
    </>
  )
}

export default PostEditor
