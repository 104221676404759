import React from 'react'

import {
  get,
} from 'lodash'
import { useCallback, useContext, useState } from "react"
import { Button as Btn, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"
import { AppContext } from "../App"

import { useGlobal } from '../store'



const SiteSelector = ({className = '', style = {}, sites = [], value = undefined as (string | undefined), onChange = (value:string) => {} }) => {

  const app = useContext(AppContext)

  const [state, actions] = useGlobal()

  actions.load('site', sites)

  const [open, setOpen] = useState<boolean>(false)
  const toggleOpen = useCallback(() => {
    setOpen(!open)
  }, [open])

  if(!sites.length) {
    return null
  }

  value = value || sites[0]

  return (
    <Dropdown isOpen={open} toggle={toggleOpen} className={className} style={style}>
      <DropdownToggle tag={Btn} className="btn-primary">
        { get(state.data, `site.${value}.content$${state.lang}.subtitle`, value) }
      </DropdownToggle>
      <DropdownMenu className="" style={{
        borderWidth: 0,
        minWidth: 0,
      }}>
      {sites.map(id => (id == value) ? null : (
        <DropdownItem key={id} tag={Btn} className="btn-light align-left" onClick={() => {
          onChange(id)
        }}>
          { get(state.data, `site.${id}.content$${state.lang}.subtitle`, id) }
        </DropdownItem>
      ))}
      </DropdownMenu>
    </Dropdown>
  )
  
}

export default SiteSelector