import React, { useState, useCallback, useContext, useMemo } from "react"
import { Link } from "react-router-dom"
import { Formik, Field, Form, ErrorMessage } from 'formik'
import delay from 'delay'

import { withRouter } from 'react-router'

import { pick } from 'lodash'

import typeToReducer from 'type-to-reducer'

import { api, useGlobal } from '../../store' 
import { State, Actions } from '../../store/types' 

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Label,
  Input,
  CustomInput
} from "reactstrap";
import { AppContext } from "../../App";
import { createApiAxios } from "../../api";

const Login = (history) => {

  const app = useContext(AppContext)

  const [ pending, setPending ] = useState(false)

  const [state, actions] = useGlobal()

  return (
    <Card className="my-4">
      <CardHeader className="lead text-center">
        登录
      </CardHeader>
      <CardBody className="mx-sm-4">
          <div className="text-center">
            <img
              src={`${process.env.PUBLIC_URL}/img/icon.svg`}
              alt="IEEPA.ORG"
              className="my-5"
              style={{
                width: '8rem',
                height: '8rem',
              }}
            />
          </div>
          <Formik
            initialValues={{ email: '', password: '', remember: true }}
            onSubmit={ async ({ email, password }) => {

              try {
                let auth = await api.authenticate({
                  strategy: 'local',
                  email,
                  password,
                })
                console.log(auth)
                actions.login(auth && auth.user || null)
              } catch (e) {
                app.alert('用户名或密码错误', {type: "error"})
              }
              
            }}
            render={({
              values,
              errors,
              status,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form>
                <FormGroup>
                  <Label>邮箱</Label>
                  <Field type="" name="email" placeholder="请填写邮箱" className="form-control-lg form-control" />
                  <ErrorMessage name="email" />
                  {/* <div className="mt-2"> <Link to="/auth/register">注册新用户</Link> </div> */}
                </FormGroup>
                <FormGroup>
                  <Label>密码</Label>
                  <Field type="password" name="password" placeholder="请填写密码" className="form-control-lg form-control" />
                  {/* <div className="mt-2"> <Link to="/auth/reset-password">忘记密码?</Link> </div> */}
                </FormGroup>
                <FormGroup>
                </FormGroup>
                <FormGroup className="my-2">
                  <Field className="mr-2"
                    type="checkbox"
                    name="remember"
                    checked={values.remember}
                  />{' '}记住我
                </FormGroup>
                <div className="text-center mt-5">
                  <Button disabled={pending} type="submit" color="primary" size="lg">
                    登录
                  </Button>
                </div>
              </Form>
            )} 
          />
      </CardBody>
      <CardFooter className="border-top-0 mx-sm-4">
        {/* <div className="mt-2 text-right"> <Link to="/auth/register">注册新用户</Link> </div> */}
      </CardFooter>
    </Card>
  )
}

export const LoginDialog = Login

export default withRouter(Login);
