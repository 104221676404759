import React, { InputHTMLAttributes } from 'react'

// const InputCheckbox: React.FC<Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>> = ({ ...props }) => {

  
// }

class InputCheckbox extends React.Component<Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  indeterminate?: boolean,
}> {

  private inputRef: React.RefObject<any>

  constructor(props) {
    super(props)
    this.inputRef = React.createRef<any>()
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   console.log(nextProps.indeterminate)
  // }

  updateRef() {
    if(this.inputRef.current != null) {
      if(this.props.indeterminate != null) {  
        this.inputRef.current.indeterminate = this.props.indeterminate
      } else {
        delete this.inputRef.current.indeterminate
      }
    }
  }

  componentDidMount() {
    this.updateRef()
  }
  
  componentDidUpdate() {
    this.updateRef()
  }

  render() {
    return (
      <input {...this.props} type='checkbox' ref={this.inputRef} />
    )
  }

}

export default InputCheckbox