import React, { useEffect, useState, useContext, useMemo, useCallback } from 'react'

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  Button,
  Nav, NavItem, NavLink, Badge, ModalFooter, Modal, ModalHeader, ModalBody,
  Form, FormGroup, Label, Input, FormText,
  Table,
} from "reactstrap";

import { useTable } from 'react-table'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { nanoid } from 'nanoid'

import classnames from 'classnames'

import Datetime from 'react-datetime'

import { Value } from 'slate'

import { useImmer } from 'use-immer';
import App, { AppContext } from '../App';
import delay from 'delay'
import clsx from 'clsx'

import { LOCALE_LABELS, SPACE_CHAR } from '../api';

import ActionButton from '../components/Button'
import { Icon } from '../utils'
import ValueInput from '../components/ValueInput'

import { api, useGlobal } from '../store'
import { openFile, uploadFile } from '../utils/file'

import {
  isPlainObject,
  isError,
  isDate,
  isArray,
  isEmpty,
  get,
  set,
  pick,
  uniq,
  omit,
  snakeCase,
  lowerCase,
  defaults,
  find,
  extend,
  cloneDeep,
} from 'lodash'


import queryString from 'query-string'
import { Link, useHistory } from 'react-router-dom';
import moment, { Moment } from 'moment'
import ReactQuill from 'react-quill';
import Spacer from '../components/Spacer';

import TextEditor from '../components/TextEditor';

import LanguageSelector from '../components/LanguageSelector';
import LangNav from '../components/LangNav'

import config from '../config'
import { render } from 'react-dom';


const BLANK_BODY = [{
  "object": "block",
  "type": "paragraph",
  "nodes": [{
    "object": "text",
    "text": "",
    "marks": []
  }],
}]

const SiteEditor = ({id}) => {

  let history = useHistory()

  const app = useContext(AppContext)

  const [data, updateData] = useImmer<any>(undefined)
  const [lang, setLang] = useState<string>(config.languages[0] as string)

  const setLanguages = useCallback((languages) => {
    updateData(draft => {
      draft.languages = languages
    })
  }, [updateData])

  const close = useCallback(() => {
    history.goBack()
  }, [])

  const loadData = useCallback(async () => {

    let data

    if(id) {
      try {
        await app.loading(async () => {
          data = await api.site.get(id)


          try {
            data.category = await api.category.get(data.category_id)
          }catch(e) {
            data.category = null
          }
        })
      } catch (e) {
        updateData(draft => new Error('error'))
      }
    } else {
      data = {
      }
    }

    config.languages.forEach(l => {
      data[`intro$${l}`] = Value.fromJSON({
        object: 'value',
        document: {
          object: 'document',
          nodes: (isArray(data[`intro$${l}`]) && !isEmpty(data[`intro$${l}`])) ? data[`intro$${l}`] : [{
            "object": "block",
            "type": "paragraph",
            "nodes": [],
          }],
        },
      }) 
    })

    updateData(draft => data)
    
  }, [id, updateData])

  useEffect(() => {
    loadData()
  }, [loadData])

  const columns = useMemo(
    () => [
      // {
      //   Header: "编号",
      //   accessor: "no"
      // },
      {
        Header: "职位名称",
        accessor: "position",
        show: false,
      },
      {
        Header: "部门",
        accessor: "department"
      },
      {
        Header: "地点",
        accessor: "location"
      },
      {
        Header: "发布日期",
        accessor: "date"
      },
      {
        Header: "截止日期",
        accessor: "deadline"
      }
    ],
    []
  );

  const rows = [{
    _id: nanoid(8),
    position: 'AAA',
  }, {
    _id: nanoid(8),
    position: 'BBB',
  }]


  return (
    <>
      <ModalHeader toggle={close} className="align-items-center">

      <div className="d-flex flex-row align-items-center">
        <h1 className="h3 m-1 mr-4">{id ? `编辑` : `新建`}网站资料</h1>
        { data && <LangNav lang={lang} setLang={setLang} languages={data.languages} setLanguages={setLanguages as any} /> }
        <div className="ml-4" />
        <ActionButton color="link" className="mx-1" onClick={loadData}>
          <Icon name="fas:sync-alt" />{' '}刷新
        </ActionButton>
      </div>

      </ModalHeader>
        <ModalBody>
        {(data === null) && <div className="text-center"><Link to="/site">找不到此网站资料，返回网站资料列表</Link></div>}
        { isError(data) && <div className="text-center"><Button color="link" spinner={false} onClick={loadData}>网络异常， 重新加载</Button></div> }
        { isPlainObject(data) && (<>
          <Card>
          <CardHeader className="d-flex align-items-center">
            <CardTitle className="lead mb-0">基本资料</CardTitle>
            <Spacer />
            
          </CardHeader>
          <CardBody>
          <Form>

            <FormGroup>
              <Label>标题</Label>
              <Input type="text" value={get(data, `content$${lang}.title`, '')} onChange={e => {
                let v = e.target.value || ''
                updateData(row => set(row, `content$${lang}.title`, v))
              }} placeholder="标题" />
            </FormGroup>

            <FormGroup>
              <Label>标语</Label>
              <Input type="text" value={get(data, `content$${lang}.slogan`, '')} onChange={e => {
                let v = e.target.value || ''
                updateData(row => set(row, `content$${lang}.slogan`, v))
              }} placeholder="标语" />
            </FormGroup>

            {/* <FormGroup>
              <Label>友情链接</Label>
              
            </FormGroup> */}

          </Form>
          </CardBody>
          </Card>

          <Card>
          <CardHeader className="d-flex align-items-center">
            <CardTitle className="lead mb-0">招聘岗位</CardTitle>
            <Spacer />
            
          </CardHeader>
          <CardBody>
            <JobTable columns={columns} data={rows} />
          </CardBody>
          </Card>
        </>)}

        

          {/* <Card>
            <CardHeader className="d-flex align-items-center">
              <CardTitle className="lead mb-0">照片</CardTitle>
              <Spacer />
              <Button color="link" onClick={async () => {
                let imgFile = await openFile(['jpg', 'png'])
  
                if(imgFile) {
                  let url = await uploadFile(imgFile)
                  updateData(row => set(row, `photo`, url))  
                }
                
              }}>{data.photo ? '重新' : ''}上传...</Button>
              { data.photo && <Button className="ml-2" color="danger" onClick={() => {
                updateData(row => set(row, `photo`, undefined))  
              }}>删除</Button> }
            </CardHeader>
            <CardBody>
              { data.photo && 
              <img className="m-2 w-100" src={process.env.REACT_APP_CDN_URL + '/img/' + data.photo} style={{
                maxWidth: '12rem',
              }} />
              }
            </CardBody>
          </Card> */}

        </ModalBody>
        <ModalFooter>
        { isPlainObject(data) &&
          <ActionButton color="primary" className="mx-1" onClick={async () => {

            let doc: any = cloneDeep(data)

            if(id) {
              let up = await api.site.patch(id, doc)
              // updateRows(rows => {
              //   extend(find(rows, ['_id', id]), up)
              // })
              return '保存成功'
            } else {
              let res:any = await api.site.create(defaults({}, doc, {
                status: 'public',
                rank: 1,
                version: 1,
              }))

              if(res._id) {
                history.replace(`/site/${res._id}`) 
                return '创建成功'
              }
            }
          }}>
            <Icon name="fas:save" /> {id ? '保存' : '创建'}
          </ActionButton>
        }
        </ModalFooter>
    </>
  )
};

const DND_ITEM_TYPE = 'row'

const JobTableRow = ({ row, index, moveRow }) => {

  const dropRef = React.useRef(null)
  const dragRef = React.useRef(null)

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover: (item, monitor) => {
      if (!dropRef.current) {
        return
      }
      //@ts-ignore
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      //@ts-ignore
      const hoverBoundingRect = dropRef.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      //@ts-ignore
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      //@ts-ignore
      item.index = hoverIndex
    },
  })

  //@ts-ignore
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DND_ITEM_TYPE, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1

  preview(drop(dropRef))
  drag(dragRef)

  return (
    <tr {...row.getRowProps()} ref={dropRef} style={{ opacity }}>
      <td className="text-nowrap"><Button color="link" innerRef={dragRef} title="上下拖动改变排序"><i className="fal fa-bars" /></Button></td>
      <td>
        {index+1}
      </td>
      {row.cells.map(cell => {
        return (
          <td {...cell.getCellProps()}>
            {cell.render('Cell')}
          </td>
        )
      })}
    </tr>
    
  )
}

const JobTable = ({ columns, data }) => {

  const [records, updateRecords] = useImmer(data as any[])

  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: records,
    initialState: {
      // hiddenColumns: ['position'],
    },
    getRowId: (row: any) => row._id,
  })

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = records[dragIndex]
    updateRecords( draft => {
      draft.splice(dragIndex, 1)
      draft.splice(hoverIndex, 0, dragRecord)
    })
  }

  // Render the UI for your table
  return (
    <DndProvider backend={HTML5Backend}>
    <Table striped={false} hover {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()} className="text-muted font-weight-normal small">
            <th style={{width:'0.1%'}}></th>
            <th style={{width:'0.1%'}} className="text-nowrap">编号</th>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <JobTableRow
                  index={i}
                  row={row}
                  moveRow={moveRow}
                  {...row.getRowProps()}
                />
            
          )
        })}
      </tbody>
    </Table>
    </DndProvider>
  )
}

export default SiteEditor;

