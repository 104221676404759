import React, { useEffect, useState, useContext, useMemo, useCallback } from 'react'

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  Input,
  Label,
  Button,
  Nav, NavItem, NavLink, Badge, ModalFooter, Modal, ModalHeader, ModalBody,
} from "reactstrap";

import classnames from 'classnames'

import Datetime from 'react-datetime'

import { useForm } from 'react-hook-form'

import { Value } from 'slate'

import { useImmer } from 'use-immer';
import { AppContext } from '../App';
import delay from 'delay'
import clsx from 'clsx'

import { LOCALE_LABELS, SPACE_CHAR } from '../api';

import ActionButton from '../components/Button'
import { Icon } from '../utils'
import ValueInput from '../components/ValueInput'

import { api, useGlobal } from '../store'
import { openFile, uploadFile } from '../utils/file'


import {
  isPlainObject,
  isError,
  isDate,
  isArray,
  isEmpty,
  get,
  set,
  pick,
  uniq,
  omit,
  snakeCase,
  lowerCase,
  defaults,
  find,
  extend,
  cloneDeep,
} from 'lodash'


import queryString from 'query-string'
import { Link } from 'react-router-dom';
import moment, { Moment } from 'moment'
import ReactQuill from 'react-quill';
import Spacer from '../components/Spacer';

import { KeywordsEditor } from './Donation'
import TextEditor from '../components/TextEditor';

import LanguageSelector from '../components/LanguageSelector';
import LangNav from '../components/LangNav'

import config from '../config'

import { cleanNodes } from '../utils'


const BLANK_BODY = [{
  "object": "block",
  "type": "paragraph",
  "nodes": [{
    "object": "text",
    "text": "",
    "marks": []
  }],
}]

const DonationEditor = ({id, open = false, history, onClose, updateRows}) => {

  const app = useContext(AppContext)

  const { register, handleSubmit, watch, formState: { errors } } = useForm()
  const onSubmit = data => { console.log(data) }

  const [data, updateData] = useImmer<any>(undefined)
  const [lang, setLang] = useState<string>(config.languages[0] as string)

  const setLanguages = useCallback((languages) => {
    updateData(draft => {
      draft.languages = languages
    })
  }, [updateData])

  const loadData = useCallback(async () => {
    let data = {}

    if(id) {
      try {
        await app.loading(async () => {
          data = await api.donation.get(id)
        })
      } catch (e) {
        updateData(draft => new Error('error'))
      }
    }

    config.languages.forEach(l => {
      data[`intro$${l}`] = Value.fromJSON({
        object: 'value',
        document: {
          object: 'document',
          nodes: (isArray(data[`intro$${l}`]) && !isEmpty(data[`intro$${l}`])) ? data[`intro$${l}`] : [{
            "object": "block",
            "type": "paragraph",
            "nodes": [],
          }],
        },
      }) 
    })

    updateData(draft => data)
    
  }, [id, updateData])

  useEffect(() => {
    loadData()
  }, [loadData])

  return (
    <form onSubmit={handleSubmit(onSubmit)} >
    <Modal
        scrollable
        backdrop="static"
        isOpen={open}
        size="lg"
        className=""
      >
      <ModalHeader toggle={onClose} className="align-items-center">

      <div className="d-flex flex-row align-items-center">
        <h1 className="h3 m-1 mr-4">{id ? `编辑` : `新建`}捐赠信息</h1>
        <div className="ml-4" />
        {/* <ActionButton color="link" className="mx-1" onClick={loadData}>
          <Icon name="fas:sync-alt" />{' '}刷新
        </ActionButton> */}
      </div>

      </ModalHeader>
        <ModalBody>
        <Container fluid className="p-0">
      {(data === null) && <div className="text-center"><Link to="/donation">找不到此捐赠信息，返回捐赠信息列表</Link></div>}
      { isError(data) && <div className="text-center"><Button color="link" spinner={false} onClick={loadData}>网络异常， 重新加载</Button></div> }
      { isPlainObject(data) && (<>

      <Row>
        <Col>
          { (data != null) &&
          <Card>
            <CardHeader className="d-flex flex-row align-items-center">
              <CardTitle className="lead mb-0">基本信息</CardTitle>
              <Spacer />
            </CardHeader>

            <CardBody>
              <Label>捐赠者</Label>
              <Input type="text" width="120" value={get(data, `name$${lang}`, '')} onChange={e => {
                let v = e.target.value || ''
                updateData(row => set(row, `name$${lang}`, v))
              }}  placeholder="捐赠者" />

              <div className="my-3" />

              <Label>捐赠金额</Label>
              {/* <Input type="number" width="120" value={get(data, `title$${lang}`, '')} onChange={e => {
                let v = e.target.value || ''
                updateData(row => set(row, `title$${lang}`, v))
              }}  placeholder="职务" /> */}
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">¥</span>
                </div>
                <input 
                  name="amount"
                  type="number" 
                  className={clsx('form-control', {['is-invalid']: false})} 
                  value={get(data, `amount`, 0)} 
                  onBlur={e => {
                    let v = parseFloat(e.target.value) || 0
                    updateData(row => set(row, `amount`, v))
                  }}
                />
                {/* <div className="invalid-feedback">
                  Please choose a username.
                </div> */}
              </div>

              <div className="my-3" />

              <Label>捐赠日期</Label>
              <Input type="text" width="120" value={get(data, `title$${lang}`, '')} onChange={e => {
                let v = e.target.value || ''
                updateData(row => set(row, `title$${lang}`, v))
              }}  placeholder="职务" />
            </CardBody>

          </Card>
          }

          
        </Col>
      </Row>
      </>)}
    </Container>
        </ModalBody>
        <ModalFooter>
        { isPlainObject(data) &&
          <Button color="primary" type="submit">
            <Icon name="fas:save" /> {id ? '保存' : '创建'}
          </Button>
          // <ActionButton color="primary" className="mx-1" onClick={async () => {

          //   let doc: any = cloneDeep(data)
          //   config.languages.forEach(l => {
          //     doc[`intro$${l}`] = cleanNodes(data[`intro$${l}`].toJS().document.nodes)
          //   })

          //   if(id) {
          //     let up = await api.donation.patch(id, doc)
          //     updateRows(rows => {
          //       extend(find(rows, ['_id', id]), up)
          //     })
          //     return '保存成功'
          //   } else {
          //     let res:any = await api.donation.create(defaults({}, doc, {
          //       status: 'public',
          //       rank: 1,
          //       version: 1,
          //     }))

          //     if(res._id) {
          //       history.replace(`/donation/${res._id}`) 
          //       return '创建成功'
          //     }
          //   }
          // }}>
          //   <Icon name="fas:save" /> {id ? '保存' : '创建'}
          // </ActionButton>
        }
        </ModalFooter>
    </Modal>
    </form>

  )
};

export default DonationEditor;
