import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';

import React, { Suspense, useState, useEffect, useMemo, useCallback, useContext } from 'react';
import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import Routes from './routes/Routes';

import queryString from 'query-string'

import {
  atom,
  useRecoilValue,
  RecoilRoot,
} from 'recoil'

import {
  defaults,
  get,
  last,
  compact,
} from 'lodash'

import {
  Alert, Container, Row, Col, Spinner,
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'

import { useImmer } from 'use-immer';

import moment from 'moment'
import { SPACE_CHAR, createApiAxios } from './api';
import { AxiosInstance } from 'axios';
import { useParams, useLocation } from 'react-router-dom';
import { api, useGlobal, siderState } from './store';
import { State, Actions } from './store/types'

import { AuthenticationResult } from '@feathersjs/authentication/lib';
import { LoginDialog } from './pages/auth/Login';

import { SIDER_IS_OPEN, Icon, IApp } from './utils';

import config from './config';
import Dashboard from './layouts/Dashboard';

const MESSAGES = {
  loading: '加载中，请稍候...',
  // error: '网络正在开小差...',
  error: '网络异常，请稍候重试',
}

const CONFIG = {
  DISMISS_TIMEOUT: 2500,
}

type LoadingType = {
  enabled: boolean,

  title?: string,
  modal?: boolean,
}

type MessageType = {
  enabled: boolean,

  type: string,
  content: string,
  onClose: () => void,

  title?: string,
  autoDismiss?: boolean,
  modal?: boolean,

  
}

interface IAppConfig {
  autoDismissInterval: number,
  messages: {
    loading?: string,
    error?: string,
  }
}

const MESSAGE_COLORS = {
  info: 'info',
  success: 'success',
  warning: 'warning',
  error: 'danger',
}

let ntfSeq = 0

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          'No Name': '8fuafsa',
        }
      },
      zh_cn: {
        translation: {
          'No Name': '未命名',
        }
      }
    },
    lng: 'zh_cn',
    fallbackLng: 'zh_cn',

    interpolation: {
      escapeValue: false
    }
  });

type AppViewParamsType = {
  tab?: string,
  dlg?: string[],
}

const App: React.FC<{
}> = () => {

  // let { tab = 'home', dlg = [] } = useParams<AppViewParamsType>()
  const { search } = useLocation()
  const { tab = '', dlg = [] }: AppViewParamsType = queryString.parse(search)

  console.log(tab, dlg)


  const [state, actions] = useGlobal()

  console.log(actions)

  const loadData = useCallback(async () => {
    let auth:AuthenticationResult | null = null
    try {
      auth = await api.reAuthenticate()
      console.log(auth)
    } catch(e) {
      console.error(e)
    }

    actions.login(auth && auth.user || null )
  }, [])

  useEffect(() => {
    loadData()
  }, [])

  const sider = useRecoilValue(siderState)

  useEffect(() => {
    window.localStorage.setItem(SIDER_IS_OPEN, JSON.stringify(sider))
  }, [sider])

  const [loadings, updateLoadings] = useImmer<LoadingType[]>([])
  const [messages, updateMessages] = useImmer<MessageType[]>([])

  const loading = useCallback(
    function<T> (action: () => Promise<T>, opt: { title?: string; modal?: boolean } = {}) {
      return new Promise<T>((resolve, reject) => {
        const { title, modal = false } = opt

        let idx = loadings.length
        updateLoadings(draft => {
          draft[idx] = {
            enabled: true,
            title,
            modal,
          }
        })

        action().then((result: T) => {
          resolve(result)
          updateLoadings(draft => {
            delete draft[idx]
          })
        }).catch(reason => {
          reject(reason)
          updateLoadings(draft => {
            delete draft[idx]
          })
        })

      })
    },
    [loadings, updateLoadings]
  )

  const alert = useCallback<IApp['alert']>((content, opt = {}) => {

    const type = opt.type ?? 'info'
    opt.autoDismiss = opt.autoDismiss ?? (type === 'info' || type === 'success')
    opt.modal = opt.modal ?? (type === 'error' || type === 'warning')

    return new Promise<void>((resolve, reject) => {
      let idx = ntfSeq++

      const onClose = () => {
        updateMessages((msgs) => {
          msgs[idx].enabled = false
        })
        resolve()
      }

      const msg: MessageType = {
        ...opt,
        enabled: true,
        type,
        content,
        onClose,
      }

      updateMessages((msgs) => {
        msgs[idx] = msg
      })

      if (opt.autoDismiss) {
        setTimeout(onClose, config.dismissAfter)
      }
    })
    
  }, [])

  const confirm = useCallback((msg, opt = {}) => {
    return new Promise<boolean>((resolve, reject) => {
      if(window.confirm(msg)) {
        resolve(true)
      } else {
        resolve(false)
      }
    })
    
  }, [])


  const notification = useMemo(() => {
    return last(loadings.filter(n => n))
  }, [loadings])

  const router = useMemo(() => <Routes />, [])

  //@ts-ignore
  const context = useMemo<IApp>(() => ({

    get: (path: string) => {
      return null
    },

    set: (path:string, value: any) => {

    },

    loading,
    alert,

    confirm,

    // showLoading: (title: string | boolean = '', modal = false) => {

    //   if(typeof title === 'boolean') {
    //     modal = title
    //     title = ''
    //   }

    //   let idx = ntfSeq ++

    //   const dismiss = () => { updateNotifications(msgs => {
    //     msgs[idx].enabled = false
    //   })}

    //   updateNotifications(msgs => { msgs[idx] = {
    //     enabled: true,
    //     type: 'loading',
    //     title: (title as string),
    //     modal,
    //   }})

    //   return dismiss
    // },

    
  }), [])

  return (
    <AppContext.Provider value={context}>

      { !tab && state.user && router }

      { tab && state.user && (
        <Dashboard>
          <h1>djfsak</h1>
        </Dashboard>
      )}



      <Modal isOpen={state.user === null} toggle={() => {}}>
        <LoginDialog />
      </Modal>

      <div className="modal fade" tabIndex={-1}>
      </div>
      
      { loadings.some(n => n && n.modal) && <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 100000,
        backgroundColor: 'rgba(0, 0, 0, 0.375)',
      }} />}

      <Container className="fixed-top w-100" style={{zIndex: 100000}}>
        <Row>
          <Col md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }} xl={{ size: 6, offset: 3 }}>
            { messages.map((msg, idx) => msg?.title && (
            <Alert key={idx} className="mt-1" color={MESSAGE_COLORS[msg.type] || msg.type} isOpen={msg.enabled} toggle={ msg.onClose }>
              <div className="alert-icon">
                <Icon name="fas:bell" />
              </div>
              <div className="alert-message text-nowrap text-truncate pr-5">
                {msg.title}
              </div>
            </Alert>
            ))}
            {/* { loadings.map((n, idx) => (
            <Alert key={idx} className="mt-1" color={NOTIF_COLORS[n.type] || n.type} isOpen={n.enabled && n === notification} toggle={ n.callback }>
              <div className="alert-icon">
                { (n.type === 'loading') ? <Spinner color="white" size="sm" /> : <Icon name="fas:bell" /> }
              </div>
              <div className="alert-message text-nowrap text-truncate pr-5">
                {n.title || messages[n.type] || SPACE_CHAR}
              </div>
            </Alert>
            ))} */}
          </Col>
        </Row>
      </Container>

    </AppContext.Provider>
  )
};

export const AppContext = React.createContext<IApp>({} as IApp)

export const useApp = () => {
  const app = useContext<IApp>(AppContext)

  return app
}

export interface IData {
  find(coll: string, )
}

export default () => (
  <RecoilRoot>
    <App />
  </RecoilRoot>
)
