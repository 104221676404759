import React from 'react';

import { Col, Container, Row } from 'reactstrap';

import Main from '../components/Main';

const Auth = ({ children }) => (
  <Main className='d-flex w-100 justify-content-center'>
    <Container className='d-flex flex-column'>
      <Row className='h-100'>
        <Col md={8} lg={6} xl={4} className='mx-auto d-table h-100'>
          <div className='d-table-cell align-middle'>{children}</div>
        </Col>
      </Row>
    </Container>
  </Main>
);

export default Auth;
