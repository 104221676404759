import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

import { parseUrl, stringify } from 'query-string'

import {
  isPlainObject,
  isArray,
  isRegExp,
  isDate,
  isString,
  isBoolean,
  isNumber,
  defaults,
  assignIn,
  isInteger,
  isNull,
} from 'lodash'

import * as TJSON from 'telejson' 
import renameKey from 'deep-rename-keys'

import { api } from '../store'


const INTL_NAME_RE = /^[_0-9a-zA-Z]+\$$/i
const addIntlName = (lang: string) => (name: string) => {
  return name.split('.').map(s => (INTL_NAME_RE.test(s) ? (s + lang) : s)).join('.')
}
const removeIntlName = (lang: string) => {
  let re = new RegExp('^[_0-9a-zA-Z]+\\$' + lang + '$', 'i')
  return (name: string) => {
    return lang ? name.split('.').map(s => (re.test(s) ? s.slice(0, -(lang.length)) : s)).join('.') : name
  }
}

const url = (url: string, query?: {[index:string]: any}): string => {

  let path = parseUrl(url)
  query = defaults({}, path.query, query)
  url = path.url

  if(isString(query.lang) && query.lang) {
    let trans = addIntlName(query.lang)
    if(isPlainObject(query.where)) query.where = renameKey(query.where, trans)
    if(isString(query.select)) query.select = trans(query.select)
    if(isArray(query.select)) query.select = query.select.map(trans)
    if(isString(query.sort)) query.sort = trans(query.sort)
    if(isArray(query.sort)) query.sort = query.sort.map(trans)
  }

  console.log(query)

  for( let k in query) {
    if(query[k] === undefined) {
      delete(query[k])
    } else if(!isString(query[k])) {
      query[k] = TJSON.stringify(query[k])
    }
  }

  

  url += ('?' + stringify(query))

  return url
}

export const createApiAxios = (token, locale) => {
  const apiAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 30000,
    transformResponse: [
      TJSON.parse,
    ],
    headers: {
      'X-Custom-Header': 'ieepa',
    }
  });

  apiAxios.interceptors.request.use(reqInterceptor(token, locale))
  apiAxios.interceptors.response.use(resInterceptor.done, resInterceptor.error);

  return apiAxios
}

const isBasicType = value => ([
  isPlainObject,
  isArray,
  isRegExp,
  isDate,
  isString,
  isBoolean,
  isNumber,
  isInteger,
  isNull,
].some(f => f(value)))




const reqInterceptor = (token, locale) => (req: AxiosRequestConfig) => {

  token && (req.headers['Authorization'] = `Bearer ${token}`);
  locale && (req.headers['Content-Language'] = locale);

  if(isBasicType(req.data)) {
    if(!isPlainObject(req.data) && !isArray(req.data)) {
      req.data = { __root__: req.data }
    }
  }

  let { query: {lang} } = parseUrl(req.url as string)

  if(isString(lang) && lang) {
    let trans = addIntlName(lang)

    if(isPlainObject(req.data)) {
      req.data = renameKey(req.data, trans)
    } else if(isArray(req.data)) {
      req.data = req.data.map(o => {
        return isPlainObject(o) ? renameKey(o, trans) : o
      })
    }
  }

  if(isPlainObject(req.data) || isArray(req.data)) {
    req.data = JSON.parse(TJSON.stringify(req.data))
  }

  return req;
}

const resInterceptor = {
  done: (res: AxiosResponse) => {
    let { query: { lang } } = parseUrl(res?.config?.url as string)
    
    if(isString(lang) && lang) {
      let trans = removeIntlName(lang)
      if(isPlainObject(res.data)) {
        res.data = renameKey(res.data, trans)
      } else if(isArray(res.data)) {
        res.data = res.data.map(o => {
          return isPlainObject(o) ? renameKey(o, trans) : o
        })
      }
    }

    if(res.data && (res.data.__root__ !== undefined)) {
      res.data = res.data.__root__
    }
    return Promise.resolve(res.data || undefined);
  },
  error: err => {
    console.error(err)
    return Promise.reject(new Error(err.response.data));
  }
}

const axiosCDN = axios.create({
  baseURL: process.env.REACT_APP_CDN_URL,
  timeout: 30000,
  headers: {
    'X-Custom-Header': 'ieepa',
  }
});

// const Fold = new Collection('fold', {
//   name: { type: String },
//   parent_id: { type: String },
// })


// const Post = new Collection('post', {
//   code: { type: String },
//   title: { type: String, intl: true },
//   date: { type: Date },
//   from: { type: String, intl: true },
//   fold: { type: Fold },
//   tags: { type: [String], intl: true },
//   status: { type: String },
//   rank: { type: Number },
//   page: { type:String },
//   body: { type: [{
//     type: { type: String },
//     image: { type: String },
//     text: { type: String, intl: true },
//     caption: { type: String, intl: true },
//   }]},
// })

export const ARTICLE_STATUS = {
  DRAFT: 'draft',
  PUBLIC: 'public',
  ARCHIVED: 'archive',
}

export const ARTICLE_STATUS_LABELS = {
  [ARTICLE_STATUS.DRAFT]: '草稿',
  [ARTICLE_STATUS.PUBLIC]: '发布',
  [ARTICLE_STATUS.ARCHIVED]: '归档',
}

export const LOCALE_LABELS = {
  'zh_cn': '中文',
  'en_us': 'English',
}

export const SPACE_CHAR = '\u00a0'

export {
  axiosCDN,
}

export default api

