import React from 'react'
import { Icon } from '../utils'
import { Spinner } from 'reactstrap'
import clsx from 'clsx'

const IconButton: React.FC<{
  onClick?: () => void,
  isPending?: boolean,
}> = ({ onClick, isPending = false }) => {
  return (
    <>
      <a href="javascript:void(0)" onClick={onClick}>
        <Icon className={clsx('text-primary', {'d-none': !!isPending})} name="fas:sync-alt" />
      </a>
      <Spinner className={clsx({'d-none': !isPending})} size="sm" color="primary" />
    </>
  )
}

export default IconButton