import React from 'react'

import globalHook, { Store } from 'use-global-hook'
import produce from 'immer'

import { State, Actions, DataRowType } from './types'

import Axios, { AxiosRequestConfig } from 'axios'

import feathers, { ServiceAddons, Service } from '@feathersjs/feathers'
import rest from '@feathersjs/rest-client'
import auth from '@feathersjs/authentication-client'

import { FeathersError } from '@feathersjs/errors'

import Try from 'nice-try'
import { atom } from 'recoil'

import {
  trim,
  snakeCase,
  lowerCase,
  first,
  includes,
  set,
  get,
  unset,
  isString,
  isArray,
  find,
  transform,
} from 'lodash'

import config from '../config' 
import { SIDER_IS_OPEN } from '../utils'

export const siderState = atom({
  key: SIDER_IS_OPEN,
  //@ts-ignore
  default: Try(() => JSON.parse(window.localStorage.getItem(SIDER_IS_OPEN))) ?? true,
})

// export const siteState = atom({
//   key: 'site_id',
//   default: null,
// })

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

const initState: State = {
  user: undefined,
  lang: first(config.languages) as string,
  site: localStorage.getItem('site_id') || 'ieepa',
  data: {},
}

const fetchings: {[type: string]: { [id: string]: false | true | Error }[]} = {}

const actions = {
  login: ( store: Store<State, Actions>, user: any ) => {
    store.setState(produce(store.state, draft => {
      draft.user = user
      if(!includes(user?.permissions?.sites, draft.site)) {
        //@ts-ignore
        draft.site = null
      }
      draft.site = draft.site || get(user, `permissions.sites[0]`)
    }))
  },
  logout: ( store: Store<State, Actions> ) => {
    store.setState(produce(store.state, draft => {
      draft.user = null
      //@ts-ignore
      draft.site = null
    }))
  },

  setLang: ( store: Store<State, Actions>, value: string) => {
    store.setState(produce(store.state, draft => {
      draft.lang = value
    }))
  },

  setSite: ( store: Store<State, Actions>, value: string) => {
    localStorage.setItem('site_id', value)
    store.setState(produce(store.state, draft => {
      draft.site = value
    }))
  },

  load: (store: Store<State, Actions>, type: string, ids: string | string[], paths?: string | string[] ) => {

    let reqIds = (isString(ids) ? [ids] : ids).filter(id => (
      get(store.state.data, [type, id]) === undefined && (get(fetchings, [type, id]) === undefined)
    ))

    if(reqIds.length) {
      reqIds.forEach(id => set(fetchings, [type, id], true))

      api.service(type).find({
        query: {
          _id: {$in: reqIds},
          $limit: -1,
        }
      }).then((rows: DataRowType[]) => {
        store.setState(produce(store.state, draft => {
          reqIds.forEach(id => {
            set(draft.data, [type, id], find(rows, ['_id', id]) || null)
          })
        }))
        reqIds.forEach(id => unset(fetchings, [type, id]))
      })
    }
  },
};

const useGlobal = globalHook<State, Actions>(React, initState, actions)

const app = feathers();
const restClient = rest()

// axios.interceptors.request.use((req: AxiosRequestConfig) => {
//   req.headers['Content-Language'] = 
//   return req
// })

app.configure(restClient.axios(axios))
app.configure(auth())



export {
  useGlobal,
}

type ServiceNameType = 'user' | 'file' | 'site' | 'category' | 'leader' | 'post' | 'donation' | 'volunteer'

export type FilterType = {
  [key: string]: any
}

export type QueryOptionType = {
  limit?: number,
  skip?: number,
}

export type QueryResultType = {
  total: number,
  limit: number,
  skip: number,
  data: any[],
}

export const api = {
  authenticate: app.authenticate,
  reAuthenticate: app.reAuthenticate,
  logout: app.logout,
  service: (name: ServiceNameType | string) => ( app.service(name) as Service<any> ),

  user: (app.service('user') as Service<any>),
  file: (app.service('file') as Service<any>),
  site: (app.service('site') as Service<any>),
  category: (app.service('category') as Service<any>),
  leader: (app.service('leader') as Service<any>),
  post: (app.service('post') as Service<any>),
  donation: (app.service('donation') as Service<any>),
  volunteer: (app.service('volunteer') as Service<any>),

  find: async (type: string, filter?: FilterType, opt?: QueryOptionType ): Promise<QueryResultType> => {

    try {
      const result = await app.service(type).find({
        query: {
          ...filter,
          //@ts-ignore
          ...transform(opt, (o, val, key) => {
            o['$' + key] = val
          }, {}),
        }
      })

      return result
    } catch(e) {
      if(e instanceof FeathersError && e.code === 404) {
        return {
          data: [],
          total: 0,
          skip: 0,
          limit: 0,
        }
      } else {
        throw e
      }

    }

    
     
  }
  
}