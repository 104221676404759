import React from 'react';
import { Link } from 'react-router-dom';

import { 
  Button, 
} from 'reactstrap';

const Page404 = () => (
  <div className="container">
    <div className="row">
      <img className="col-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3" src={`${process.env.PUBLIC_URL}/img/404.svg`} />
    </div>
    <div className="row">
      <p className="col-12 h2 font-weight-normal mt-3 mb-4 text-center">
        页面没找到，或已被删除
      </p>
    </div>
    <div className="row my-5">
      <Link to="/" className="col-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 btn btn-primary btn-lg">
        返回首页
      </Link>
    </div>
  </div>
);

export default Page404;
