import React, { useState, useContext } from 'react'

import {
  Button as RsButton,
  Spinner,
  ButtonProps
} from 'reactstrap'

import { AppContext } from '../App'

import classnames from 'classnames'

import {
  isError,
} from 'lodash'


export default ({ spinner = true, onClick, size = '', children, ...props}) => {

  const app = useContext(AppContext)
  const [ pending,  setPending ] = useState(false)

  return (
    <RsButton size={size} active={pending} onClick={e => {
      if(!pending) {
        try {
          let rst = onClick(e)

          if(rst instanceof Promise) {
            setPending(true)
            rst.then(data => {
              setPending(false)
              data && app.alert(data.toString(), {type: "success"})
            }).catch(e => {
              setPending(false)
              e && app.alert(isError(e) ? e.message : String(e), {type: "error"})
            })
          } else if(rst) {
            app.alert(rst.toString(), {type: "success"})
          }
        } catch(e) {
          app.alert(isError(e) ? e.message : String(e), {type: "error"})
        }

        
      }
    }} { ...props }>
      <span className={classnames({invisible: spinner && pending})}>{children}</span>
      { spinner && pending && <Spinner size={size} /> }
    </RsButton>
  )
}