import React, { useState, useContext } from 'react';
import { withRouter, RouteComponentProps } from 'react-router'
import { NavLink } from 'react-router-dom';

import produce from 'immer'

import { Badge, Collapse } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  get,
} from 'lodash'

import routes from '../routes/index';
import { AppContext } from '../App';
import { useGlobal, siderState } from '../store';
import { useRecoilValue } from 'recoil'
import { Icon } from '../utils';

interface IComponentProps extends RouteComponentProps<any> {
  [propName: string]: any
}

const SidebarFold = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon,
    isOpen,
    children,
    onClick,
    location,
    to,
  }: IComponentProps) => {
    const getSidebarItemClass = path => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === '/' && path === '/dashboard')
        ? 'active'
        : '';
    };

    return (
      <li className={'sidebar-item ' + getSidebarItemClass(to)}>
        <span
          data-toggle='collapse'
          className={'sidebar-link ' + (!isOpen ? 'collapsed' : '')}
          onClick={onClick}
          aria-expanded={isOpen ? 'true' : 'false'}
        >
          {icon ? <Icon name={icon} className='align-middle mr-3 lead' /> : null}
          <span className='align-middle'>{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className='sidebar-badge'>
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id='item' className={'sidebar-dropdown list-unstyled'}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon, location, to }: IComponentProps) => {
    const getSidebarItemClass = path => {
      return location.pathname === path ? 'active' : '';
    };

    return (
      <li className={'sidebar-item ' + getSidebarItemClass(to)}>
        <NavLink to={to} className='sidebar-link d-flex align-items-center' activeClassName='active'>
          {icon ? <Icon name={icon} className='align-middle mr-3' /> : null}
          <span className="d-block">{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className='sidebar-badge'>
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

const Sidebar = ({ location }: RouteComponentProps<any>)  => {

  const app = useContext(AppContext)

  const sider = useRecoilValue(siderState)

  const [state, actions] = useGlobal()

  const initFold = {}

  routes.forEach((route, index) => {
    const isActive = location.pathname.indexOf(route.path) === 0;
    const isOpen = (route as any).open;
    const isHome = (route as any).containsHome && location.pathname === '/' ? true : false;

    initFold[index] = isActive || isOpen || isHome
  })

  const [ fold, setFold ] = useState(initFold)

  const toggle = index => {

    setFold(produce(fold, draft => {
      draft[index] = !fold[index]
    }))
  }

  let models = get(state, `user.permissions.models`, {})

  return (
    <nav
      className={
        'sidebar sidebar-sticky' +
        (!sider ? ' toggled' : '')
      }
    >
      <div className='sidebar-content'>
        <PerfectScrollbar>
          <NavLink to={'/'} className='sidebar-brand'>
            <img src={`${process.env.PUBLIC_URL}/img/icon.svg`} className='align-middle mr-2' alt="IEEPA ICON" style={{ width: '2rem', height: '2rem' }} />
            <span className='align-middle'>{process.env.REACT_APP_TITLE}</span>
          </NavLink>

          <ul className='sidebar-nav'>
            { routes.filter(({model = null}) => !model || models[model]).map((fold, index) => {
              return (
                <React.Fragment key={index}>
                  {(fold as any).header ? (
                    <li className='sidebar-header'>{(fold as any).header}</li>
                  ) : null}

                  {fold.children ? (
                    <SidebarFold
                      //@ts-ignore
                      name={fold.name}
                      badgeColor={(fold as any).badgeColor}
                      badgeText={(fold as any).badgeText}
                      icon={fold.icon}
                      to={fold.path}
                      isOpen={fold[index]}
                      onClick={() => toggle(index)}
                    >
                      {(fold as any).children.map((route, index) => (
                        <SidebarItem
                          key={index}
                          //@ts-ignore
                          name={route.name}
                          to={route.path}
                          badgeColor={route.badgeColor}
                          badgeText={route.badgeText}
                        />
                      ))}
                    </SidebarFold>
                  ) : (
                      <SidebarItem
                        //@ts-ignore
                        name={fold.name}
                        //@ts-ignore
                        to={fold.pathname || fold.path}
                        icon={fold.icon}
                        badgeColor={(fold as any).badgeColor}
                        badgeText={(fold as any).badgeText}
                      />
                    )}
                </React.Fragment>
              );
            })}
          </ul>

        </PerfectScrollbar>
      </div>
    </nav>
  )
}

export default withRouter(Sidebar)
