import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import {
  home as homeRoutes,
  dashboard as dashboardRoutes,
  auth as authRoutes,
} from './index'

import { useGlobal } from '../store'

import { get } from 'lodash'

import DashboardLayout from '../layouts/Dashboard'
import AuthLayout from '../layouts/Auth'
import Page404 from '../pages/auth/Page404'

// import ScrollToTop from '../components/ScrollToTop';

const createPrivateRoute = (user, path, Layout, Component) => (props) => {
  if (user === undefined) return null

  return path.startsWith('/auth/') || user ? (
    <Layout>
      <Component {...props} />
    </Layout>
  ) : (
    <Redirect
      to={{
        pathname: '/auth/login',
        state: { from: path },
      }}
    />
  )
}

const childRoutes = (user, Layout, routes, exact = true) => {
  return routes
    .filter(
      ({ model = null }) => !model || get(user, `permissions.models.${model}`)
    )
    .map(({ model = null, children, path, component: Component }, index) =>
      children ? (
        // Route item with children
        children.map(({ path, component: Component }, index) => (
          <Route
            key={index}
            path={path}
            exact={exact}
            render={createPrivateRoute(user, path, Layout, Component)}
          />
        ))
      ) : (
        // Route item without children
        <Route
          key={index}
          path={path}
          exact={exact}
          render={createPrivateRoute(user, path, Layout, Component)}
        />
      )
    )
}

const Routes = (props) => {
  const [state, actions] = useGlobal()

  return (
    <Switch>
      {childRoutes(state.user, DashboardLayout, homeRoutes)}
      {childRoutes(state.user, DashboardLayout, dashboardRoutes, false)}
      {childRoutes(state.user, AuthLayout, authRoutes)}
      <Route
        render={() => (
          <DashboardLayout>
            <Page404 />
          </DashboardLayout>
        )}
      />
    </Switch>
  )
}

export default Routes
