import React, { useState, useContext, useCallback, useEffect, useMemo, ComponentType } from 'react';
import { Badge, Button, Card, CardBody, CardFooter, CardHeader, Container, Input, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink } from "reactstrap";
import {
  TreeDataState,
  CustomTreeData,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableTreeColumn,
} from '@devexpress/dx-react-grid-bootstrap4';

import clsx from 'clsx'

import {
  generateRows,
  defaultColumnValues,
} from '../demo/generator';
import { AppContext } from '../App';

import ActionButton from '../components/Button';
import LanguageSelector from '../components/LanguageSelector';
import Spacer from '../components/Spacer';
import { Icon } from '../utils'
import LangNav from '../components/LangNav'
import LanguageFormatter from '../components/LanguageFormatter'

import { compact, debounce, get, isArray, isPlainObject, flatten, assign, startsWith, toUpper, includes, isEmpty, isError, last, lowerCase, pull, range, snakeCase, uniq, isString, dropWhile, find, findIndex, extend, omit } from 'lodash';

const defaultGetChildRows = (row, rootRows) => {
  const childRows = rootRows.filter(r => (r.parent_id || null) === (row ? row._id : null));
  return childRows.length ? childRows : null;
};

const FormatterMapping = {
  text: (({value, row, column}) => {

    const openEditor = useCallback(async () => {
      let ipt = window.prompt(column.title, value || '')

      if(ipt != null) {
        await row.$update({
          [column.name]: (ipt || null)
        })
      }
    }, [value])

    return (
      <div className="d-flex flex-row align-items-center justify-content-start">
        <span className={clsx(value ? 'text-body' : 'text-muted')}>{value || toUpper(row._id)}</span>
        <Button color="link" className="" onClick={openEditor} >
          <Icon name="fas:edit" />
        </Button>
      </div>
    ) 
  }) as ComponentType<DataTypeProvider.ValueFormatterProps>,
}

export interface DataTableColumnType {
  formatter: ComponentType<DataTypeProvider.ValueFormatterProps>
}

export interface DataTableColumn {
  name: string,
  type: 'text' | DataTableColumnType,
  title?: string,
  description?: string,
  width?: number,
}

export interface DataTableProps {
  columns: DataTableColumn[],
  rows: any[],
  id?: ((row: any) => string) | string,
  tree?: ((row:any, rootRows:any[]) => any[]) | boolean | null,
}

export default ({
  columns, 
  rows = [], 
  id = '_id',
  tree = false,
}: DataTableProps) => {

  if(!isArray(columns) || isEmpty(columns)) {
    return null
  }

  if(typeof id === 'string') {
    id = (row: any) => row[id as string]
  }

  if(tree === true) {
    tree = defaultGetChildRows
  }

  columns.map(c => {
    c.title = c.title || c.name
    c.description = c.description || c.title
    if(isString(c.type)) {
      c.type = {
        formatter: FormatterMapping[c.type]
      }
    }
  })

  const tableColumnExtensions = useMemo(() => {
    return columns.map(o => ({
      columnName: o.name,
      width: o.width,
    }))
  }, [columns])

  return (
    <Grid getRowId={id} rows={rows} columns={columns}>
      {columns
        .filter(c => isPlainObject(c.type))
        .map(({ name, type }) => (
          <DataTypeProvider
            key={name}
            for={[name]}
            formatterComponent={(type as DataTableColumnType).formatter}
          />
        ))}

      { tree && <TreeDataState /> }
      { tree && <CustomTreeData getChildRows={tree} /> }

      <Table columnExtensions={tableColumnExtensions} />
      <TableHeaderRow />

      { tree && <TableTreeColumn for={columns[0].name} /> }
    </Grid>
  );
};