import * as yup from 'yup'
import _ from 'lodash'

type SizeType = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

type SchemaMetaType = {
  type: string,

  label?: string,
  description?: string,
  icon?: string,
  size?: SizeType, // 'xs'

  lookup?: {[key: string]: any},
  required?: boolean, // false
  multiple?: [number, number?], // [min, max?]
  
  // br?: boolean, // false

  params?: yup.ObjectSchema<any>[]

  fields: { [key: string]: yup.SchemaOf<any> },

  constructors?: {[key: string]: yup.SchemaOf<any>}, //{}
  methods?: {[key: string]: { 
    label?: string,
    icon?: string,
    description?: string,
    isEnabled?: (value: any) => boolean | Promise<boolean>,
    param: yup.SchemaOf<any>,
  }}, //{}
  [key: string]: any
}

export function lookupSchema(schema: yup.StringSchema, lookup: SchemaMetaType['lookup'] = {}) {
  return schema.oneOf(Object.keys(lookup)).meta({
    lookup,
  }).transform(function(val, origVal) {
    return _.findKey(reflectSchema(this).lookup, v => _.isEqual(v, val)) || val
  })
}

export function modelSchema(schema: yup.ObjectSchema<any>, props: {
  methods?: SchemaMetaType['methods'],
} = {}) {
  return schema.meta({
    methods: props.methods || {},
  })
}

export function reflectSchema(schema: yup.SchemaOf<any>): SchemaMetaType {
  if(schema.type === 'array') {
    schema = (schema as yup.ArraySchema<any>).innerType
  }

  const { type, required, multiple, params, description, lookup, icon, constructors = {}, methods = {}, ...others } = (schema['_meta'] || {}) as SchemaMetaType

  // const fields: yup.SchemaOf<any>[] = []

  return {
    type:  type || schema.type,
    required: typeof(required) === 'boolean' ? required : schema?.describe?.().tests.some(({name}) => name === 'required'),

    params: params || [],
    label: schema['_label'],
    description,
    icon,

    lookup,
    multiple,

    //@ts-ignore
    fields: (schema['fields'] || {}) as {[key: string]: yup.SchemaOf<any> },
    constructors,
    methods,

    ...others,
  }

}


function configSchema<T>(schema: yup.SchemaOf<any>, meta: SchemaMetaType) {

  let {
    type,
  
    label,
    description,
    icon,
    size,
  
    required,
    multiple,
  } = meta

  if(label) {
    schema = schema.label(label)
  }

  if(required) {
    //@ts-ignore
    schema = schema.required()
  }

  //@ts-ignore
  schema = schema.meta({
    required,
    type,
    icon,
    description,
    size,
    multiple,
  })

  if(multiple) {
    schema = yup.array().of(schema)
    if( multiple[0] > 0) {
      schema = (schema as yup.ArraySchema<any>).min(multiple[0])
    }
    //@ts-ignore
    if(multiple[1] > 0) {
      schema = (schema as yup.ArraySchema<any>).max(multiple[0])
    }
  }

  return schema
}



