import React from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row
} from "reactstrap";

const Blank = () => (
  <Container fluid className="p-0">
    <h1 className="h3 mb-3">{process.env.REACT_APP_TITLE}</h1>
  </Container>
);

export default Blank;
