import React from 'react'

import Sidebar from '../components/Sidebar'
import Main from '../components/Main'
import Navbar from '../components/Navbar'
import Content from '../components/Content'
import Footer from '../components/Footer'
import Spacer from '../components/Spacer'

const Dashboard = ({ children }) => (
  <React.Fragment>
    <div className="wrapper">
      <Sidebar />
      <Main className="justify-content-start">
        <Navbar />
        <Content>{children}</Content>
        <Spacer />
        <Footer />
      </Main>
    </div>
  </React.Fragment>
)

export default Dashboard
