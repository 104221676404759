import React, { ComponentType, useCallback } from 'react'
import { DataTypeProvider } from '@devexpress/dx-react-grid'
import { get } from 'lodash'
import clsx from 'clsx'
import { Button } from 'reactstrap'

import { Icon } from '../utils'

const TextEditorFormatter: ComponentType<DataTypeProvider.ValueFormatterProps> = ({row, column}) => {

  let value = get(row, column.name, null)

  const openEditor = useCallback(async () => {
    let ipt = window.prompt(column.title, value || '')

    if(ipt != null) {
      await row.$update({
        [column.name]: (ipt || null)
      })
    }
  }, [value])

  return (
    <div className="d-flex flex-row align-items-center justify-content-start">
      <span className={clsx(value ? 'text-body' : 'text-muted')}>{value || ''}</span>
      <Button color="link" className="" onClick={openEditor} >
        <Icon name="fas:edit" />
      </Button>
    </div>
  ) 
}

export default TextEditorFormatter