import { CustomPaging, DataTypeProvider, DataTypeProviderProps, IntegratedSelection, PagingState, SelectionState, Sorting, SortingState, FilteringState } from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, Table, TableFixedColumns, TableHeaderRow, TableSelection, Toolbar } from '@devexpress/dx-react-grid-bootstrap4';
import classnames from 'classnames';
import { compact, debounce, get, assign, set, includes, isEmpty, flatten, isError, last, lowerCase, pull, range, snakeCase, uniq, isString, dropWhile, find, findIndex, extend, omit } from 'lodash';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button, Card, CardBody, CardFooter, CardHeader, Container, Input, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink } from "reactstrap";
import { useImmer } from "use-immer";
import { ARTICLE_STATUS, ARTICLE_STATUS_LABELS, SPACE_CHAR } from '../api';
import { AppContext } from '../App';
import ActionButton from '../components/Button';
import LanguageSelector from '../components/LanguageSelector';
import Spacer from '../components/Spacer';

import PostEditor from './PostEditor'
import CategoryDropdown from '../components/CategoryDropdown';
import { Icon } from '../utils'
import LanguageFormatter from '../components/LanguageFormatter'
import LangNav from '../components/LangNav'

import { api, useGlobal } from '../store'
import config from '../config'

import clsx from 'clsx'
import { useTranslation } from 'react-i18next';


const SortLabel = ({column, onSort, children, direction }) => {

  return (
    <ActionButton 
      size="sm"
      color="link"
      disabled={ !column.sortable }
      onClick={onSort}
    >
      {children}{' '}
      {(direction && <Icon name={`fas.fa-${direction === 'asc' ? 'chevron-up' : 'chevron-down'}`} />)}
    </ActionButton>
  );
}

interface IProgress {
  rate?: number;
  message: string;
}

interface ITableData<T> {
  rows: T[];
  count?: number;
  loading?: IProgress | string | boolean;
  error?: any;
}

interface ITreeData<T> {
  rows: T[];
  index: { [index: string]: T };
  tree: any;
}

const DEFAULT_CELL_COMPONENT = ({value}) => { return String(value) }

const createColumns = (updateRows, lang) => {

return ([

  { name: `content.title`, type: String, title: '标题', wrap: true, sortable:true, Component: ({row}) => {

    const { t } = useTranslation('zh_cn');

    return (<>
      <div className="d-flex align-items-center">
        <Link to={`/post/${row._id}`}>{get(row, `content$${lang}.title`, '[无标题]')}</Link>
        { row.image && row.image.url && (
          <Button color="link" className="ml-3 p-1 " onClick={async () => {
            await api.post.patch(row._id, {
              [`image.searchable`]: !row.image.searchable,
            })
            updateRows(draft => { 
              set(find(draft, ['_id', row._id]), 'image.searchable', !row.image.searchable)
            })
          }} >
            <i className={clsx((row.image.searchable ? 'fas' : 'fal'), `fa-image`, `text-${row.image.searchable ? 'secondary' : 'muted'}`)} />
          </Button>
        )}
        { row.video && row.video.url && (
          <Button color="link" className="ml-3 p-1 " onClick={async () => {
            await api.post.patch(row._id, {
              [`content$${lang}.video.searchable`]: !row.video.searchable,
            })
            updateRows(draft => { 
              set(find(draft, ['_id', row._id]), 'video.searchable', !row.video.searchable)
            })
          }} >
            <i className={clsx((row.video.searchable ? 'fas' : 'fal'), `fa-video`, `text-${row.video.searchable ? 'secondary' : 'muted'}`)} />
          </Button>
        )}
      </div>
      { row.tags &&
      <p>
        { uniq(get(row, `tags`, []) as string[]).map(s => (
          <Badge key={s} color="info" pill className="m-1">{s}</Badge>
        ))}
      </p>
      }
    </>)
  }},

  { name: `languages`, title: '语言', width: 100, Component: LanguageFormatter },

  { name: 'date', type: Date, title: '日期', width: 120, sortable:true, Component:({value}) => {

    const [opened, setOpen] = useState<boolean>(false)

    const open = useCallback(() => {
      setOpen(true)
    }, [setOpen])

    const cancel = useCallback(() => {
      setOpen(false)
    }, [setOpen])

    return (
      <div className="d-flex align-items-center justify-content-start">
        <span>{value ? moment(value).format(process.env.REACT_APP_DATE_FORMAT) : SPACE_CHAR}</span>
      </div>
    )
  }},

  { name: `content.from`, type: String, title: '来源', sortable:true, width: 200, wrap: true, Component: ({value}) => {
    return <span contentEditable={false}>{value}</span>
  }},

  { name: `category_id`, title: '板块', align: 'center', width: '10rem', Component: ({value, row}) => {

    const onChange = useCallback(async value => {
      let data = { category_id: value._id }
      await api.post.patch(_id, data)
      updateRows(draft => { 
        extend(find(draft, ['_id', _id]), { category: value })
      })
    }, [])

    let { _id, status } = row

    return <CategoryDropdown className="btn-sm" lang={lang} value={row.category} onChange={onChange} />
  }},

  { name: 'rank', title: '评级', width: 100, align: 'left',  type: Function, Component: ({value:rank, row}) => {

    let { _id, status } = row

    const app = useContext(AppContext)

    return (
      <div className="d-flex flex-row justify-content-end align-items-center">
        { (status === ARTICLE_STATUS.PUBLIC) && 
        <div className="">
        { range(5).map(n => (
          <span key={n} onClick={async (e) => {
            let data = { rank: n+1 }
            await api.post.patch(_id, data)
            updateRows(draft => { 
              extend(find(draft, ['_id', _id]), data)
            })
          }}>
          <Icon name="fas:star" style={{
            color: (rank > n) ? 'orange' : 'gray',
            cursor: 'pointer',
          }}  />
          </span>
        ))
        }
        </div>
        }
      </div>
    )
  } },

  { name: 'status', title: '操作', width: 80, align: 'right',  type: Function, Component: ({value:status, row}) => {

    let {_id, rank = 1} = row

    return (
      <div className="d-flex flex-row justify-content-end align-items-center">
        { (status === ARTICLE_STATUS.PUBLIC) && <ActionButton color="danger" size="sm" className="ml-1" onClick={async (e) => {
          let data = { status: ARTICLE_STATUS.ARCHIVED }
          await api.post.patch(_id, data)
          updateRows(draft => { 
            extend(find(draft, ['_id', _id]), data)
          })
        }}>撤下</ActionButton> }

        { (status === ARTICLE_STATUS.ARCHIVED) && 
        <>
        <ActionButton color="primary" size="sm" className="ml-1" onClick={async (e) => {
          let data = { status: ARTICLE_STATUS.PUBLIC }
          await api.post.patch(_id, data)
          updateRows(draft => { 
            extend(find(draft, ['_id', _id]), data)
          })
        }}>发布</ActionButton> 

        <ActionButton color="danger" size="sm" className="ml-1" onClick={async (e) => {
          if(window.confirm('确定删除？')) {
            await api.post.remove(_id)
            updateRows(draft => { 
              draft.splice(findIndex(draft, ['_id', _id]), 1)
            })
          }
        }}>删除</ActionButton> 
        </>
        }

      </div>
    )
  } },
])
}

export const KeywordsEditor = ({label = '...', value = [], onChange = (v:string[]) => {},  disabled = false, className = ''}) => {

  value = compact(uniq(value))

  const [open, setOpen] = useState(false)
  const toggleOpen = useCallback(() => {
    setOpen(!open)
  }, [open])

  const [input, setInput] = useState<string>('')

  const [words, updateWords] = useImmer<string[]>(value)

  // useEffect(() => {
  //   updateWords(draft => value)
  // }, [value])

  const onOpened = useCallback(() => {
    updateWords(() => value)
  }, [value])


  return (
    <div>
      <ActionButton color="info" size="sm" className={className} onClick={toggleOpen}>{label}</ActionButton>
      <Modal isOpen={open} toggle={toggleOpen} onOpened={onOpened} >
        <ModalHeader toggle={toggleOpen}>
          关键字
          {words.map(w => (
            <Badge key={w} onClick={() => {}} color="info" pill className="m-1">
              {w}{' '}
              <span onClick={() => {
                updateWords(draft => pull(draft, w))
              }}>
                <Icon name="fas:times-circle" />
              </span>
            </Badge>
          ))}
          
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-row justify-content-center align-items-center">
            <Input type="text" text={input} onChange={e => setInput(e.target.value)} className="flex-grow-1 flex-shrink-1" />
            <Button color="link" className="text-nowrap" onClick={() => {
              updateWords(draft => {
                if(!includes(draft, input)) draft.push(input)
              })
            }}>添加</Button>
          </div>
          <div className="d-flex flex-row justify-content-center align-items-center" style={{
            height: '20rem',
          }}>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => {toggleOpen(); onChange(words);}}>确定</Button>{' '}
          <Button color="secondary" onClick={toggleOpen}>取消</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}



const PostTable = ({pageSizes = [20, 50, 100], rows, updateRows, lang, setLang}) => {

  const app = useContext(AppContext)

  const [state, actions] = useGlobal()

  const columns = useMemo(() => createColumns(updateRows, lang), [updateRows, lang])

  const [count, setCount] = useState(0)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(pageSizes[0])

  const [selection, select] = useState<any[]>([])

  const [ commonFilters, updateCommonFilters ] = useImmer({
    content: { $exists: true },
    status: ARTICLE_STATUS.PUBLIC,
  } as any)

  const [ textFilters, setTextFilters ] = useState({})

  const [ typeFilters, updateTypeFilters] = useImmer({
    image: false,
    video: false,
    // keynote: false,
  })

  const [match, setMatch] = useState<string>('')
  const search = useCallback(() => {
    setCurrentPage(0)
    let words = match.split(/\s/).map(w => w.trim()).filter(w => w.length > 0)

    if(words.length > 0) {
      setTextFilters({
        $text: {
          $search: words,
        }
      })
    } else {
      setTextFilters({})
    }
  }, [match, setTextFilters, setCurrentPage])


  const filters: any = useMemo(() => ({
    ...commonFilters, 
    ...textFilters,
    languages: lang,
  }), [commonFilters, textFilters, lang])

  const [ sort, setSort ] = useState<{ [key: string] : 1 | -1 }>({
    date: -1,
  })

  const projection: string[] = useMemo(() => ([
    ...flatten(columns.map(c => c['fields'] || c.name)), 
    `tags`,
    `content.enasbled`,
    `image`,
    `video`,
  ]), [columns])

  const loadData = useCallback(async () => {


    let where = omit(filters, 'category')
    if(filters.category && filters.category._id) {
      let children = await api.category.find({
        query: {
          $lang: lang,
          $site: state.site,
          $limit: -1,
          $select: ['_id'],
          parent_id: filters.category._id,
        }
      })

      where.category_id = {
        $in: [
          filters.category._id,
          ...children.map(o => o._id),
        ]
      }
        
    }

    if(typeFilters.image) { where['image'] = {$exists: true} }
    if(typeFilters.video) { where['video'] = {$exists: true} }


    try {
      app.loading(async () => {
        // console.log(where)

      let { total, data: rows } = await api.post.find({
        query: {
          $lang: lang,
          $site: state.site,
          $select: projection,
          $populate: 'category',
          $skip: currentPage * pageSize,
          $limit: pageSize,
          $sort: sort,
          ...where,
        }
      })


      setCount(total)

      rows.forEach(row => {
        row.$update = async function(change: object) {
          await api.post.patch(this._id, change)
          let row = await api.post.get(this._id, {
            query: {
              $select: projection,
              $populate: 'category',
            },
          })
          updateRows(draft => {
            assign(find(draft, ['_id', row._id]), row)
          })
        }
      })

      updateRows(draft => rows)
      })
      

    } catch (e) {
      app.alert(isError(e) ? e.message : String(e), {type: "error"})
    }

    return
  }, [currentPage, pageSize, filters, typeFilters, sort, lang, state.site, columns])

  const withdraw = useCallback(async () => {
    // await update(coll, selection, { status: ARTICLE_STATUS.ARCHIVED })
  }, [selection])

  const publish = useCallback(async () => {
    // await update(coll, selection, { status: ARTICLE_STATUS.ACTIVE })
  }, [selection])

  useEffect(() => {
    loadData()
  }, [loadData, lang])

  // console.log(compact(ids).map(id => get(data, [coll, id], {_id:id})))

  return (
    <Card>
      <CardHeader className="d-flex flex-row align-items-center">
      {/* <h2 className="lead m-0 mr-3">文章列表</h2> */}
      <LangNav lang={lang} setLang={setLang} languages={config.languages as any} />

      <Nav className="align-items-center">
      { Object.entries(ARTICLE_STATUS_LABELS).map(([value, label]) => (
        <NavItem key={value}>
          <NavLink
            className={classnames({ active: commonFilters.status === value })}
            onClick={() => updateCommonFilters(filters => { filters.status = value })}
          >
            {label}
          </NavLink>
        </NavItem>
      ))}

        <CategoryDropdown className="btn ml-3" lang={lang} value={commonFilters.category} placeholder={"全部" as any} onChange={(value) => {
          updateCommonFilters((draft:any) => {
            if(value && value._id) {
              draft.category = value
            } else {
              delete draft.category
            }
          })
        }} />
      </Nav>
      
      

      <div className="position-relative">
        <input type="text" onReset={() => console.log('======')} placeholder="输入关键字，之间用空格分开" value={match} onChange={e => setMatch(e.currentTarget.value)} className="form-control ml-5" style={{width:'20rem'}} />
        {textFilters && Object.keys(textFilters).length > 0 &&
        <div className="position-absolute" style={{right:0, top:'50%', transform: 'translate(0, -50%)'}}>
          <Button className="mx-1" color="link" onClick={() => {
            setMatch('')
            setTextFilters({})
          }}>
            <Icon name="fas:times" />
          </Button>
        </div>
        }
      </div>
      <ActionButton color="link" className="mx-1" onClick={search}>
      <Icon name="fas:search" />{' '}查找
      </ActionButton>
      
      <Spacer />

      <ActionButton color="link" className="mx-1" onClick={loadData}>
      <Icon name="fas:sync-alt" />{' '}刷新
      </ActionButton>

      <Link to={`/post/new`} className="btn btn-link mx-1">
      <Icon name="fas:plus" />{' '}新建...
      </Link>
      
      </CardHeader>
      <CardBody>
        <Grid
          rows={rows}
          getRowId={row => row._id}
          columns={columns}
        >
          <SortingState
            sorting={Object.entries(sort).map(([name, value]) => ({columnName:name, direction: (value === 1) ? 'asc' : 'desc'}))}
            onSortingChange={(sorting: Sorting[]) => {
              const st = last(sorting)
              st && setSort({ [st.columnName]: (st.direction === 'asc') ? 1 : -1 })
            }}
          />

          <SelectionState 
            selection={selection}
            onSelectionChange={select}
          />

          <IntegratedSelection />

          {/* <IntegratedFiltering /> */}
          {/* <IntegratedSorting /> */}
          {/* <IntegratedPaging /> */}
          

          {/* <CurrencyTypeProvider for={currencyColumns} /> */}
          {/* <DragDropProvider /> */}


          {/* <DateTypeProvider for={columns.filter(({name, type}) => (type === Date)).map(row => row.name)} /> */}


          { columns.filter(col => !col.name.startsWith('$')).map(col => (
            <DataTypeProvider key={col.name} for={[col.name]} formatterComponent={({row}) => {
              let Comp = col.Component || DEFAULT_CELL_COMPONENT
              //@ts-ignore
              return <Comp value={get(row, col.name, null)} row={row} />
            }} />
          ))}
          
          <PagingState 
            currentPage={currentPage} 
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize} 
            onPageSizeChange={setPageSize}
          />
          <CustomPaging totalCount={count} />
          

          <Table messages={{  
              noData: '没有数据'
            }} 
            columnExtensions={columns.map(({name, align = 'left', width, wrap = false}) => ({
              columnName: name,
              align: (align as 'left'|'center'|'right'),
              width: width || undefined,
              wordWrapEnabled: wrap,
            }))}  
          />

          <PagingPanel pageSizes={pageSizes} />

          <TableSelection highlightRow showSelectAll selectByRowClick={false} />
          
          <TableHeaderRow showSortingControls sortLabelComponent={SortLabel} contentComponent={({column, children}) => {
            if(column.name === 'content.title') {
              return (
                <div className="d-flex align-items-center">
                  { children }
                  <div className="ml-4" />
                  { Object.entries(typeFilters).map(([k,v]) => (
                    <Button key={k} color="link" className="p-1" onClick={() => updateTypeFilters(draft => { draft[k] = !v})} >
                      <i className={clsx((v ? 'fas' : 'fal'), `fa-${k}`, `text-${v ? 'secondary' : 'muted'}`)} />
                    </Button>
                  ))}
                  
                </div>
              )
            } else {
              return <>{children}</>
            }
          }}  />


          {/* <TableFilterRow showFilterSelector cellComponent={FilterCellComponent} /> */}

          <TableFixedColumns leftColumns={[TableSelection.COLUMN_TYPE]} rightColumns={[]} />


         
        </Grid>
      </CardBody>

      <CardFooter className="d-flex flex-row justify-content-start align-items-center">
        {count ? <div className="mr-2">共{count}条</div> : null}
        {selection.length ? <div className="mr-2">选中{selection.length}条</div> : null}

        {/* { (filters.status === ARTICLE_STATUS.PUBLIC) &&
        <ActionButton disabled={selection.length === 0} color="link" className="mr-2 btn-outline-primary" onClick={withdraw}>
          撤下
        </ActionButton>
        }

        { (filters.status === ARTICLE_STATUS.ARCHIVED) &&
        <ActionButton disabled={selection.length === 0} color="link" className="mr-2 btn-outline-primary" onClick={publish}>
          上架
        </ActionButton>
        } */}

        {/* <CategoryDropdown className="mr-2" value={null} onChange={id => update('post', selection, { category_id: id })} direction="up" disabled={selection.length === 0} label="移动板块..." /> */}

        

        <Spacer />

      </CardFooter>
    </Card>
  );
};


export default ({ match, location, history }) => {

  let { id } = match.params

  const [state, actions] = useGlobal()

  const [lang, setLang] = useState<string>(state.lang)

  if(isString(id) && id.length !== 24) {
    id = null
  }

  const closeEditor = useCallback(() => {
    history.push('/post')
  }, [])

  const [rows, updateRows] = useImmer([])

  return (
    <>
      <Container fluid className="p-0">
        <PostTable rows={rows} updateRows={updateRows} lang={lang} setLang={setLang} />
      </Container>

      <Modal scrollable backdrop="static" isOpen={id !== undefined} size="lg" className="">
        <PostEditor history={history} id={id} onClose={closeEditor} updateRows={updateRows} defaultLang={lang} />
      </Modal>
    </>
  )
}
