import assert from 'assert'
import {
  trim,
  lowerCase, 
  snakeCase,
  isString,
  isEmpty,
} from 'lodash'

import * as yup from 'yup'

const ConfigSchema = yup.object().shape({
  languages: yup.array(yup.string()).default((process.env.REACT_APP_LANGUAGES as string).split(',').map(s => snakeCase(lowerCase(trim(s))))),
  pageSize: yup.number().integer().positive().default(parseInt(process.env.REACT_APP_QUERY_PAGE_SIZE as string)),
  dismissAfter: yup.number().integer().positive().default(parseInt(process.env.REACT_APP_DISMISS_TIMEOUT as string)),
})

const config = ConfigSchema.cast({})

export default config
