import React, { useCallback, useMemo } from 'react'

import * as yup from 'yup'
import { useForm } from 'react-hook-form';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input, FormText } from 'reactstrap';
import { isPlainObject } from 'lodash';
import { Icon } from '../utils';

import ActionButton from '../components/Button'
import { useLocation, useHistory } from 'react-router';
import { reflectSchema } from '../utils/yup';
import DataFormTextField from './DataFormTextField';


const DataFormDialog = <T extends object>({
  schema, 

  id = null, 
  setId,
  data = null,
  updateData,

  isOpen = false,
  close,
}: {
  schema: yup.ObjectSchema<any>,

  id?: string | null,
  setId?: (id: string | null) => void,
  data?: Partial<T> | null,
  updateData?: (data: any) => void,

  isOpen?: boolean,
  close?: () => void,
}) => {

  const {
    label,
    fields,
  } = useMemo(() => reflectSchema(schema), [schema])

  console.log(fields)

  const { register, setValue, handleSubmit, formState: { errors } } = useForm<any>();
  const onSubmit = useCallback((data) => {
    console.log(data);
  }, [])

  const loadData = useCallback(() => {
  }, [])

  return (
    
      <Modal scrollable backdrop="static" isOpen={isOpen} size="lg" className="">
        <form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader toggle={close} className="align-items-center">
          <div className="d-flex flex-row align-items-center">
            <h1 className="h3 m-1 mr-4">{id ? `编辑` : `新建`}{label}</h1>
            {id &&
            <ActionButton color="link" className="mx-1" onClick={loadData}>
              <Icon name="fas:sync-alt" />{' '}刷新
            </ActionButton>
            }
          </div>  
        </ModalHeader>
        <ModalBody>
        {/* <FormGroup>
          <Label for="exampleEmail">Email</Label>
          <Input type="email" name="email" innerRef={register} />
          <FormText color="muted">SFDSA</FormText>
        </FormGroup> */}
        {/* <DataFormTextField ref={register} /> */}
        </ModalBody>
        <ModalFooter>
            <Button color="primary" type="submit">
              <Icon name="fas:save" /> {id ? '保存' : '创建'}
            </Button>
        </ModalFooter>
        </form>
      </Modal>
    
  )
}

export default DataFormDialog
