import {
  TextSchemaType,
  ObjectSchemaType,
  DateSchemaType,
  ModelSchemaType,
} from '../utils/schema'

    // no: {type: String, required: true},
    // name: { type: String, required: true },
    // email: { type: String, required: true },

    // gender: { type: Number, enum: [0, 1] },
    // location: { type: String, }, // 所在地
    // mobile: { type: String },
    // languages: { type: String, }, // 语种
    // degree: { type: String }, // 学历
    // subject: { type: String }, // 专业
    // special: { type: String }, // 其他特长
    // remark: { type: String }, // 备注


const VolunteerSchema: ModelSchemaType = {
  type: 'object',
  label: '志愿者',
  fields: {
    no: { schema: { type: 'text', label: '编号' }, required: true },
    name: { schema: { type: 'text', label: '姓名' }, required: true },
    email: { schema: { type: 'text', label: '邮箱' }, required: true },

    sex: { schema: { type: 'text', label: '性别', select: [
      { value: '0', label: '女' },
      { value: '1', label: '男' },
    ]}},

    addr: { schema: { type: 'text', label: '所在地' }},
    tel: { schema: { type: 'text', label: '电话' }},
    languages: { schema: { type: 'text', label: '语种' }},
    degree: { schema: { type: 'text', label: '学历' }},
    subject: { schema: { type: 'text', label: '专业' }},
    special: { schema: { type: 'text', label: '其他特长' }},
    remark: { schema: { type: 'text', label: '备注' }},

    duration: { schema: { type: 'number', label: '服务时长', integer: true }},
  },
  updates: {
    sign: {
      label: '签发',
      param: {
        type: 'object',
        label: '签发信息',
        fields: { signedAt: { schema: { type: 'date', label: '签发日期' } }},
      },
    }
  }
}

export default VolunteerSchema