import React, { useEffect, useState, useContext, useMemo, useCallback } from 'react'

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  Input,
  Label,
  Button,
  Nav, NavItem, NavLink, Badge, ModalFooter, Modal, ModalHeader, ModalBody,
} from "reactstrap";

import classnames from 'classnames'

import Datetime from 'react-datetime'

import { Value } from 'slate'

import { useImmer } from 'use-immer';
import { AppContext } from '../App';
import delay from 'delay'
import clsx from 'clsx'

import { LOCALE_LABELS, SPACE_CHAR } from '../api';

import ActionButton from '../components/Button'
import { Icon } from '../utils'
import ValueInput from '../components/ValueInput'

import { api, useGlobal } from '../store'
import { openFile, uploadFile } from '../utils/file'

import {
  isPlainObject,
  isError,
  isDate,
  isArray,
  isEmpty,
  get,
  set,
  pick,
  uniq,
  omit,
  snakeCase,
  lowerCase,
  defaults,
  find,
  extend,
  cloneDeep,
} from 'lodash'


import queryString from 'query-string'
import { Link } from 'react-router-dom';
import moment, { Moment } from 'moment'
import ReactQuill from 'react-quill';
import Spacer from '../components/Spacer';

import { KeywordsEditor } from './Leader'
import TextEditor from '../components/TextEditor';

import LanguageSelector from '../components/LanguageSelector';
import LangNav from '../components/LangNav'

import config from '../config'

import { cleanNodes } from '../utils'
import { StatusTypes } from 'react-async';


const BLANK_BODY = [{
  "object": "block",
  "type": "paragraph",
  "nodes": [{
    "object": "text",
    "text": "",
    "marks": []
  }],
}]

const LeaderEditor = ({id, history, onClose, updateRows}) => {

  const app = useContext(AppContext)

  const [state, actions] = useGlobal()
  const [data, updateData] = useImmer<any>(undefined)
  const [lang, setLang] = useState<string>(config.languages[0] as string)

  const setLanguages = useCallback((languages) => {
    updateData(draft => {
      draft.languages = languages
    })
  }, [updateData])

  const loadData = useCallback(async () => {
    let data = {}

    if(id) {
      try {
        await app.loading(async () => {
          data = await api.leader.get(id, {
            query: {
              $site: state.site,
            }
          })
        })
      } catch (e) {
        app.alert(isError(e) ? e.message : String(e), {type: "error"})
        return
      }
    }

    config.languages.forEach(l => {
      data[`intro$${l}`] = Value.fromJSON({
        object: 'value',
        document: {
          object: 'document',
          nodes: (isArray(data[`intro$${l}`]) && !isEmpty(data[`intro$${l}`])) ? data[`intro$${l}`] : [{
            "object": "block",
            "type": "paragraph",
            "nodes": [],
          }],
        },
      }) 
    })

    updateData(draft => data)
    
  }, [id, updateData, state.site])

  useEffect(() => {
    loadData()
  }, [loadData])

  return (
    <>
      <ModalHeader toggle={onClose} className="align-items-center">

      <div className="d-flex flex-row align-items-center">
  <h1 className="h3 m-1 mr-4">{id ? `编辑` : `新建`}领导资料</h1>
        { data && <LangNav lang={lang} setLang={setLang} languages={data.languages} setLanguages={setLanguages as any} /> }
        <div className="ml-4" />
        <ActionButton color="link" className="mx-1" onClick={loadData}>
          <Icon name="fas:sync-alt" />{' '}刷新
        </ActionButton>
      </div>

      </ModalHeader>
        <ModalBody>
        <Container fluid className="p-0">
      {(data === null) && <div className="text-center"><Link to="/leader">找不到此领导资料，返回领导资料列表</Link></div>}
      { isError(data) && <div className="text-center"><Button color="link" spinner={false} onClick={loadData}>网络异常， 重新加载</Button></div> }
      { isPlainObject(data) && (<>

      <Row>
        <Col>
          { (data != null) &&
          <Card>
            <CardHeader className="d-flex flex-row align-items-center">
              <CardTitle className="lead mb-0">基本信息</CardTitle>
              <Spacer />
            </CardHeader>

            <CardBody>
            <Label>姓名</Label>
              <Input type="text" width="120" value={get(data, `name$${lang}`, '')} onChange={e => {
                let v = e.target.value || ''
                updateData(row => set(row, `name$${lang}`, v))
              }}  placeholder="姓名" />

              <div className="my-3" />
              <Label>职务</Label>
              
              <Input type="text" width="120" value={get(data, `title$${lang}`, '')} onChange={e => {
                let v = e.target.value || ''
                updateData(row => set(row, `title$${lang}`, v))
              }}  placeholder="职务" />
            </CardBody>

          </Card>
          }

          <Card>
            <CardHeader className="d-flex align-items-center">
              <CardTitle className="lead mb-0">照片<small className="text-danger ml-3">（图片大小请勿超过10M）</small></CardTitle>
              <Spacer />
              <Button color="link" onClick={async () => {
                let imgFile = await openFile(['jpg', 'png'])
  
                if(imgFile) {
                  let url = await uploadFile(imgFile)
                  updateData(row => set(row, `photo`, url))  
                }
                
              }}>{data.photo ? '重新' : ''}上传...</Button>
              { data.photo && <Button className="ml-2" color="danger" onClick={() => {
                updateData(row => set(row, `photo`, undefined))  
              }}>删除</Button> }
            </CardHeader>
            <CardBody>
              { data.photo && 
              <img className="m-2 w-100" src={process.env.REACT_APP_CDN_URL + '/img/' + data.photo} style={{
                maxWidth: '12rem',
              }} />
              }
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle className="lead mb-0">简介<small className="text-danger ml-3">（图片大小请勿超过10M）</small></CardTitle>
            </CardHeader>
            <CardBody>
              { 
                //@ts-ignore
                <TextEditor app={app} value={data[`intro$${lang}`]} placeholder="编辑领导简介" onChange={({value}) => {
                  updateData(draft => {
                    draft[`intro$${lang}`] = value
                  })
                }} /> 
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
      </>)}
    </Container>
        </ModalBody>
        <ModalFooter>
        { isPlainObject(data) &&
          <ActionButton color="primary" className="mx-1" onClick={async () => {

            let doc: any = cloneDeep(data)
            config.languages.forEach(l => {
              doc[`intro$${l}`] = cleanNodes(data[`intro$${l}`].toJS().document.nodes)
            })

            if(id) {
              let up = await api.leader.patch(id, doc)
              updateRows(rows => {
                extend(find(rows, ['_id', id]), up)
              })
              return '保存成功'
            } else {
              let res:any = await api.leader.create(defaults({}, doc, {
                status: 'public',
                rank: 1,
                version: 1,
              }), {
                query: {
                  $site: state.site 
                }
              })

              if(res._id) {
                history.replace(`/leader/${res._id}`) 
                return '创建成功'
              }
            }
          }}>
            <Icon name="fas:save" /> {id ? '保存' : '创建'}
          </ActionButton>
        }
        </ModalFooter>
    </>
  )
};

export default LeaderEditor;
