import { CustomPaging, DataTypeProvider, DataTypeProviderProps, IntegratedSelection, PagingState, SelectionState, Sorting, SortingState, FilteringState } from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, Table, TableFixedColumns, TableHeaderRow, TableSelection, Toolbar } from '@devexpress/dx-react-grid-bootstrap4';
import classnames from 'classnames';
import { compact, debounce, get, set, includes, isEmpty, isError, last, lowerCase, pull, range, snakeCase, uniq, isString, dropWhile, find, findIndex, extend, omit, assign } from 'lodash';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button, Card, CardBody, CardFooter, CardHeader, Container, Input, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink } from "reactstrap";
import { useImmer } from "use-immer";
import { ARTICLE_STATUS, ARTICLE_STATUS_LABELS, SPACE_CHAR } from '../api';
import { AppContext } from '../App';
import ActionButton from '../components/Button';
import LanguageSelector from '../components/LanguageSelector';
import Spacer from '../components/Spacer';
import LangNav from '../components/LangNav'
import LanguageFormatter from '../components/LanguageFormatter'

import LeaderEditor from './LeaderEditor'
import { Icon } from '../utils'

import { useTranslation } from "react-i18next";

import { api, useGlobal } from '../store'
import { State, Actions } from '../store/types'

import clsx from 'clsx'
import Content from '../components/Content';
import config from '../config'

import Slider from 'react-bootstrap-slider'
import TextEditorFormatter from '../components/TextEditorFormatter';

const SortLabel = ({column, onSort, children, direction }) => {

  return (
    <Button 
      size="sm"
      color="link"
      disabled={ !column.sortable }
      onClick={onSort}
      className="p-0"
    >
      {children}{' '}
      {(direction && <Icon name={`fas.fa-${direction === 'asc' ? 'chevron-up' : 'chevron-down'}`} />)}
    </Button>
  );
}

interface IProgress {
  rate?: number;
  message: string;
}

interface ITableData<T> {
  rows: T[];
  count?: number;
  loading?: IProgress | string | boolean;
  error?: any;
}

interface ITreeData<T> {
  rows: T[];
  index: { [index: string]: T };
  tree: any;
}

const DEFAULT_CELL_COMPONENT = ({value}) => { return String(value) }

const createColumns = (updateRows, lang) => {



return ([

  { name: `photo`, type: String, title: '照片', width: '6rem', wrap: true, Component: ({value, row}) => {

    return (<>
      <div className="d-flex align-items-center">
        <img src={`${process.env.REACT_APP_CDN_URL}/img/${row.photo}`} className="w-100" />
      </div>
    </>)
  }},

  { name: `name`, fields: config.languages.map(s => `name$${s}`), type: String, width:'12rem', title: '姓名', sortable:true, Component: ({value, row}) => {

    const { t } = useTranslation();

    return (<>
      <div className="d-flex align-items-center">
        <Link to={`/leader/${row._id}`}>{row[`name$${lang}`] || t('no name')}</Link>
      </div>
    </>)
  }},

  { name: `languages`, title: '语言', width: 100, Component: LanguageFormatter },

  { name: 'group', title: '分组', width: '5rem', align: 'left',  wrap: false, formatter: TextEditorFormatter },

  { name: 'rank', title: '评级', sortable:true, width: 100, align: 'left',  type: Function, Component: ({value, row}) => {

    let { _id, status } = row

    const app = useContext(AppContext)

    return (
      <div className="d-flex flex-row justify-content-end align-items-center">
        { (status === ARTICLE_STATUS.PUBLIC) && 

        <Slider
          value={value}
          max={100}
          min={1}
          change={({target}) => row.$update({
            rank: target.value,
          })}
        />
        // <div className="">
        // { range(5).map(n => (
        //   <span key={n} onClick={async (e) => {
        //     let data = { value: n+1 }
        //     await api.leader.patch(_id, data)
        //     updateRows(draft => { 
        //       extend(find(draft, ['_id', _id]), data)
        //     })
        //   }}>
        //   <Icon name="fas:star" style={{
        //     color: (value > n) ? 'orange' : 'gray',
        //     cursor: 'pointer',
        //   }}  />
        //   </span>
        // ))
        // }
        // </div>
        }
      </div>
    )
  } },

  { name: 'status', title: '操作', width: '8rem', align: 'right',  type: Function, Component: ({value:status, row}) => {

    let {_id, rank = 1} = row

    const app = useContext(AppContext)

    return (
      <div className="d-flex flex-row justify-content-end align-items-center">
        { (status === ARTICLE_STATUS.PUBLIC) && <ActionButton color="danger" size="sm" className="ml-1" onClick={async (e) => {
          let data = { status: ARTICLE_STATUS.ARCHIVED }
          await api.leader.patch(_id, data)
          updateRows(draft => { 
            extend(find(draft, ['_id', _id]), data)
          })
        }}>撤下</ActionButton> }

        { (status === ARTICLE_STATUS.ARCHIVED) && 
        <>
        <ActionButton color="primary" size="sm" className="ml-1" onClick={async (e) => {
          let data = { status: ARTICLE_STATUS.PUBLIC }
          await api.leader.patch(_id, data)
          updateRows(draft => { 
            extend(find(draft, ['_id', _id]), data)
          })
        }}>发布</ActionButton> 

        <ActionButton color="danger" size="sm" className="ml-1" onClick={async (e) => {
          if(window.confirm('确定删除？')) {
            await api.leader.remove(_id)
            updateRows(draft => { 
              draft.splice(findIndex(draft, ['_id', _id]), 1)
            })
          }
        }}>删除</ActionButton>

        </>
        }


      </div>
    )
  } },
])
}

export const KeywordsEditor = ({label = '...', value = [], onChange = (v:string[]) => {},  disabled = false, className = ''}) => {

  value = compact(uniq(value))

  const [open, setOpen] = useState(false)
  const toggleOpen = useCallback(() => {
    setOpen(!open)
  }, [open])

  const [input, setInput] = useState<string>('')

  const [words, updateWords] = useImmer<string[]>(value)

  // useEffect(() => {
  //   updateWords(draft => value)
  // }, [value])

  const onOpened = useCallback(() => {
    updateWords(() => value)
  }, [value])


  return (
    <div>
      <ActionButton color="info" size="sm" className={className} onClick={toggleOpen}>{label}</ActionButton>
      <Modal isOpen={open} toggle={toggleOpen} onOpened={onOpened} >
        <ModalHeader toggle={toggleOpen}>
          关键字
          {words.map(w => (
            <Badge key={w} onClick={() => {}} color="info" pill className="m-1">
              {w}{' '}
              <span onClick={() => {
                updateWords(draft => pull(draft, w))
              }}>
                <Icon name="fas:times-circle" />
              </span>
            </Badge>
          ))}
          
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-row justify-content-center align-items-center">
            <Input type="text" text={input} onChange={e => setInput(e.target.value)} className="flex-grow-1 flex-shrink-1" />
            <Button color="link" className="text-nowrap" onClick={() => {
              updateWords(draft => {
                if(!includes(draft, input)) draft.push(input)
              })
            }}>添加</Button>
          </div>
          <div className="d-flex flex-row justify-content-center align-items-center" style={{
            height: '20rem',
          }}>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => {toggleOpen(); onChange(words);}}>确定</Button>{' '}
          <Button color="secondary" onClick={toggleOpen}>取消</Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

const DateTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={({ value }) => moment(value).format('YYYY-MM-DD')}
    {...props}
  />
)



const DataTable = ({pageSizes = [20, 50, 100], rows, updateRows}) => {

  const app = useContext(AppContext)

  const [state, actions] = useGlobal()
  const [lang, setLang] = useState<string>(config?.languages?.[0] || 'zh-CN')

  const columns = useMemo(() => createColumns(updateRows, lang), [updateRows, lang])

  const [count, setCount] = useState(0)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(pageSizes[0])

  const [selection, select] = useState<any[]>([])

  const [ commonFilters, updateCommonFilters ] = useImmer({
    status: ARTICLE_STATUS.PUBLIC,
  } as any)

  const [ textFilters, setTextFilters ] = useState({})

  const [match, setMatch] = useState<string>('')
  const search = useCallback(() => {
    setCurrentPage(0)
    let words = match.split(/\s/).map(w => w.trim()).filter(w => w.length > 0)

    if(words.length > 0) {
      setTextFilters({
        $text: {
          $search: words,
        }
      })
      setTextFilters({
        $and: words.map(w => {
          let re = new RegExp(w, 'i')
          return {
            $or: [
              { [`name`]: { $regex: re } },
            ]
          }
        })
      })
    } else {
      setTextFilters({})
    }
  }, [match, setTextFilters, setCurrentPage, state.site])


  const filters: any = useMemo(() => ({
    ...commonFilters, 
    ...textFilters,  
    languages: lang,
  }), [commonFilters, textFilters, lang])

  const [ sort, setSort ] = useState<{ [key: string] : 1 | -1 }>({
    rank: -1,
  })

  const loadData = useCallback(async () => {


    let where = omit(filters, 'category')
    if(filters.category && filters.category._id) {
      where.category_id = filters.category._id
    }

    try {
      await app.loading(async () => {

        let { total, data } = await api.leader.find({
          query: {
            $lang: lang,
            $site: state.site,
            $select: [
              ...columns.map(c => c.name).filter(n => !n.startsWith('_')), 
            ],
            $skip: currentPage * pageSize,
            $limit: pageSize,
            $sort: sort,
            ...where,
          }
        })

        data.forEach(row => {
          row.$update = async function(change: object | null = null) {
            if(change) {
              await api.leader.patch(this._id, change)
            }
            let row = await api.leader.get(this._id, {
              query: {
                $site: state.site,
              }
            })
            if(row) {
              updateRows(draft => {
                assign(find(draft, ['_id', row._id]), row)
              })
            }
          }
        })

        setCount(total)

        updateRows(draft => data)
      })

    } catch (e) {
      app.alert(isError(e) ? e.message : String(e), {type: "error"})
    }

    return
  }, [currentPage, pageSize, filters, sort, lang, columns, state.site])

  const withdraw = useCallback(async () => {
    // await update(coll, selection, { status: ARTICLE_STATUS.ARCHIVED })
  }, [selection])

  const publish = useCallback(async () => {
    // await update(coll, selection, { status: ARTICLE_STATUS.ACTIVE })
  }, [selection])

  useEffect(() => {
    loadData()
  }, [loadData])

  // console.log(compact(ids).map(id => get(data, [coll, id], {_id:id})))

  return (
    <Card>
      <CardHeader className="d-flex flex-row align-items-center">
      {/* <h2 className="lead m-0 mr-3">领导资料列表</h2> */}
      <LangNav lang={lang} setLang={setLang} languages={config.languages as string[]} />

      <Nav className="align-items-center">
      { Object.entries(ARTICLE_STATUS_LABELS).map(([value, label]) => (
        <NavItem key={value}>
          <NavLink
            className={classnames({ active: commonFilters.status === value })}
            onClick={() => updateCommonFilters(filters => { filters.status = value })}
          >
            {label}
          </NavLink>
        </NavItem>
      ))}
      </Nav>
      
      

      <div className="position-relative">
        <input type="text" onReset={() => console.log('======')} placeholder="输入关键字，之间用空格分开" value={match} onChange={e => setMatch(e.currentTarget.value)} className="form-control ml-5" style={{width:'20rem'}} />
        {textFilters && Object.keys(textFilters).length > 0 &&
        <div className="position-absolute" style={{right:0, top:'50%', transform: 'translate(0, -50%)'}}>
          <Button className="mx-1" color="link" onClick={() => {
            setMatch('')
            setTextFilters({})
          }}>
            <Icon name="fas:times" />
          </Button>
        </div>
        }
      </div>
      <ActionButton color="link" className="mx-1" onClick={search}>
      <Icon name="fas:search" />{' '}查找
      </ActionButton>
      
      <Spacer />

      <ActionButton color="link" className="mx-1" onClick={loadData}>
      <Icon name="fas:sync-alt" />{' '}刷新
      </ActionButton>

      <Link to={`/leader/new`} className="btn btn-link mx-1">
      <Icon name="fas:plus" />{' '}新建...
      </Link>
      
      </CardHeader>
      <CardBody>
        <Grid
          rows={rows}
          getRowId={row => row._id}
          columns={columns}
        >
          {/* <Toolbar rootComponent={ToolbarComponent} /> */}

          {/* <EditingState onCommitChanges={() => {}} /> */}


          {/* <FieldTypeProvider columns={columns} /> */}
          

          {/* <FilteringState
            filters={Object.entries(filters).map(([name, value]) => ({columnName:name, value}))}
            onFiltersChange={ filters => updateFilters(draft => {
              filters.forEach(({columnName, value}) => (draft[columnName] = value ))
            })}
          /> */}

          <SortingState
            sorting={Object.entries(sort).map(([name, value]) => ({columnName:name, direction: (value === 1) ? 'asc' : 'desc'}))}
            onSortingChange={(sorting: Sorting[]) => {
              const st = last(sorting)
              st && setSort({ [st.columnName]: (st.direction === 'asc') ? 1 : -1 })
            }}
          />

          <SelectionState 
            selection={selection}
            onSelectionChange={select}
          />

          <IntegratedSelection />

          {/* <IntegratedFiltering /> */}
          {/* <IntegratedSorting /> */}
          {/* <IntegratedPaging /> */}
          

          {/* <CurrencyTypeProvider for={currencyColumns} /> */}
          {/* <DragDropProvider /> */}


          {/* <DateTypeProvider for={columns.filter(({name, type}) => (type === Date)).map(row => row.name)} /> */}


          { columns.filter(col => !col.name.startsWith('$')).map(({name, Component, formatter}) => {
          
            if(name === 'group') {
              return <DataTypeProvider key={name} for={[name]} formatterComponent={formatter} />
            }

            return (
              <DataTypeProvider key={name} for={[name]} formatterComponent={({row}) => {
                let Comp = Component || DEFAULT_CELL_COMPONENT
                //@ts-ignore
                return <Comp value={get(row, name, null)} row={row} />
              }} />
            )
          })}

          {/* { columns.filter(c => c.formatter).map(({name, formatter}) => (
            //@ts-ignore
            <DataTypeProvider key={name} for={[name]} formatterComponent={formatter} />
          ))} */}
          
          <PagingState 
            currentPage={currentPage} 
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize} 
            onPageSizeChange={setPageSize}
          />
          <CustomPaging totalCount={count} />
          

          <Table messages={{  
              noData: '没有数据'
            }} 
            columnExtensions={columns.map(({name, align = 'left', width, wrap = false}) => ({
              columnName: name,
              align: (align as 'left'|'center'|'right'),
              width: width || undefined,
              wordWrapEnabled: wrap,
            }))}  
          />

          <PagingPanel pageSizes={pageSizes} />

          <TableSelection highlightRow showSelectAll selectByRowClick={false} />
          
          <TableHeaderRow showSortingControls sortLabelComponent={SortLabel} />


          {/* <TableFilterRow showFilterSelector cellComponent={FilterCellComponent} /> */}

          <TableFixedColumns leftColumns={[TableSelection.COLUMN_TYPE]} rightColumns={[]} />


         
        </Grid>
      </CardBody>

      <CardFooter className="d-flex flex-row justify-content-start align-items-center">
        {count ? <div className="mr-2">共{count}条</div> : null}
        {selection.length ? <div className="mr-2">选中{selection.length}条</div> : null}

        {/* { (filters.status === ARTICLE_STATUS.PUBLIC) &&
        <ActionButton disabled={selection.length === 0} color="link" className="mr-2 btn-outline-primary" onClick={withdraw}>
          撤下
        </ActionButton>
        }

        { (filters.status === ARTICLE_STATUS.ARCHIVED) &&
        <ActionButton disabled={selection.length === 0} color="link" className="mr-2 btn-outline-primary" onClick={publish}>
          上架
        </ActionButton>
        } */}


        

        <Spacer />

      </CardFooter>
    </Card>
  );
};

const Leader = ({ match, location, history }) => {

  

  let { id } = match.params

  console.log(`*************`, id)

  if(isString(id) && id.length !== 24) {
    id = null
  }

  const closeEditor = useCallback(() => {
    history.push('/leader')
  }, [])

  const [rows, updateRows] = useImmer([])

  return (
    <>
      <Container fluid className="p-0">
        <DataTable rows={rows} updateRows={updateRows} />
      </Container>

      <Modal scrollable backdrop="static" isOpen={id !== undefined} size="lg" className="">
        <LeaderEditor history={history} id={id} onClose={closeEditor} updateRows={updateRows} />
      </Modal>
    </>
  )
}

export default Leader;
