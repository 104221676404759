import { Editor } from 'slate-react'
import { 
  Value,
  Node as SlateNode,
  Block as BlockNode,
} from 'slate'


import React from 'react'
import initialValue from './editor.value.json'
import { isKeyHotkey } from 'is-hotkey'
import { Button, Toolbar } from './TextEditorComponents'

import { Icon } from '../utils'

import Html from 'slate-html-serializer'
import { SPACE_CHAR } from '../api';

import isUrl from 'is-url'
import { api, useGlobal } from '../store'
import { openFile, uploadFile } from '../utils/file'

import {
  Button as BsButton,
} from 'reactstrap'
import clsx from 'clsx'

const BLOCK_TAGS = {
  p: 'paragraph',
  ul: 'bulleted-list',
  ol: 'numbered-list',
  li: 'list-item',
  h3: 'heading-three',

};

const INLINE_TAGS = {
  a: 'link'
};

// Add a dictionary of mark tags.
const MARK_TAGS = {
  em: 'italic',
  strong: 'bold',
  // u: 'underlined',
};

const rules = [
  {
      deserialize: function(el, next) {
          const type = BLOCK_TAGS[el.tagName];
          console.log(el);
          if (!type) { return; }
          return {
              kind: 'block',
              type: type,
              nodes: next(el.childNodes)
          };
      },
      serialize: function(object, children) {
          switch (object.type) {
              case 'image':
                return (
                  <figure>
                    <img src={object.data.src} />
                    <figcaption>{object.data.caption}</figcaption>
                  </figure>
                )
              case 'block-quote':
                  return <blockquote>{children}</blockquote>;
              case 'numbered-list':
                  return <ol>{children}</ol>;
              case 'bulleted-list':
                  return <ul>{children}</ul>;
              case 'list-item':
                  return <li>{children}</li>;
              case 'paragraph':
                  return <p>{children}</p>;
              case 'heading-three':
                  return <h3>{children}</h3>;
              case 'link':
                  return <a href={object.data.href}>{children}</a>;
          }
      }
  },
  // Add a new rule that handles marks...
  {
      deserialize: function(el, next) {
          const type = MARK_TAGS[el.tagName];
          if (!type) { return; }
          return {
              kind: 'mark',
              type: type,
              nodes: next(el.childNodes)
          };
      },
      serialize: function(object, children) {
          // if (object.kind != 'mark') { return; }
          switch (object.type) {
              case 'bold':
                  return <strong>{children}</strong>;
              case 'italic':
                  return <em>{children}</em>;
              case 'underline':
                  return <u>{children}</u>;
          }
      }
  },
  {
      deserialize: function (el, next) {
          if (el.tagName != 'a') { return; }
          const type = INLINE_TAGS[el.tagName];

          if (!type) {
              return;
          }
          return {
              kind: 'inline',
              type: type,
              nodes: next(el.childNodes),
              data: {
                  href: el.attrs.find(({name}) => name == 'href').value
              }
          };
      },
  },
];

const html = new Html({
  rules,
})

// export const doc = html.serialize(initialValue)
// console.log(doc)


/**
 * Define the default node type.
 *
 * @type {String}
 */

const DEFAULT_NODE = 'paragraph'

/**
 * Define hotkey matchers.
 *
 * @type {Function}
 */

const isBoldHotkey = isKeyHotkey('mod+b')
const isItalicHotkey = isKeyHotkey('mod+i')
const isUnderlinedHotkey = isKeyHotkey('mod+u')
const isCodeHotkey = isKeyHotkey('mod+`')

function insertImage(editor: Editor, { src, caption = null }, target) {
  if (target) {
    editor.select(target)
  }

  editor.insertBlock({
    type: 'image',
    data: { 
      src, 
      // caption,
    },
  })
}

function wrapLink(editor, href) {
  editor.wrapInline({
    type: 'link',
    data: { href },
  })

  editor.moveToEnd()
}

function unwrapLink(editor) {
  editor.unwrapInline('link')
}

/**
 * The rich text example.
 *
 * @type {Component}
 */

class TextEditor extends React.Component {
  
  private editor

  // state:any = {
  //   value: Value.fromJSON(initialValue),
  // }

  constructor(props) {
    super(props)
    // this.state.value = Value.fromJSON({
    //   object: 'value',
    //   document: {
    //     object: 'document',
    //     nodes: props.nodes || [],
    //   }
    // })
  }

  /**
   * Check if the current selection has a mark with `type` in it.
   *
   * @param {String} type
   * @return {Boolean}
   */

  hasMark = type => {
    const value = this.props['value']
    return value.activeMarks.some(mark => mark.type === type)
  }

  hasLinks = () => {
    const value = this.props['value']
    return value.inlines.some(inline => inline.type === 'link')
  }

  /**
   * Check if the any of the currently selected blocks are of `type`.
   *
   * @param {String} type
   * @return {Boolean}
   */

  hasBlock = type => {
    const value = this.props['value']
    return value.blocks.some(node => node.type === type)
  }

  hasAlign = type => {
    const value = this.props['value']
    return value.blocks.some(node => {
      return (node.align || 'align-left') === type
    })
  }

  /**
   * Store a reference to the `editor`.
   *
   * @param {Editor} editor
   */

  ref = editor => {
    this.editor = editor
  }

  onClickImage = async (event) => {
      try {
        let imgFile = await openFile(['jpg', 'png'])
  
        if(imgFile) {
          let url = await uploadFile(imgFile)
          this.editor.command(insertImage, {
            src: url,
            // caption: 'jfajfk',
          })
        }
      } catch(e) {
        console.error(e)
      }
    
  }

  onClickLink = event => {
    event.preventDefault()

    const { editor } = this
    const { value } = editor
    const hasLinks = this.hasLinks()

    if (hasLinks) {
      editor.command(unwrapLink)
    } else if (value.selection.isExpanded) {
      const href = window.prompt('Enter the URL of the link:')

      if (href == null) {
        return
      }

      editor.command(wrapLink, href)
    } else {
      const href = window.prompt('Enter the URL of the link:')

      if (href == null) {
        return
      }

      const text = window.prompt('Enter the text for the link:')

      if (text == null) {
        return
      }

      editor
        .insertText(text)
        .moveFocusBackward(text.length)
        .command(wrapLink, href)
    }
  }
  

  /**
   * Render.
   *
   * @return {Element}
   */

  render() {
    return (
      <div>
        //@ts-ignore
        <Toolbar className="editor-toolbar">
          {this.renderMarkButton('bold', 'bold')}
          {this.renderMarkButton('italic', 'italic')}
          {/* {this.renderMarkButton('underlined', 'format_underlined')} */}
          {/* {this.renderMarkButton('code', 'code')} */}

          <span className="m-2">{`${SPACE_CHAR}`}</span>

          {this.renderBlockButton('heading-one', 'h1')}
          {this.renderBlockButton('heading-two', 'h2')}
          {this.renderBlockButton('block-quote', 'quote-right')}
          {this.renderBlockButton('numbered-list', 'list-ol')}
          {this.renderBlockButton('bulleted-list', 'list-ul')}

          {/* <span className="m-2">{`${SPACE_CHAR}`}</span>

          {this.renderAlignButton('align-left', 'align-left')}
          {this.renderAlignButton('align-center', 'align-center')}
          {this.renderAlignButton('align-right', 'align-right')} */}
          

          <span className="m-2">{`${SPACE_CHAR}`}</span>

          <Button onMouseDown={this.onClickImage}>
            <i className="fas fa-fw fa-image" />
          </Button>
          <Button active={this.hasLinks()} onMouseDown={this.onClickLink}>
            <i className="fas fa-fw fa-link" />
          </Button>
        </Toolbar>
        <Editor
          className="editor"
          spellCheck
          autoFocus
          placeholder={this.props['placeholder'] || '编辑内容'}
          ref={this.ref}
          value={this.props['value']}
          onChange={this.props['onChange']}
          onKeyDown={this.onKeyDown}
          renderDocument={this.renderDocument}
          renderBlock={this.renderBlock}
          renderMark={this.renderMark}
          renderInline={this.renderInline}
        />
      </div>
    )
  }

  /**
   * Render a mark-toggling toolbar button.
   *
   * @param {String} type
   * @param {String} icon
   * @return {Element}
   */

  renderMarkButton = (type, icon) => {
    const isActive = this.hasMark(type)

    return (
      <Button
        active={isActive}
        onMouseDown={event => this.onClickMark(event, type)}
      >
        <i className={`fas fa-fw fa-${icon}`} />
      </Button>
    )
  }

  /**
   * Render a block-toggling toolbar button.
   *
   * @param {String} type
   * @param {String} icon
   * @return {Element}
   */

  renderBlockButton = (type, icon) => {
    let isActive = this.hasBlock(type)

    if (['numbered-list', 'bulleted-list'].includes(type)) {
      const { document, blocks } = this.props?.['value'] || {}

      if (blocks.size > 0) {
        const parent = document.getParent(blocks.first().key)
        isActive = this.hasBlock('list-item') && parent && parent.type === type
      }
    }

    return (
      <Button
        active={isActive}
        onMouseDown={event => this.onClickBlock(event, type)}
      >
        <i className={`fas fa-fw fa-${icon}`} />
      </Button>
    )
  }

  renderAlignButton = (type, icon) => {
    let isActive = this.hasAlign(type)

    // if (['align-left', 'align-center', 'align-right'].includes(type)) {
    //   const { value: { document, blocks } } = this.props

    //   if (blocks.size > 0) {
    //     const parent = document.getParent(blocks.first().key)
    //     isActive = this.hasBlock('list-item') && parent && parent.type === type
    //   }
    // }

    return (
      <Button
        active={isActive}
        onMouseDown={event => this.onClickAlign(event, type)}
      >
        <i className={`fas fa-fw fa-${icon}`} />
      </Button>
    )
  }

  renderDocument = (props, editor, next) => {
    return next()
  }

  /**
   * Render a Slate block.
   *
   * @param {Object} props
   * @return {Element}
   */

  renderBlock = (props, editor:Editor, next) => {
    const { attributes, children } = props
    const node: BlockNode = props.node

    switch (node.type) {
      case 'paragraph':
        return <p {...attributes}>{children}</p>
      case 'block-quote':
        return <blockquote {...attributes}>{children}</blockquote>
      case 'heading-one':
        return <h1 {...attributes}>{children}</h1>
      case 'heading-two':
        return <h2 {...attributes}>{children}</h2>
      case 'image':
        let src = node.data.get('src')
        let size = node.data.get('size') || 'lg'
        if(src) {
          isUrl(src) || (src = `${process.env.REACT_APP_CDN_URL}/img/${src}`);
          return (
            <figure className="position-relative">
              <img src={src} className={`slate-img-${size}`} />
              { node.data.get('caption') && <figcaption>{node.data.get('caption')}</figcaption>}
              <div className="img-tool position-absolute w-100 d-flex align-items-center justify-content-end">
                <BsButton color="link" className="mx-1" onClick={() => {
                  editor.setNodeByKey(node.key, {
                    type: node.type,
                    data: node.data.set('size', 'lg'),
                  })
                }}>
                  <Icon name="fas:expand" />
                </BsButton>
                <BsButton color="link" className="mx-1" onClick={() => {
                  editor.setNodeByKey(node.key, {
                    type: node.type,
                    data: node.data.set('size', 'sm'),
                  })
                }}>
                  <Icon name="fas:compress" />
                </BsButton>
                <BsButton color="link" className="mx-1" onClick={() => {
                  let ipt = window.prompt('请填写图片标题', node.data.get('caption') || '')

                  if(ipt != null) {
                    editor.setNodeByKey(node.key, {
                      type: node.type,
                      data: ipt ? node.data.set('caption', ipt) : node.data.remove('caption'),
                    })
                  }
                }}>
                  <Icon name="fas:tag" />
                </BsButton>
                <BsButton color="link" className="mx-1" onClick={() => {
                  editor.removeNodeByKey(node.key)
                }}>
                  <Icon name="fas:trash" />
                </BsButton>
                  <style jsx>{`
                  div {
                    background-color: rgba(0,0,0,0.75);
                    height: 2rem;
                    top: 0;
                    color: white;
                  }
                `}</style>
              </div>
            </figure>
          )
        }
        return next()
      case 'bulleted-list':
        return <ul {...attributes}>{children}</ul>
      case 'numbered-list':
        return <ol {...attributes}>{children}</ol>
      case 'list-item':
        return <li {...attributes}>{children}</li>
      default:
        return next()
    }
  }

  /**
   * Render a Slate mark.
   *
   * @param {Object} props
   * @return {Element}
   */

  renderMark = (props, editor, next) => {
    const { children, mark, attributes } = props

    switch (mark.type) {
      case 'bold':
        return <strong {...attributes}>{children}</strong>
      // case 'code':
      //   return <code {...attributes}>{children}</code>
      case 'italic':
        return <em {...attributes}>{children}</em>
      case 'underlined':
        return <u {...attributes}>{children}</u>
      default:
        return next()
    }
  }

  renderInline = (props, editor, next) => {
    const { attributes, children, node } = props

    switch (node.type) {
      case 'link': {
        const { data } = node
        const href = data.get('href')
        return (
          <a {...attributes} href={href} target="_blank">
            {children}
          </a>
        )
      }

      default: {
        return next()
      }
    }
  }

  /**
   * On change, save the new `value`.
   *
   * @param {Editor} editor
   */

  // onChange = ({ value }) => {
  //   this.setState({ value })
  // }

  /**
   * On key down, if it's a formatting command toggle a mark.
   *
   * @param {Event} event
   * @param {Editor} editor
   * @return {Change}
   */

  onKeyDown = (event, editor, next) => {
    let mark

    if (isBoldHotkey(event)) {
      mark = 'bold'
    } else if (isItalicHotkey(event)) {
      mark = 'italic'
    } else if (isUnderlinedHotkey(event)) {
      mark = 'underlined'
    } else if (isCodeHotkey(event)) {
      mark = 'code'
    } else {
      return next()
    }

    event.preventDefault()
    editor.toggleMark(mark)
  }

  /**
   * When a mark button is clicked, toggle the current mark.
   *
   * @param {Event} event
   * @param {String} type
   */

  onClickMark = (event, type) => {
    event.preventDefault()
    this.editor.toggleMark(type)
  }

  /**
   * When a block button is clicked, toggle the block type.
   *
   * @param {Event} event
   * @param {String} type
   */

  onClickBlock = (event, type) => {
    event.preventDefault()

    const { editor } = this
    const { value } = editor
    const { document } = value

    // Handle everything but list buttons.
    if (type !== 'bulleted-list' && type !== 'numbered-list') {
      const isActive = this.hasBlock(type)
      const isList = this.hasBlock('list-item')

      if (isList) {
        editor
          .setBlocks(isActive ? DEFAULT_NODE : type)
          .unwrapBlock('bulleted-list')
          .unwrapBlock('numbered-list')
      } else {
        editor.setBlocks(isActive ? DEFAULT_NODE : type)
      }
    } else {
      // Handle the extra wrapping required for list buttons.
      const isList = this.hasBlock('list-item')
      const isType = value.blocks.some(block => {
        return !!document.getClosest(block.key, parent => parent.type === type)
      })

      if (isList && isType) {
        editor
          .setBlocks(DEFAULT_NODE)
          .unwrapBlock('bulleted-list')
          .unwrapBlock('numbered-list')
      } else if (isList) {
        editor
          .unwrapBlock(
            type === 'bulleted-list' ? 'numbered-list' : 'bulleted-list'
          )
          .wrapBlock(type)
      } else {
        editor.setBlocks('list-item').wrapBlock(type)
      }
    }
  }

  onClickAlign = (event, type) => {
    event.preventDefault()

    const { editor } = this
    const { value } = editor
    const { document, blocks } = value

    console.log(editor)

    // Handle everything but list buttons.
    if (!['align-left', 'align-center', 'align-right'].includes(type)) {
      
    } else {


      console.log(document)
      console.log(blocks)

      // Handle the extra wrapping required for list buttons.
      // const isList = this.hasBlock('list-item')
      // const isType = value.blocks.some(block => {
      //   return !!document.getClosest(block.key, parent => parent.type === type)
      // })

      // if (isList && isType) {
      //   editor
      //     .setBlocks(DEFAULT_NODE)
      //     .unwrapBlock('bulleted-list')
      //     .unwrapBlock('numbered-list')
      // } else if (isList) {
      //   editor
      //     .unwrapBlock(
      //       type === 'bulleted-list' ? 'numbered-list' : 'bulleted-list'
      //     )
      //     .wrapBlock(type)
      // } else {
      //   editor.setBlocks('list-item').wrapBlock(type)
      // }
    }
  }
}



/**
 * Export.
 */

export default TextEditor