import React from 'react'

import { lowerCase, snakeCase } from 'lodash'
import { useCallback, useContext, useState } from "react"
import { Button as Btn, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"
import { AppContext } from "../App"

import config from '../config' 

const LanguageSelector = ({className = '', style = {}, languages = config.languages, value = undefined as (string | undefined), onChange = (value:string) => {} }) => {

  const app = useContext(AppContext)

  value = value || languages[0]

  const [open, setOpen] = useState<boolean>(false)
  const toggleOpen = useCallback(() => {
    setOpen(!open)
  }, [open])

  if(!languages.length) {
    return null
  }

  return (
    <Dropdown isOpen={open} toggle={toggleOpen} className={className} style={style}>
      <DropdownToggle tag={Btn} className="rounded-circle btn-light" style={{
        width:'2rem', 
        height:'2rem',
        backgroundImage: `url('${process.env.PUBLIC_URL}/img/flag_${value}.svg')`,
        backgroundSize: 'cover',
      }}>
        {/* <img src={`${process.env.PUBLIC_URL}/img/flag_${locale}.svg`} className="" style={{
          width:'2rem', 
          height:'2rem'
        }} /> */}
      </DropdownToggle>
      <DropdownMenu className="" style={{
        borderWidth: 0,
        minWidth: 0,
      }}>
      {languages.map(lc => (lc == value) ? null : (
        <DropdownItem key={lc} tag={Btn} className="rounded-circle btn-light p-0 my-1" style={{
          width:'2rem', 
          height:'2rem',
          backgroundImage: `url('${process.env.PUBLIC_URL}/img/flag_${lc}.svg')`,
          backgroundSize: 'cover',
        }} onClick={() => {
          lc && onChange(lc)
        }}>

        </DropdownItem>
      ))}
      </DropdownMenu>
    </Dropdown>
  )
  
}

export default LanguageSelector