import { createContext, useContext } from 'react'
import { ObjectId } from 'bson'

import { 
  types, 
  TypeOfValue,
  Instance,
  SnapshotIn,
  SnapshotOut, 
  SnapshotOrInstance,
  flow,
} from 'mobx-state-tree'

import {
  get,
  set,
  unset,
  omit,
} from 'lodash'

import api from '../api'

export const Volunteer = types.model({
  _id: types.optional(types.identifier, () => new ObjectId().toHexString()),
  name: types.optional(types.string, '22f'),
  type: types.optional(types.string, '志愿者'),
  service: types.optional(types.string, ''),
  duration: types.optional(types.number, 1),
}).views(self => ({
  // isEditable: () => self.verification
}))

type DataTypeName = 'volunteer'

const Data = types
  .model('Data', {
    volunteer: types.map(Volunteer),
  })
  .actions(self => {

    const pendings: {[type: string]: {[id: string]: boolean}} = {}

    return ({

      load: flow(function*(name: DataTypeName, id: string) {
        if (!get(pendings, [name, id])) {
          set(pendings, [name, id], true)
          try {
            let value = yield api.service(name).get(id);
            console.log(value);
            unset(pendings, [name, id])
            self[name].put(value);
          } catch (err) {
            console.error(err);
            unset(pendings, [name, id])
          }
        }
      }),

      save: flow(function*(name: DataTypeName, value: VolunteerType) {
        if(value._id) {
          let res = yield api.service(name).update(value._id, omit(value, '_id'))
          console.log(res)
        } else {
          let res = yield api.service(name).create(omit(value, '_id'))
          console.log(res)
        }
        
      }),
    })
  })
  .views(self => ({
    get(name: DataTypeName, id: string) {
      let row = self[name].get(id);
      if (!row) {
        self.load(name, id);
      }
      return row;
    }
  }));

const Auth = types.model('Auth', {
  userId: types.maybeNull(types.string),
}).actions(self => ({
  login(userId: string) {
    self.userId = userId
  },
  logout() {
    self.userId = null
  }
}))

const Store = types.model({
  auth: Auth,
  data: Data,
})

const store = Store.create({
  auth: {
    userId: null,
  },
  data: {
    volunteer: {}
  }
});

export type StoreType = Instance<typeof Store>
const StoreContext = createContext<null | StoreType>(null)

export const Provider = StoreContext.Provider;

export function useStore() {
  const store = useContext(StoreContext);
  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider");
  }
  return store;
}

export default store

export type VolunteerType = Instance<typeof Volunteer>

