import async from '../components/Async'

// import {
//   BookOpen as BookOpenIcon,
//   CheckSquare as CheckSquareIcon,
//   Grid as GridIcon,
//   Heart as HeartIcon,
//   Layout as LayoutIcon,
//   List as ListIcon,
//   MapPin as MapPinIcon,
//   Monitor as MonitorIcon,
//   PieChart as PieChartIcon,
//   Sliders as SlidersIcon,
//   Users as UsersIcon,
//   User as LeaderIcon,
//   Calendar as CalendarIcon,
//   FileText as FileTextIcon,
//   Folder as FolderIcon,
//   Database as SiteIcon,
//   DollarSign,
//   HelpCircle as VolunteerIcon,
// } from 'react-feather'

// Home
import Home from '../pages/Home'

// Auth
import Login from '../pages/auth/Login'
import Register from '../pages/auth/Register'
import ResetPassword from '../pages/auth/ResetPassword'
import Page404 from '../pages/auth/Page404'
import Page500 from '../pages/auth/Page500'

// Site
import Leader from '../pages/Leader'
import Post from '../pages/Post'
import Category from '../pages/Category'
import Site from '../pages/Site'

import Donation from '../pages/Donation'

import * as yup from 'yup'

// Setting
import User from '../pages/User'

import VolunteerSchema from '../schema/VolunteerSchema'
import DataPage from '../pages/DataPage'

// Donation

const Schemas = [VolunteerSchema]

// Routes
const homeRoutes = {
  path: '/',
  name: '首页',
  component: Home,
  children: null,
}

const userRoutes = {
  model: 'user',
  path: '/user',
  name: '人员',
  header: '设置',
  icon: 'fi:users',
  containsHome: true,
  component: User,
  children: null,
}

const siteRoutes = {
  model: 'site',
  path: '/site/:id?',
  name: '站点',
  pathname: '/site',
  icon: 'fi:database',
  containsHome: true,
  component: Site,
  children: null,
}

const catetoryRoutes = {
  model: 'category',
  path: '/category',
  name: '板块',
  header: '网站',
  icon: 'fi:folder',
  containsHome: true,
  component: Category,
  children: null,
}

const leaderRoutes = {
  model: 'leader',
  path: '/leader/:id?',
  pathname: '/leader',
  name: '领导',
  icon: 'fi:user',
  containsHome: true,
  component: Leader,
  children: null,
}

const postRoutes = {
  model: 'post',
  path: '/post/:id?',
  pathname: '/post',
  name: '文章',
  icon: 'fi:file-text',
  containsHome: true,
  component: Post,
  children: null,
}

const createRoutersFromSchema = (schema: yup.SchemaOf<any>) => {
  return {
    model: 'volunteer',
    path: '/volunteer/:id?',
    pathname: '/volunteer',
    name: '志愿者',
    icon: 'fi:help-circle',
    containsHome: true,
    component: DataPage,
    children: null,
  }
}

const donationRoutes = {
  model: 'donation',
  path: '/donation/:id?',
  pathname: '/donation',
  name: '捐赠',
  icon: 'fi:dollar-sign',
  containsHome: true,
  component: Donation,
  children: null,
}

const authRoutes = {
  path: '/auth',
  name: 'Auth',
  icon: 'fi:users',
  badgeColor: 'secondary',
  badgeText: '12/24',
  children: [
    {
      path: '/auth/login',
      name: 'Sign In',
      component: Login,
    },
    {
      path: '/auth/register',
      name: 'Sign Up',
      component: Register,
    },
    {
      path: '/auth/reset-password',
      name: 'Reset Password',
      component: ResetPassword,
    },
    {
      path: '/auth/404',
      name: '404 Page',
      component: Page404,
    },
    {
      path: '/auth/500',
      name: '500 Page',
      component: Page500,
    },
  ],
}

// This route is not visisble in the sidebar
const privateRoutes = {
  path: '/private',
  name: 'Private',
  children: [
    {
      path: '/private/blank',
      name: 'Blank Page',
      component: Home,
    },
  ],
}

// Dashboard specific routes
export const dashboard = [
  catetoryRoutes,
  leaderRoutes,
  postRoutes,
  donationRoutes,

  userRoutes,
  siteRoutes,

  //@ts-ignore
  ...Schemas.map((schema) => createRoutersFromSchema(schema)),

  // dashboardRoutes,
  // pageRoutes,
  // documentationRoutes,
  // uiRoutes,
  // formRoutes,
  // tableRoutes,
  // iconRoutes,
  privateRoutes,
]

// Landing specific routes
export const home = [homeRoutes]

// Auth specific routes
export const auth = [authRoutes]

// All routes
export default [
  userRoutes,
  siteRoutes,

  catetoryRoutes,
  leaderRoutes,
  postRoutes,
  donationRoutes,

  //@ts-ignore
  ...Schemas.map((schema) => createRoutersFromSchema(schema)),

  // dashboardRoutes,
  // pageRoutes,
  // authRoutes,
  // documentationRoutes,
  // uiRoutes,
  // formRoutes,
  // tableRoutes,
  // iconRoutes,
]
