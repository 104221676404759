import React from 'react'
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";

const ResetPassword = () => (
  <React.Fragment>
    <div className="text-center mt-4">
      <h1 className="h2">找回密码</h1>
      {/* <p className="lead">Enter your email to reset your password.</p> */}
    </div>

    <Card>
      <CardBody>
        <div className="m-sm-4">
          <Form>
            <FormGroup>
              <Label>邮箱</Label>
              <Input
                bsSize="lg"
                type="email"
                name="email"
                placeholder="注册邮箱"
              />
              <div className="mt-2">
                <Link to="/auth/login">返回登录</Link>
              </div>
            </FormGroup>
            <div className="text-center mt-3">
              <Link to="/dashboard/default">
                <Button color="primary" size="lg">
                  发送邮件验证
                </Button>
              </Link>
            </div>
          </Form>
        </div>
      </CardBody>
    </Card>
  </React.Fragment>
);

export default ResetPassword;
