import {
  get,
  set,
  union,
  pullAll,
  isArray,
  trim,
} from 'lodash'


export * from './ui'

export const add = (object, path, value):void => {
  isArray(value) || (value = [value]);
  let arr = get(object, path)

  arr = union(isArray(arr) ? arr : [], value)
  set(object, path, arr)
}

export const remove = (object, path, value):void => {
  isArray(value) || (value = [value]);
  let arr = get(object, path)

  isArray(arr) && pullAll(arr, value);
}

export const FILE_ICON = {
  jpg: 'file-image',
  png: 'file-image',
  pdf: 'file-pdf',
  docx: 'file-word',
  xlsx: 'file-excel',
  pptx: 'file-powerpoint',
  zip: 'file-archive',
}


export const cleanNodes = blocks => {
  blocks.forEach((b, bIdx, blocks) => {
    if(b.type === 'paragraph') {
      (b.nodes || []).forEach((n, nIdx, nodes) => {
        if (n.object === 'text' && trim(n.text).length === 0) {
          nodes.splice(nIdx, 1)
        }
      })
      
      if(b.nodes.length === 0) {
        blocks.splice(bIdx, 1)
      }
    }
  })

  return blocks
}

export const SIDER_IS_OPEN = 'siderIsOpen'


