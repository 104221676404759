import React, { useState, useContext, useCallback, useEffect, useMemo, ComponentType } from 'react';
import { Badge, Button, Card, CardBody, CardFooter, CardHeader, Container, Input, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink } from "reactstrap";
import {
  TreeDataState,
  CustomTreeData,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';

import {
  isArray,
} from 'lodash'

import clsx from 'clsx'

const LanguageFormatter: ComponentType<DataTypeProvider.ValueFormatterProps> = ({value, row, column}) => {

  return (
    <div className="d-flex flex-row align-items-center justify-content-start">
    { isArray(row.languages) && row.languages.map(l => {

      return (
        <div key={l} className="p-0 m-0">
          <img src={`${process.env.PUBLIC_URL}/img/flag_${l}.svg`} className={clsx('mr-3')} />
          <style jsx>{`
          img {
            width: 1.5rem;
            height: 1.5rem;
          }
          `}</style>
        </div>
    )})}
    </div>
  ) 
}

export default LanguageFormatter;