import {
  CustomPaging,
  DataTypeProvider,
  DataTypeProviderProps,
  IntegratedSelection,
  PagingState,
  SelectionState,
  Sorting,
  SortingState,
  FilteringState,
} from '@devexpress/dx-react-grid'
// import { Grid, PagingPanel, Table, TableFixedColumns, TableHeaderRow, TableSelection, Toolbar } from '@devexpress/dx-react-grid-bootstrap4';
import classnames from 'classnames'
import {
  compact,
  debounce,
  get,
  set,
  includes,
  isEmpty,
  isError,
  last,
  lowerCase,
  pull,
  range,
  snakeCase,
  uniq,
  isString,
  dropWhile,
  find,
  findIndex,
  extend,
  omit,
  isPlainObject,
} from 'lodash'
import moment, { Moment } from 'moment'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Link } from 'react-router-dom'
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  InputGroupAddon,
  InputGroupText,
  Spinner,
} from 'reactstrap'
import { useImmer } from 'use-immer'
import { ARTICLE_STATUS, ARTICLE_STATUS_LABELS, SPACE_CHAR } from '../api'
import { AppContext, useApp } from '../App'
import ActionButton from '../components/Button'
import LanguageSelector from '../components/LanguageSelector'
import Spacer from '../components/Spacer'
import LangNav from '../components/LangNav'
import LanguageFormatter from '../components/LanguageFormatter'

import * as yup from 'yup'

import { Table } from 'reactstrap'

import currency from 'currency.js'

import Datetime from 'react-datetime'

import { Icon } from '../utils'

import { useTranslation } from 'react-i18next'

import { api, useGlobal } from '../store'
import { State, Actions } from '../store/types'

import clsx from 'clsx'
import Content from '../components/Content'
import config from '../config'
import delay from 'delay'
import { useTable, useRowSelect, TableInstance, useSortBy, UseSortByOptions, UseSortByInstanceProps, UseSortByColumnProps, Column, UseSortByColumnOptions } from 'react-table'

import { openFile, readExcelOrCsv } from '../utils/file'

import { Volunteer } from '../store/data'
import VolunteerSchema from '../schema/VolunteerSchema'
import DataFormDialog from '../components/DataFormDialog'
import { reflectSchema } from '../utils/yup'
import IconButton from '../components/IconButton'

import { usePaginatedQuery } from 'react-query'
import InputCheckbox from '../h5/InputCheckbox'

declare module 'react-table' {
  interface TableState<D extends object = {}> extends UseRowSelectState<D> {
    formProps?: any
  }

  interface TableOptions<D extends object>
    extends UseRowSelectOptions<D>,
      UseRowStateOptions<D>, UseSortByOptions<D> {}

  // interface Column<D extends object>
  //     extends UseSortByColumnOptions<D> {}

  interface TableInstance<D extends object = {}>
    extends UseRowSelectInstanceProps<D>,
      UseRowStateInstanceProps<D>, UseSortByInstanceProps<D> {}

  interface UseTableRowProps<D extends object>
    extends UseRowSelectRowProps<D> {}

  interface UseTableColumnProps<D extends object>
    extends UseResizeColumnsColumnProps<D>, UseSortByColumnProps<D> {}
}

const DataTableCard: React.FC<{
  schema: yup.ObjectSchema<any>
}> = ({ schema }) => {

  const app = useApp()

  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(config.pageSize)

  const [lang, setLang] = useState<string>(config.languages[0] as string)

  const importExcel = useCallback(async () => {
    let file = await openFile(['xlsx', 'csv'])
    if (file) {
      let rows = (await readExcelOrCsv(file, schema)) as any[]
      await Promise.all(
        rows.map(async (r) => {
          let res = await api.service('volunteer').create(r)
          console.log(res)
        })
      )
    }
  }, [])

  const {
    status,
    resolvedData,
    latestData,
    error,
    isFetching,
    refetch,
  } = usePaginatedQuery(['volunteer', {}, {
    skip: pageIndex * pageSize,
    limit: pageSize,
  }], api.find)

  const [sort, setSort] = useState<{[key: string]: boolean}>({})

  const columns = useMemo<Column[]>(
    () =>
      Object.entries(reflectSchema(schema).fields).map(([key, sch]) => ({
        Header: reflectSchema(sch).label || key,
        accessor: key,
        defaultCanSort: true,
      })),
    [schema]
  )

  const data = useMemo(() => resolvedData?.data || [], [resolvedData])

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      manualSortBy: true,
    },
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: '_select',
          width: 38,
          maxWidth: 38,
          Header: (table: TableInstance) => {
            return (
              <div className="d-flex justify-content-start align-items-center w-100 h-100">
                <InputCheckbox
                  className="d-block "
                  {...table.getToggleAllRowsSelectedProps()}
                />
              </div>
            )
          },
          Cell: ({ row }) => {
            return (
              <div className="d-flex justify-content-start align-items-center w-100 h-100">
                <InputCheckbox
                  className="d-block "
                  {...row.getToggleRowSelectedProps()}
                />
              </div>
            )
          },
        },
        ...columns,
        {
          id: '_action',
          width: 16+30,
          maxWidth: 16+30,
          Header: ({data}) => {

            return (
              null
            )
          },
          Cell: ({row, data}) => {
            return (
              <div className="d-flex justify-content-end align-items-center">
                { 
                //@ts-ignore
                Object.entries(reflectSchema(schema).methods).map(([name, schema]) => (
                  <Button key={name} color="primary" size="sm" disabled={selectedFlatRows.length === 0} className="mx-1">
                    {
                    //@ts-ignore
                    reflectSchema(schema).icon && <Icon name={`fas.fa-${reflectSchema(schema).icon}`} />}
                    {
                    //@ts-ignore
                    reflectSchema(schema).label || name}
                  </Button>
                ))}
              </div>
            )
          },
        },
      ])
    }
  )

  return (
    <Card>
      <CardHeader className="d-flex flex-row align-items-center">

        {/* <Link to={`/volunteer/new`} className="btn btn-link mx-1">
          <Icon name="fas:plus" /> 新建...
        </Link> */}

        <Button color="link" className="mx-1" onClick={importExcel}>
          <Icon name="fas:file-import" /> 导入...
        </Button>

        { 
                //@ts-ignore
                 Object.entries(reflectSchema(schema).methods).map(([name, schema]) => (
          <Button key={name} color="primary" disabled={selectedFlatRows.length === 0} className="mx-1">
            { 
                //@ts-ignore
                reflectSchema(schema).icon && <Icon name={`fas.fa-${reflectSchema(schema).icon}`} />}
            { 
                //@ts-ignore
                reflectSchema(schema).label || name}
          </Button>
        ))}

        <Spacer />

        <InputGroup style={{width: '20rem'}}>
          <Input placeholder="查找..." />
          <InputGroupAddon addonType="append">
            <InputGroupText>
              <IconButton onClick={refetch} isPending={isFetching} />
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>

        {/* <ActionButton color="link" className="mx-1" onClick={saveData}>
      <Icon name="fas:save" />{' '}保存
      </ActionButton> */}
      </CardHeader>

      <CardBody>
      <Table responsive className="position-relative">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, i, arr) => {
              // console.log(column.getSortByToggleProps())
              return (
                <th className={ clsx('text-nowrap', 'sticky-top', 'bg-white', {
                  'sticky-left': i === 0,
                  'sticky-right': i === arr.length - 1,
                  // 'position-absolute': i === 0
                })} {...column.getHeaderProps()}>
                  <a onClick={() => column.toggleSortBy(!column.isSortedDesc, false) } className="text-primary btn btn-link border-0 p-0 d-flex align-items-center">
                    {column.render('Header')}
                    { column.isSorted && <i className={clsx('fas', 'fa-fw', {
                      'fa-sort-up': !column.isSortedDesc,
                      'fa-sort-down': column.isSortedDesc,
                    })} /> }
                  </a>
                </th>
              )
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell, i, arr) => {
                return <td className={ clsx('text-nowrap', 'bg-white', {
                  'sticky-left': i === 0,
                  'sticky-right': i === arr.length - 1,
                  // 'position-absolute': i === 0
                })} {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </Table>
      </CardBody>

      <CardFooter className="d-flex flex-row justify-content-start align-items-center"></CardFooter>
    </Card>
  )
}

const DataPage = ({ match, location: { pathname }, history }) => {
  let { id } = match.params

  if (isString(id) && id.length !== 24) {
    id = null
  }

  const [rows, updateRows] = useImmer([])

  const closeDialog = useCallback(() => {
    history.push(pathname.split('/').slice(0, -1).join('/'))
  }, [history, pathname])

  return (
    <>
      <Container fluid className="p-0">
        <DataTableCard schema={VolunteerSchema as any} />
      </Container>

      <DataFormDialog
        schema={VolunteerSchema as any}
        isOpen={id !== undefined}
        id={id}
        close={closeDialog}
      />
    </>
  )
}

export default DataPage
