import React, { useState, useContext, useCallback, useEffect, useMemo, ComponentType } from 'react';
import { Badge, Button, Card, CardBody, CardFooter, CardHeader, Container, Row, Col, Input, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink } from "reactstrap";
import {
  DataTypeProvider,
  PagingState,
  CustomPaging,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
} from '@devexpress/dx-react-grid-bootstrap4';

import clsx from 'clsx'

import {
  generateRows,
  defaultColumnValues,
} from '../demo/generator';
import { AppContext } from '../App';

import ActionButton from '../components/Button';
import LanguageSelector from '../components/LanguageSelector';
import Spacer from '../components/Spacer';
import { Icon } from '../utils'

import { compact, debounce, get, includes, isEmpty, flatten, isError, last, lowerCase, pull, range, snakeCase, uniq, isString, dropWhile, find, findIndex, extend, omit, assign } from 'lodash';
import { useImmer } from 'use-immer';
import delay from 'delay';


import { api, useGlobal } from '../store';
import { State, Actions } from '../store/types'

import { openFile, uploadFile } from '../utils/file'

import LangNav from '../components/LangNav'

import config from '../config'

import SiteEditor from './SiteEditor'
import { Link, useParams } from 'react-router-dom';
import TextEditorFormatter from '../components/TextEditorFormatter';

const SiteTable = ({pageSizes = [20, 50, 100]}) => {

  const app = useContext(AppContext)

  const [lang, setLang] = useState<string>(config.languages[0] as string)


  const [rows, updateRows] = useImmer([])

  const ImageEditorFormatter = ({value, row, column}) => {
  
    const openEditor = useCallback(async () => {
      let imgFile = await openFile(['jpg', 'png'])
  
      if(imgFile) {
        let url = await uploadFile(imgFile)
        await api.site.patch(row._id, {
          [column.name]: (url || null)
        })
        updateRows(draft => {
          //@ts-ignore
          find(draft, ['_id', row._id])[column.name] = (url || null)
        })
      }
    }, [])
  
    return (
      <div className="d-flex flex-row align-items-center justify-content-start">
        <img src={value ? `${process.env.REACT_APP_CDN_URL}/img/${value}` : `${process.env.PUBLIC_URL}/img/icon.png`} />
        <style jsx>{`
          img {
            width: 2rem;
            height: 2rem;
          }
        `}</style>
        <Button color="link" className="" onClick={openEditor} >
          <Icon name="fas:edit" />
        </Button>
      </div>
    ) 
  }

  const IconEditorFormatter: ComponentType<DataTypeProvider.ValueFormatterProps> = ({value, row, column}) => {

    const openEditor = useCallback(async () => {
      let ipt = window.prompt(column.title, value || '')
  
      if(ipt != null) {
        await api.category.patch(row._id, {
          [column.name]: (ipt || null)
        })
        updateRows(draft => {
          //@ts-ignore
          find(draft, ['_id', row._id])[column.name] = (ipt || null)
        })
      }
    }, [value])
  
    return (
      <div className="d-flex flex-row align-items-center justify-content-start">
        <Icon name={value || 'fas.fa-bars'} />
        <style jsx>{`
          div {
            font-size: 1.5rem;
          }
        `}</style>
        <Button color="link" className="" onClick={openEditor} >
          <Icon name="fas:edit" />
        </Button>
      </div>
    ) 
  }
  
  const MenuEditorFormatter = ({value, row, column}) => {
  
    const columns = useMemo(() => [
      { name: 'icon', title: '图标', align:'left', width: 150, formatter: IconEditorFormatter },
      { name: `title$${lang}`, title: '标题', wrap: true, width: 200, formatter: ({row, column}) => {

        return (<>
          <div className="d-flex align-items-center">
            <Link to={`/site/${row._id}`}>{get(row, column.name, '')}</Link>
          </div>
        </>)
      } },
      // { name: `splash`, title: '头图', },
    ], [])
    
  
    const tableColumnExtensions = useMemo(() => {
      return columns.map(({name, align = 'left', width, wrap = false}) => ({
        columnName: name,
        align: (align as 'left'|'center'|'right'),
        width: width || undefined,
        wordWrapEnabled: wrap,
      }))
    }, [columns])
  
    const [opened, setOpened] = useState<boolean>(false)
    const open = useCallback(() => {
      setOpened(true)
    }, [setOpened])
    const close = useCallback(() => {
      setOpened(false)
    }, [setOpened])
  
    
    const openEditor = useCallback(async () => {
      let ipt = window.prompt(column.title, value || '')
      if(ipt != null) {
        await api.site.patch(row._id, {
          [column.name]: (ipt || null)
        })
        updateRows(draft => {
          //@ts-ignore
          find(draft, ['_id', row._id])[column.name] = (ipt || null)
        })
      }
    }, [value])
  
    return (
      <div className="d-flex flex-row align-items-center justify-content-start">
        <Button color="danger" onClick={open}>菜单</Button>
        <Modal isOpen={opened} toggle={close}>
          <ModalHeader toggle={close}>菜单</ModalHeader>
          <ModalBody>
            <Grid 
              rows={value}
              columns={columns}
            >
              { columns.filter(c => c.formatter).map(({name, formatter}) => (
                //@ts-ignore
                <DataTypeProvider key={name} for={[name]} formatterComponent={formatter} />
              ))}
  
              <Table columnExtensions={tableColumnExtensions} />
              <TableHeaderRow />
            </Grid>     
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={close}>关闭</Button>
          </ModalFooter>
        </Modal>
      </div>
    ) 
  }

  const columns = useMemo(() => [
    { name: '_id', title: 'ID', align:'left', width: 80},
    { name: 'icon', align: 'left', title: '图标', width: '10rem', formatter: ImageEditorFormatter },
    { name: `content$${lang}.title`, title: `标题`, wrap: false, width: '20rem', formatter: ({row, column}) => {
      return (<>
        <div className="d-flex align-items-center">
          <Link to={`/site/${row._id}`}>{get(row, column.name, '')}</Link>
        </div>
      </>)
    } },
    { name: `content$${lang}.slogan`, title: '标语', wrap: false, width: '40rem', formatter: TextEditorFormatter },
    // { name: `splash`, title: '头图', },
    // { name: `menu`, title: '菜单', width: 200, formatter: MenuEditorFormatter},
  ], [lang]);

  const projection: string[] = useMemo(() => ([
    ...flatten(columns.map(c => c['fields'] || c.name)),
  ]), [columns])

  const [count, setCount] = useState(0)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(pageSizes[0])

  const filter: any = useMemo(() => ({
    languages: lang,
  }), [lang])

  const tableColumnExtensions = useMemo(() => {
    return columns.map(({name, align = 'left', width, wrap = false}) => ({
      columnName: name,
      align: (align as 'left'|'center'|'right'),
      width: width || undefined,
      wordWrapEnabled: wrap,
    }))
  }, [columns])

  const loadData = useCallback(async () => {

    try {
      await app.loading(async () => {
        let { total: count, data: rows } = await api.site.find({
          query: {
            $select: [
              ...columns.map(c => c.name), 
              '_id',
            ],
            $limit: 100,
            $sort: {
              order: 1,
            },
            ...filter,
          },
          headers: {
            'Content-Language': lang,
          }
        })

        rows.forEach(row => {
          row.$update = async function(change: object | null = null) {
            if(change) {
              await api.site.patch(this._id, change)
            }
            let row = await api.site.get(this._id, {
              query: {
                $select: projection,
              },
            })
            updateRows(draft => {
              assign(find(draft, ['_id', row._id]), row)
            })
          }
        })

        setCount(count)
        updateRows(() => rows)
      })

    } catch (e) {
      app.alert(isError(e) ? e.message : String(e), {type: "error"})
    }

  }, [lang, updateRows])

  useEffect(() => {
    loadData()
  }, [loadData])

  return (
    <Card>
      <CardHeader className="d-flex flex-row align-items-center">
      {/* <h2 className="lead m-0 mr-3">站点列表</h2> */}
      <LangNav lang={lang} setLang={setLang} languages={config.languages as any} />
      <Nav className="align-items-center">
      
      </Nav>
      
      <Spacer />

      <ActionButton color="link" className="mx-1" onClick={loadData}>
        <Icon name="fas:sync-alt" />{' '}刷新
      </ActionButton>

      </CardHeader>
      <CardBody>
      <Grid
        getRowId={r => r._id}
        rows={rows}
        columns={columns}
      >
        { columns.filter(c => c.formatter).map(({name, formatter}) => (
          //@ts-ignore
          <DataTypeProvider key={name} for={[name]} formatterComponent={formatter} />
        ))}

          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
          <CustomPaging totalCount={count} />

        <Table
          columnExtensions={tableColumnExtensions}
        />

        <PagingPanel pageSizes={pageSizes} />

        <TableHeaderRow />
      </Grid>
    </CardBody>
    </Card>
  );
};

export default () => {

  //@ts-ignore
  let { id } = useParams();

  if(id === 'new') {
    id = null
  }

  return (
    <>
      <Container fluid className="p-0">
        <Row>
        <SiteTable />
        </Row>
      </Container>

      <Modal scrollable backdrop="static" isOpen={id !== undefined} size="lg" className="">
        <SiteEditor id={id} />
      </Modal>
    </>
  )
}