import { 
  isString,
  compact,
  omitBy,
  trim,
} from 'lodash'
import mime from 'mime'
import BrowserMD5File from 'browser-md5-file'

import { api } from '../store';
import COS from 'cos-js-sdk-v5'
import axios from 'axios'
import * as yup from 'yup';

import XLSX from "xlsx";
import Papa from "papaparse";


const browserMD5File = new BrowserMD5File()

export const blob2Buf = blob => (new Promise((resolve, reject) => {
  let fileReader = new FileReader()
  //@ts-ignore
  fileReader.onload = e => resolve(e.target.result)
  fileReader.readAsArrayBuffer(blob)
}))

const getAuthorization = async (opt) => {
  let { key, name, credentials } = await api.file.create({
    dir: opt.dir,
    file: {
      name: opt.file.name,
      type: opt.file.type,
      size: opt.file.size,
    }
  })

  return {
    key,
    name,
    token: credentials.sessionToken,
    auth: COS.getAuthorization({
      SecretId: credentials.tmpSecretId,
      SecretKey: credentials.tmpSecretKey,
      Method: opt.Method,
      Pathname: opt.Pathname,
    })
  };
};

export const uploadFile = async (file: File, {
  dir = 'img',
  //@ts-ignore
  onProgress = null,
}: {
  dir?: string,
  onProgress?: (n: number) => void,
} = {}) => {

  let info = await getAuthorization({ dir, file, Method: 'POST', Pathname: '/' })

  console.log(info)

  var fd = new FormData();
  fd.append('key', info.key);
  fd.append('Signature', info.auth);
  fd.append('Content-Type', '');
  info.token && fd.append('x-cos-security-token', info.token);
  fd.append('file', file);

  var config = {
    onUploadProgress: function (progressEvent) {
      if(onProgress) {
        var progress = progressEvent.loaded / progressEvent.total
        onProgress(progress)
      }
    }
  };

  

  try {
    let res = await axios.post(process.env.REACT_APP_CDN_URL as string, fd, config)
    // console.log(res)
    if(~~(res.status / 100) === 2 ) {
      return info.name
    }
  } catch(e) {
    console.error(e)
  }
}

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

export const openFile = ( types: string[], { size = 10, multi = false }: {
  size?: number,
  multi?: boolean,
} = {} ): Promise<any> => {

  return new Promise( (resolve, reject) => {
    const el = document.createElement('input')
    el.setAttribute('type', 'file')
    types = types.map( mime.getType.bind(mime) ).filter( isString )
    if( types.length > 0 ) el.setAttribute('accept', types.join(', '))
    el.setAttribute('multiple', String(!!multi))
    el.style.display = 'none'
    //@ts-ignore
    el.addEventListener('change', ({target}: HTMLInputEvent) => { 

      if( target.files )  {

        if(Array.from(target.files).some(f => (f.size > size*1024*1024))) {
          window.alert(`文件大小不能超过${size}M`)
          resolve(null)
        } else {
          resolve( multi ? target.files : target.files[0] )
        }
      } else {
        resolve(null)
      }

      document.body.removeChild(target)
    })
    document.body.appendChild(el)
    el.click()
  })
  
}

export const saveFile = (name, data) => {

  if(typeof data === 'string') {
    data = new Blob([data])
  }

  const el = document.createElement('a')
  el.style.display = 'none'
  el.setAttribute('download', name)
  el.setAttribute('href', URL.createObjectURL(data)) 

  document.body.appendChild(el)
  el.click()
  setTimeout(() => {
    URL.revokeObjectURL(data)
  }, 100)

}

export const readImage = async file => new Promise((resolve, reject) => {
  var reader  = new FileReader()
  reader.addEventListener('load', () => {
    resolve(reader.result)
  }, false)
  file && reader.readAsDataURL(file)
})

const md5 = file => {
  return new Promise( (resolve, reject) => {
    browserMD5File.md5(file, (err, md5) => {
      if(err) {
        reject(err)
      }else {
        file.md5 = md5
        resolve(file)
      }
    }, pgr => {})
  })
}


type ExcelOption = {
  multiSheets?: boolean, // false
}

export const readExcelOrCsv = async (file: Blob, schema?: yup.ObjectSchema<any>, opt: ExcelOption = {}) => {

  const header = {}

  //@ts-ignore
  Object.entries(schema.fields).forEach(([k, v]) => {
    //@ts-ignore
    v._label && (header[v._label] = k)
  })

  // console.log(header)

  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    if (file.type == "text/csv") {
      reader.onload = e => {
        //@ts-ignore
        resolve(parseCSV(e.target.result as string, header, schema))
      }
      reader.readAsText(file);
    } else if (file.type.startsWith("application/")) {
      reader.onload = e => {
        //@ts-ignore
        resolve(parseExcel(e.target.result as ArrayBuffer, header, schema, opt))
      }
      reader.readAsBinaryString(file);
    } else {
      reject(new TypeError('file not excel or csv'))
    }
  })
  
};

export const parseExcel = (data: ArrayBuffer, header: {[label: string]: string}, schema?: yup.ObjectSchema<any>, opt: ExcelOption = {}) => {
  const wb = XLSX.read(data, { type: "binary" });
  const result = (opt.multiSheets === true) ? wb.SheetNames.map(name => {
    let csv = XLSX.utils.sheet_to_csv(wb.Sheets[name]);
    return parseCSV(csv, header, schema);
  }) : parseCSV(XLSX.utils.sheet_to_csv(wb.Sheets[wb.SheetNames[0]]), header, schema)

  return result
}

export const parseCSV = (data: string, header: {[label: string]: string}, schema?: yup.ObjectSchema<any>) => {

  let { data: rows } = Papa.parse(data, {
    header: true,
    transformHeader: (s: string) => (header[s] || s),
  })

  rows = compact(rows.map(r => {
    //@ts-ignore
    r = omitBy(r, s => !trim(s).length)
    try {
      return schema?.validateSync?.(r) ?? r
    } catch(e) {
      console.log(r)
      console.log(e)
      return undefined
    }
  }))

  return rows
};