import React, { useState, useContext, useCallback, useEffect, useMemo, ComponentType } from 'react';
import { Badge, Button, Card, CardBody, CardFooter, CardHeader, Container, Input, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink } from "reactstrap";
import {
  TreeDataState,
  CustomTreeData,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableTreeColumn,
} from '@devexpress/dx-react-grid-bootstrap4';

import clsx from 'clsx'

import {
  generateRows,
  defaultColumnValues,
} from '../demo/generator';
import { AppContext } from '../App';

import ActionButton from '../components/Button';
import LanguageSelector from '../components/LanguageSelector';
import Spacer from '../components/Spacer';
import { Icon } from '../utils'
import LangNav from '../components/LangNav'
import LanguageFormatter from '../components/LanguageFormatter'

import { compact, debounce, get, flatten, assign, startsWith, toUpper, includes, isEmpty, isError, last, lowerCase, pull, range, snakeCase, uniq, isString, dropWhile, find, findIndex, extend, omit } from 'lodash';
import { useImmer } from 'use-immer';
import delay from 'delay';

import config from '../config'
import { api, useGlobal } from '../store'
import { object } from 'yup';

import DataTable, { DataTableColumn } from '../components/DataTable'

const getChildRows = (row, rootRows) => {
  const childRows = rootRows.filter(r => (r.parent_id || null) === (row ? row._id : null));
  return childRows.length ? childRows : null;
};

const IconEditorFormatter: ComponentType<DataTypeProvider.ValueFormatterProps> = ({value, row, column}) => {

  const openEditor = useCallback(async () => {
    let ipt = window.prompt(column.title, value || '')

    if(ipt != null) {
      await row.$update({
        [column.name]: (ipt || null)
      })
    }
  }, [value])

  return (
    <div className="d-flex flex-row align-items-center justify-content-start">
      <Icon name={`fa-fw ${value || 'fas.fa-bars'}`} />
      <style jsx>{`
        div {
          font-size: 1.5rem;
        }
      `}</style>
      <Button color="link" className="" onClick={openEditor} >
        <Icon name="fas:edit" />
      </Button>
    </div>
  ) 
}

export default () => {

  const app = useContext(AppContext)

  const [state, actions] = useGlobal()

  const [rows, updateRows] = useImmer([])
  const [lang, setLang] = useState<string>(config.languages[0] as string)

  const columns: DataTableColumn[] = useMemo(() => ([
    { name: 'icon', title: '图标', width: 150, type: { formatter: IconEditorFormatter }},
    { name: `name$${lang}`, title: '名称', width: 200, type: 'text' },
    { name: `languages`, title: '语言', width: 100, type: { formatter: LanguageFormatter }},
  ]), [lang])

  const filter = useMemo(() => ({
    $site: state.site,
    languages: lang,
  }), [lang, state.site])

  const tableColumnExtensions = useMemo(() => {
    return columns.map(o => ({
      columnName: o.name,
      width: o.width,
    }))
  }, [columns])

  const projection: string[] = useMemo(() => ([
    ...flatten(columns.map(c => c['fields'] || c.name)), 
    '_id',
    'parent_id',
  ]), [columns])

  const loadData = useCallback(async () => {

    try {
      await app.loading(async () => {
        let { data: rows } = await api.category.find({
          query: {
            $select: projection,
            // $populate: 'post',
            $limit: 100,
            $sort: {
              order: 1,
            },
            ...filter,
          }
        })

        rows.forEach(row => {
          row.$update = async function(change: object) {
            await api.category.patch(this._id, change)
            let row = await api.category.get(this._id, {
              $select: projection,
            })
            updateRows(draft => {
              assign(find(draft, ['_id', row._id]), row)
            })
          }
        })

        updateRows(draft => rows)
      })

    } catch(e) {
      app.alert(isError(e) ? e.message : String(e), {type: "error"})
    }


  }, [projection, filter, updateRows])

  useEffect(() => {
    loadData()
  }, [loadData])

  

  return (
    <Card>
      <CardHeader className="d-flex flex-row align-items-center">
      <h2 className="lead m-0 mr-3">板块列表</h2>
      <LangNav lang={lang} setLang={setLang} languages={config.languages as string[]} />
      
      
      <Spacer />

      <ActionButton color="link" className="mx-1" onClick={loadData}>
        <Icon name="fas:sync-alt" />{' '}刷新
      </ActionButton>

      </CardHeader>
      <CardBody>
        <DataTable columns={columns} rows={rows} tree />
      </CardBody>
    </Card>
  );
};