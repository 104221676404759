import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import 'react-dropdown-tree-select/dist/styles.css'

import 'react-datetime/css/react-datetime.css'

import './assets/scss/classic.scss'
import './assets/scss/ieepa.scss'

import 'bootstrap-slider/dist/css/bootstrap-slider.css'

import React from 'react'
import ReactDOM from 'react-dom';

import { BrowserRouter as Router } from 'react-router-dom';

import * as yup from 'yup'


import App from './App';

import store, { Provider } from './store/data'

ReactDOM.render((
  <Provider value={store}>
    <Router>
      <App />
    </Router>
  </Provider>
  // <h1>dfa</h1>
), document.getElementById('root' as string));

// ReactDOM.render((
//   <div>
//     <p>Hello</p>
//     <style jsx>{`
//       p {
//         color: blue;
//       }
//     `}</style>
//   </div>
// ), document.getElementById('root'));


// console.log(yup.ObjectSchema)

// const PersonSchema: yup.ObjectSchema<{
//   name: string,
// }> = yup.object().shape({
//   name: yup.string(),
//   age: yup.number().integer(),
// })