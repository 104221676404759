import React from 'react'
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput
} from "reactstrap";

const Register = () => (
    <Card className="my-4">
      <CardHeader className="lead text-center">
        注册
      </CardHeader>
      <CardBody className="mx-sm-4">
          <div className="text-center">
            <img
              src={`${process.env.PUBLIC_URL}/img/icon.svg`}
              alt="IEEPA.ORG"
              className="m-4"
              style={{
                width: '8rem',
                height: '8rem',
              }}
            />
          </div>
          <Form>
            <FormGroup>
              <Label>邮箱</Label>
              <Input
                bsSize="lg"
                type="email"
                name="email"
                placeholder="请填写邮箱"
              />
              {/* <div className="mt-2">
                <Button color="primary" size="lg">
                  发送验证码
                </Button>
              </div> */}
            </FormGroup>
            <FormGroup>
              <Label>姓名</Label>
              <Input
                bsSize="lg"
                type="text"
                name="name"
                placeholder="请填写姓名"
              />
            </FormGroup>
            <FormGroup>
              <Label>密码</Label>
              <Input
                bsSize="lg"
                type="password"
                name="password"
                placeholder="请填写密码"
              />
            </FormGroup>
            <FormGroup>
              <Label>确认密码</Label>
              <Input
                bsSize="lg"
                type="password"
                name="password"
                placeholder="请确认密码"
              />
            </FormGroup>
            <div className="text-center mt-5">
              <Link to="/dashboard/default">
                <Button color="primary" size="lg">
                  注册
                </Button>
              </Link>
            </div>
          </Form>
      </CardBody>
      <CardFooter className="border-top-0 mx-sm-4">
        <div className="mt-2 text-right"> <Link to="/auth/login">用户登录</Link> </div>
      </CardFooter>
    </Card>
);

export default Register;
