import React, { useState, useCallback, useContext, useEffect, Fragment } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Spinner } from "reactstrap";

import { AppContext } from "../App";

import PendableButton from './PendableButton'

import delay from 'delay'

import arrayToTree from 'array-to-tree'

import {
  snakeCase,
  lowerCase,
  first,
  find,
  get,
} from 'lodash'

import { api, useGlobal } from '../store'
import { State, Actions } from '../store/types'

import { Icon } from '../utils';

const CategoryPicker = ({lang, isOpen, onPick, placeholder }) => {

  const app = useContext(AppContext)


  const [state, actions] = useGlobal()

  const [rows, setRows] = useState<any[] | null>(null)
  const [pending, setPending] = useState<boolean>(false)

  const loadData = useCallback(async () => {

    try {
      setPending(true)
      let rows: any[] = await api.category.find({
        query: {
          $select: ['icon', 'name$zh_cn', 'name$en_us', 'parent_id'],
          languages: lang,
          $site: state.site,
          $limit: -1,
        }
      })

      let tree = arrayToTree(rows, {
        customID: '_id'
      })

      setRows(tree)
    } catch(e) {
    } finally {
      setPending(false)
    }
  }, [lang])

  useEffect(() => {
    if(isOpen) {
      loadData()
    }
  }, [loadData])

  return (
    <>
      { pending && <Spinner /> }
      { placeholder && 
      <>
        <DropdownItem onClick={ e => onPick(null)}>{placeholder}</DropdownItem>
        <DropdownItem divider />
      </>
      }
      <div style={{
      maxHeight: '32rem',
      overflowX: 'hidden',
      overflowY: 'scroll',
    }}>
      { rows && rows.every(o => o != null) && rows.map(o => (
        <Fragment key={o._id}>
          <DropdownItem  disabled={false && o.children && o.children.length > 0} onClick={ e => onPick(o)}><Icon name={o.icon || 'fas.fa-bars'} className="mr-1" />{o[`name$${lang}`]}</DropdownItem>
          { o.children ? o.children.map(o => (
            <DropdownItem key={o._id} onClick={ e => onPick(o)}><span className="pl-3"><Icon name={o.icon || 'fas.fa-bars'} className="mr-1" />{o[`name$${lang}`]}</span></DropdownItem>
          )) : null}
        </Fragment>
      ))}
      </div>
    </>
  )

}


const CategoryDropdown = ({lang = null as (string | null), value = null as any, placeholder = null, disabled = false, direction = 'down', className = '', onChange = value => {} }) => {

  const [state, actions] = useGlobal()
  lang = lang || state.lang
  
  const [open, setOpen] = useState<boolean>(false)
  const toggleOpen = useCallback(() => {
    setOpen(!open)
  }, [open])

  return (
    <Dropdown direction="up" isOpen={open} toggle={toggleOpen}>
      <DropdownToggle disabled={disabled} tag={Button} className={className}>
        { (value?._id) ? get(value, `name$${lang}`, value._id) : (placeholder || '...')}
        <Icon name="fas:caret-down" className="ml-2" />
      </DropdownToggle>
      <DropdownMenu>
        <CategoryPicker lang={lang} isOpen={open} onPick={onChange} placeholder={placeholder} />
      </DropdownMenu>
    </Dropdown>
  )
}

// export default React.memo(CategoryDropdown, (prev, next) => {
//   // console.log('+++++', prev)
//   // console.log('-----', next)
//   // return get(prev, '_id', null) === get(next, '_id', null)
//   return true
// })

export default CategoryDropdown