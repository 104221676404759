import {
  CustomPaging,
  DataTypeProvider,
  DataTypeProviderProps,
  IntegratedSelection,
  PagingState,
  SelectionState,
  Sorting,
  SortingState,
  FilteringState,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  PagingPanel,
  Table,
  TableFixedColumns,
  TableHeaderRow,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-bootstrap4'
import classnames from 'classnames'
import {
  compact,
  debounce,
  get,
  set,
  includes,
  isEmpty,
  isError,
  last,
  lowerCase,
  pull,
  range,
  snakeCase,
  uniq,
  isString,
  dropWhile,
  find,
  findIndex,
  extend,
  omit,
  padStart,
} from 'lodash'
import moment, { Moment } from 'moment'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react'
import { Link } from 'react-router-dom'
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'
import { useImmer } from 'use-immer'
import { ARTICLE_STATUS, ARTICLE_STATUS_LABELS, SPACE_CHAR } from '../api'
import { AppContext } from '../App'
import ActionButton from '../components/Button'
import LanguageSelector from '../components/LanguageSelector'
import Spacer from '../components/Spacer'
import LangNav from '../components/LangNav'
import LanguageFormatter from '../components/LanguageFormatter'

import currency from 'currency.js'

import Datetime from 'react-datetime'

import DonationEditor from './DonationEditor'
import { Icon } from '../utils'

import { useTranslation } from 'react-i18next'

import { api, useGlobal } from '../store'
import { State, Actions } from '../store/types'

import clsx from 'clsx'
import Content from '../components/Content'
import config from '../config'

import QRCode from "qrcode-svg"

import { saveSvgAsPng } from 'save-svg-as-png'

const SortLabel = ({ column, onSort, children, direction }) => {
  return (
    <Button
      size="sm"
      color="link"
      disabled={!column.sortable}
      onClick={onSort}
      className="p-0"
    >
      {children}{' '}
      {direction && (
        <Icon
          name={`fas.fa-${direction === 'asc' ? 'chevron-up' : 'chevron-down'}`}
        />
      )}
    </Button>
  )
}

interface IProgress {
  rate?: number
  message: string
}

interface ITableData<T> {
  rows: T[]
  count?: number
  loading?: IProgress | string | boolean
  error?: any
}

interface ITreeData<T> {
  rows: T[]
  index: { [index: string]: T }
  tree: any
}

const DEFAULT_CELL_COMPONENT = ({ value }) => {
  return String(value)
}

const createColumns = (updateRows, lang) => {
  return [
    {
      name: `donor`,
      type: String,
      width: '10rem',
      title: '捐赠者',
      sortable: true,
      wrap: true,
      Component: ({ value, column, index }) => {
        const openEditor = useCallback(async () => {
          let ipt = window.prompt(column.title, value || '')

          if (ipt != null) {
            updateRows(draft => {
              draft[index][column.name] = ipt || ''
            })
          }
        }, [value])

        return (
          <div className="d-flex flex-row align-items-center justify-content-start">
            <span className={clsx(value ? 'text-body' : 'text-muted')}>
              {value || ''}
            </span>
            <Button color="link" className="" onClick={openEditor}>
              <Icon name="fas:edit" />
            </Button>
          </div>
        )
      },
    },

    {
      name: `amount`,
      type: String,
      width: '8rem',
      title: '捐赠金额',
      align: 'right',
      sortable: true,
      wrap: false,
      Component: ({ value, column, index }) => {
        const openEditor = useCallback(async () => {
          let ipt = window.prompt(column.title, value || '')

          if (ipt) {
            let n = parseFloat(ipt)
            if (n) {
              updateRows(draft => {
                draft[index][column.name] = Math.floor(n * 100) / 100
              })
            }
          }
        }, [value])

        return (
          <div className="d-flex flex-row align-items-center justify-content-end">
            <span className={clsx(value ? 'text-body' : 'text-muted')}>
              ¥{currency(value).format()}
            </span>
            <Button color="link" className="" onClick={openEditor}>
              <Icon name="fas:edit" />
            </Button>
          </div>
        )
      },
    },

    {
      name: `date`,
      type: String,
      title: '日期',
      width: '8rem',
      sortable: true,
      wrap: false,
      Component: ({ value, row, index }) => {
        // return moment(value).format('YYYY-MM-DD')
        return (
          <Datetime
            inputProps={{ readOnly: true }}
            value={moment(value)}
            //@ts-ignore
            onChange={(value: Moment) =>
              updateRows(draft => {
                draft[index].date = value.toDate()
              })
            }
            locale="zh-cn"
            dateFormat={'YYYY-MM-DD'}
            timeFormat={false}
            closeOnSelect={true}
            // input={false}
          />
        )
      },
    },

    {
      name: `type`,
      type: String,
      width: '8rem',
      title: '类型',
      sortable: true,
      wrap: false,
      Component: ({ value, column, index }) => {
        const openEditor = useCallback(async () => {
          let ipt = window.prompt(column.title, value || '')

          if (ipt != null) {
            updateRows(draft => {
              draft[index][column.name] = ipt || ''
            })
          }
        }, [value])

        return (
          <div className="d-flex flex-row align-items-center justify-content-start">
            <span className={clsx(value ? 'text-body' : 'text-muted')}>
              {value || ''}
            </span>
            <Button color="link" className="" onClick={openEditor}>
              <Icon name="fas:edit" />
            </Button>
          </div>
        )
      },
    },

    {
      name: `remark`,
      type: String,
      width: '12rem',
      title: '备注',
      wrap: true,
      Component: ({ value, column, index }) => {
        const openEditor = useCallback(async () => {
          let ipt = window.prompt(column.title, value || '')

          if (ipt != null) {
            updateRows(draft => {
              draft[index][column.name] = ipt || ''
            })
          }
        }, [value])

        return (
          <div className="d-flex flex-row align-items-center justify-content-start">
            <span className={clsx(value ? 'text-body' : 'text-muted')}>
              {value || ''}
            </span>
            <Button color="link" className="" onClick={openEditor}>
              <Icon name="fas:edit" />
            </Button>
          </div>
        )
      },
    },

    {
      name: `signAt`,
      type: String,
      title: '签发日期',
      width: '8rem',
      sortable: true,
      wrap: false,
      Component: ({ value, row, index }) => {
        // return moment(value).format('YYYY-MM-DD')
        return (
          <Datetime
            inputProps={{ readOnly: true }}
            value={moment(value)}
            //@ts-ignore
            onChange={(value: Moment) =>
              updateRows(draft => {
                draft[index].signAt = value.toDate()
              })
            }
            locale="zh-cn"
            dateFormat={'YYYY-MM-DD'}
            timeFormat={false}
            closeOnSelect={true}
            // input={false}
          />
        )
      },
    },

    {
      name: 'public',
      width: '6rem',
      title: '是否公开',
      wrap: false,
      Component: ({ value, column, index }) => {
        return (
          <div className="d-flex flex-row align-items-center justify-content-center">
            <Input
              type="checkbox"
              checked={value}
              onChange={e => {
                updateRows(draft => {
                  draft[index][column.name] = e.currentTarget.checked
                })
              }}
            />
          </div>
        )
      },
    },

    {
      name: '_action',
      width: '18rem',
      title: ' ',
      wrap: false,
      align: 'right',
      Component: ({ value, row, column, index, length }) => {

        const ref = useRef(null)

        let svg = useMemo(() => new QRCode({
          content: `${process.env.REACT_APP_WWW_URL}/donation/${row.id}`,
          // padding: 0,
          // width: 128,
          // height: 120,
        }).svg(), [row.id])

        useEffect(() => {
          if(ref.current) {
            //@ts-ignore
            ref.current.innerHTML = svg
          }
        }, [ref.current, svg])

        const downloadQrcode = useCallback(async () => {
          //@ts-ignore
          saveSvgAsPng(ref.current.children[0], `qrcode-${row.id}.png`)
        }, [ref.current])


        const up = useCallback(async () => {
          if (index > 0) {
            updateRows(draft => {
              let temp = draft[index]
              draft[index] = draft[index - 1]
              draft[index - 1] = temp
            })
          }
        }, [index])

        const down = useCallback(async () => {
          if (index < length - 1) {
            updateRows(draft => {
              let temp = draft[index]
              draft[index] = draft[index + 1]
              draft[index + 1] = temp
            })
          }
        }, [index, length])

        const insert = useCallback(async () => {
          updateRows(draft => {
            draft.splice(index + 1, 0, {
              id: `2020-JZ-${padStart(length + 1, 4, '0')}`,
              amount: 1000,
              date: new Date(),
              public: true,
              type: '银行转帐',
            })
          })
        }, [index])

        const remove = useCallback(async () => {
          let ipt = window.confirm('确认删除？')

          if (ipt) {
            updateRows(draft => {
              draft.splice(index, 1)
            })
          }
        }, [index])

        return (
          <div className="d-flex flex-row align-items-center justify-content-end">

            <div className="d-none" ref={ref} />

            <Button
              color="link"
              className="mr-3"
              disabled={!row.public}
              onClick={downloadQrcode}
            >
              <Icon name="fas:qrcode" className="lead text-info" />
            </Button>

            <Button
              color="link"
              className=""
              onClick={up}
              disabled={index <= 0}
            >
              <Icon name="fas:arrow-up" className="lead text-secondary" />
            </Button>
            <Button
              color="link"
              className=""
              onClick={down}
              disabled={index >= length - 1}
            >
              <Icon name="fas:arrow-down" className="lead text-secondary" />
            </Button>
            <Button color="link" className="" onClick={insert}>
              <Icon name="fas:plus-circle" className="lead text-primary" />
            </Button>
            <Button color="link" className="" onClick={remove}>
              <Icon name="fas:minus-circle" className="lead text-danger" />
            </Button>
          </div>
        )
      },
    },

    // { name: 'public', title: '公开', width: '8rem', align: 'right',  type: Function, Component: ({value:status, row}) => {

    //   let {_id, rank = 1} = row

    //   const app = useContext(AppContext)

    //   return (
    //     <div className="d-flex flex-row justify-content-end align-items-center">
    //       { (status === ARTICLE_STATUS.PUBLIC) && <ActionButton color="danger" size="sm" className="ml-1" onClick={async (e) => {
    //         let data = { status: ARTICLE_STATUS.ARCHIVED }
    //         await api.donation.patch(_id, data)
    //         updateRows(draft => {
    //           extend(find(draft, ['_id', _id]), data)
    //         })
    //       }}>撤下</ActionButton> }

    //       { (status === ARTICLE_STATUS.ARCHIVED) &&
    //       <>
    //       <ActionButton color="primary" size="sm" className="ml-1" onClick={async (e) => {
    //         let data = { status: ARTICLE_STATUS.PUBLIC }
    //         await api.donation.patch(_id, data)
    //         updateRows(draft => {
    //           extend(find(draft, ['_id', _id]), data)
    //         })
    //       }}>发布</ActionButton>

    //       <ActionButton color="danger" size="sm" className="ml-1" onClick={async (e) => {
    //         if(window.confirm('确定删除？')) {
    //           await api.donation.remove(_id)
    //           updateRows(draft => {
    //             draft.splice(findIndex(draft, ['_id', _id]), 1)
    //           })
    //         }
    //       }}>删除</ActionButton>

    //       </>
    //       }

    //     </div>
    //   )
    // } },
  ]
}

export const KeywordsEditor = ({
  label = '...',
  value = [],
  onChange = (v: string[]) => {},
  disabled = false,
  className = '',
}) => {
  value = compact(uniq(value))

  const [open, setOpen] = useState(false)
  const toggleOpen = useCallback(() => {
    setOpen(!open)
  }, [open])

  const [input, setInput] = useState<string>('')

  const [words, updateWords] = useImmer<string[]>(value)

  // useEffect(() => {
  //   updateWords(draft => value)
  // }, [value])

  const onOpened = useCallback(() => {
    updateWords(() => value)
  }, [value])

  return (
    <div>
      <ActionButton
        color="info"
        size="sm"
        className={className}
        onClick={toggleOpen}
      >
        {label}
      </ActionButton>
      <Modal isOpen={open} toggle={toggleOpen} onOpened={onOpened}>
        <ModalHeader toggle={toggleOpen}>
          关键字
          {words.map(w => (
            <Badge key={w} onClick={() => {}} color="info" pill className="m-1">
              {w}{' '}
              <span
                onClick={() => {
                  updateWords(draft => pull(draft, w))
                }}
              >
                <Icon name="fas:times-circle" />
              </span>
            </Badge>
          ))}
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-row justify-content-center align-items-center">
            <Input
              type="text"
              text={input}
              onChange={e => setInput(e.target.value)}
              className="flex-grow-1 flex-shrink-1"
            />
            <Button
              color="link"
              className="text-nowrap"
              onClick={() => {
                updateWords(draft => {
                  if (!includes(draft, input)) draft.push(input)
                })
              }}
            >
              添加
            </Button>
          </div>
          <div
            className="d-flex flex-row justify-content-center align-items-center"
            style={{
              height: '20rem',
            }}
          ></div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              toggleOpen()
              onChange(words)
            }}
          >
            确定
          </Button>{' '}
          <Button color="secondary" onClick={toggleOpen}>
            取消
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

const DateTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={({ value }) => moment(value).format('YYYY-MM-DD')}
    {...props}
  />
)

const DataTable = ({ rows, updateRows }) => {
  const app = useContext(AppContext)

  const [lang, setLang] = useState<string>(config.languages[0] as string)

  const columns = useMemo(() => createColumns(updateRows, lang), [
    updateRows,
    lang,
  ])

  const [selection, select] = useState<any[]>([])

  const [commonFilters, updateCommonFilters] = useImmer({
    status: ARTICLE_STATUS.PUBLIC,
  } as any)

  const [textFilters, setTextFilters] = useState({})

  const [match, setMatch] = useState<string>('')
  const search = useCallback(() => {
    let words = match
      .split(/\s/)
      .map(w => w.trim())
      .filter(w => w.length > 0)

    if (words.length > 0) {
      setTextFilters({
        $text: {
          $search: words,
        },
      })
      setTextFilters({
        $and: words.map(w => {
          let re = new RegExp(w, 'i')
          return {
            $or: [{ donor: { $regex: re } }],
          }
        }),
      })
    } else {
      setTextFilters({})
    }
  }, [match, setTextFilters])

  const filters: any = useMemo(
    () => ({
      ...commonFilters,
      ...textFilters,
      languages: lang,
    }),
    [commonFilters, textFilters, lang]
  )

  const [sort, setSort] = useState<{ [key: string]: 1 | -1 }>({
    rank: -1,
  })

  const loadData = useCallback(async () => {
    // let where = omit(filters, 'category')
    // if(filters.category && filters.category._id) {
    //   where.category_id = filters.category._id
    // }

    try {
      await app.loading(async () => {
        let { donations: data } = await api.site.get('ieepa', {
          query: {
            $select: ['donations'],
          },
        })

        updateRows(draft => compact(data))
      })
    } catch (e) {
      app.alert(isError(e) ? e.message : String(e), {type: "error"})
    }

    return
  }, [filters, sort, lang, columns])

  const saveData = useCallback(async () => {
    try {
      await app.loading(async () => {
        await api.site.patch('ieepa', {
          donations: compact(rows),
        })
      })
    } catch (e) {
      app.alert(isError(e) ? e.message : String(e), {type: "error"})
    } 

    return
  }, [rows])

  const withdraw = useCallback(async () => {
    // await update(coll, selection, { status: ARTICLE_STATUS.ARCHIVED })
  }, [selection])

  const publish = useCallback(async () => {
    // await update(coll, selection, { status: ARTICLE_STATUS.ACTIVE })
  }, [selection])

  useEffect(() => {
    loadData()
  }, [loadData])

  // console.log(compact(ids).map(id => get(data, [coll, id], {_id:id})))

  return (
    <Card>
      <CardHeader className="d-flex flex-row align-items-center">
        {/* <Nav className="align-items-center">
      { Object.entries(ARTICLE_STATUS_LABELS).map(([value, label]) => (
        <NavItem key={value}>
          <NavLink
            className={classnames({ active: commonFilters.status === value })}
            onClick={() => updateCommonFilters(filters => { filters.status = value })}
          >
            {label}
          </NavLink>
        </NavItem>
      ))}
      </Nav>
       */}

        <div className="position-relative">
          <input
            type="text"
            onReset={() => console.log('======')}
            placeholder="输入关键字，之间用空格分开"
            value={match}
            onChange={e => setMatch(e.currentTarget.value)}
            className="form-control ml-5"
            style={{ width: '20rem' }}
          />
          {textFilters && Object.keys(textFilters).length > 0 && (
            <div
              className="position-absolute"
              style={{ right: 0, top: '50%', transform: 'translate(0, -50%)' }}
            >
              <Button
                className="mx-1"
                color="link"
                onClick={() => {
                  setMatch('')
                  setTextFilters({})
                }}
              >
                <Icon name="fas:times" />
              </Button>
            </div>
          )}
        </div>
        <ActionButton color="link" className="mx-1" onClick={search}>
          <Icon name="fas:search" /> 查找
        </ActionButton>

        <Spacer />

        <ActionButton color="link" className="mx-1" onClick={loadData}>
          <Icon name="fas:sync-alt" /> 刷新
        </ActionButton>

        <ActionButton color="link" className="mx-1" onClick={saveData}>
          <Icon name="fas:save" /> 保存
        </ActionButton>

        {/* <Link to={`/donation/new`} className="btn btn-link mx-1">
      <Icon name="fas:plus" />{' '}新建...
      </Link> */}
      </CardHeader>
      <CardBody>
        <Grid rows={rows} getRowId={row => rows.indexOf(row)} columns={columns}>
          {/* <Toolbar rootComponent={ToolbarComponent} /> */}

          {/* <EditingState onCommitChanges={() => {}} /> */}

          {/* <FieldTypeProvider columns={columns} /> */}

          {/* <FilteringState
            filters={Object.entries(filters).map(([name, value]) => ({columnName:name, value}))}
            onFiltersChange={ filters => updateFilters(draft => {
              filters.forEach(({columnName, value}) => (draft[columnName] = value ))
            })}
          /> */}

          <SortingState
            sorting={Object.entries(sort).map(([name, value]) => ({
              columnName: name,
              direction: value === 1 ? 'asc' : 'desc',
            }))}
            onSortingChange={(sorting: Sorting[]) => {
              const st = last(sorting)
              st &&
                setSort({ [st.columnName]: st.direction === 'asc' ? 1 : -1 })
            }}
          />

          <SelectionState selection={selection} onSelectionChange={select} />

          <IntegratedSelection />

          {/* <IntegratedFiltering /> */}
          {/* <IntegratedSorting /> */}
          {/* <IntegratedPaging /> */}

          {/* <CurrencyTypeProvider for={currencyColumns} /> */}
          {/* <DragDropProvider /> */}

          {/* <DateTypeProvider for={columns.filter(({name, type}) => (type === Date)).map(row => row.name)} /> */}

          {columns
            .filter(col => !col.name.startsWith('$'))
            .map(col => (
              <DataTypeProvider
                key={col.name}
                for={[col.name]}
                formatterComponent={({ row, column }) => {
                  let Comp = col.Component || DEFAULT_CELL_COMPONENT
                  //@ts-ignore
                  return (
                    <Comp
                      value={get(row, col.name, null)}
                      row={row}
                      column={column}
                      index={rows.indexOf(row)}
                      length={rows.length}
                    />
                  )
                }}
              />
            ))}

          <Table
            messages={{
              noData: '没有数据',
            }}
            columnExtensions={columns.map(
              ({ name, align = 'left', width, wrap = false }) => ({
                columnName: name,
                align: align as 'left' | 'center' | 'right',
                width: width || undefined,
                wordWrapEnabled: wrap,
              })
            )}
          />

          <TableSelection highlightRow showSelectAll selectByRowClick={false} />

          <TableHeaderRow showSortingControls sortLabelComponent={SortLabel} />

          {/* <TableFilterRow showFilterSelector cellComponent={FilterCellComponent} /> */}

          <TableFixedColumns
            leftColumns={[TableSelection.COLUMN_TYPE]}
            rightColumns={[]}
          />
        </Grid>
      </CardBody>

      <CardFooter className="d-flex flex-row justify-content-start align-items-center">
        {selection.length ? (
          <div className="mr-2">选中{selection.length}条</div>
        ) : null}

        {/* { (filters.status === ARTICLE_STATUS.PUBLIC) &&
        <ActionButton disabled={selection.length === 0} color="link" className="mr-2 btn-outline-primary" onClick={withdraw}>
          撤下
        </ActionButton>
        }

        { (filters.status === ARTICLE_STATUS.ARCHIVED) &&
        <ActionButton disabled={selection.length === 0} color="link" className="mr-2 btn-outline-primary" onClick={publish}>
          上架
        </ActionButton>
        } */}

        <Spacer />
      </CardFooter>
    </Card>
  )
}

const Donation = ({ match, location, history }) => {
  let { id } = match.params

  console.log(`*************`, id)

  if (isString(id) && id.length !== 24) {
    id = null
  }

  const closeEditor = useCallback(() => {
    history.push('/donation')
  }, [])

  const [rows, updateRows] = useImmer([])

  return (
    <>
      <Container fluid className="p-0">
        <DataTable rows={rows} updateRows={updateRows} />
      </Container>

        <DonationEditor
          open={id !== undefined}
          history={history}
          id={id}
          onClose={closeEditor}
          updateRows={updateRows}
        />
    </>
  )
}

export default Donation
