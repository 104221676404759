import { ComponentType, useContext, ReactElement } from 'react'
import { id } from 'date-fns/locale'
import * as yup from 'yup'
import React, { CSSProperties } from 'react'

import clsx from 'clsx'

import * as FeatherIcons from 'react-feather'

import { camelCase, upperFirst, isString } from 'lodash'

export const Icon = ({ name = '', className = '', style = {} }) => {
  if (!name.includes(':')) name = process.env.REACT_APP_ICON_FAMILY + ':' + name

  let [family, icon] = name.split(':')

  const IconComponent = Families[family]

  if (!IconComponent) {
    return null
  }

  return (
    <IconComponent
      name={icon}
      className={className}
      style={style}
    />
  )
}

//@ts-ignore
let BootstrapIconComponent: React.ComponentType<{
  name: string
  style?: CSSProperties
  className?: string
}> = null

Icon.config = (family: string, data: any ) => {
  if(family === 'bs' && isString(data)) {
    BootstrapIconComponent = ({name, style = {}, className = ''}) => (
      <svg width={style?.fontSize || '1em'} height={style?.fontSize || '1em'} className={className}>
        <use xlinkHref={`${data}#${name}`}/>
      </svg>
    )
  }
}

const Families: {
  [key: string]: React.FC<{
    name: string
    style?: CSSProperties
    className?: string
  }>
} = {
  bs: (props) => {
    return BootstrapIconComponent ? <BootstrapIconComponent {...props} /> : null
  },

  fi: ({ name, style = {} }) => {
    const { fontSize = '1rem' } = style

    const IconComponent = FeatherIcons[upperFirst(camelCase(name))]
    return IconComponent ? <IconComponent size={fontSize} /> : null
  },

  //@ts-ignore
  fas: faFamilyOf('fas'),
  //@ts-ignore
  far: faFamilyOf('far'),
  //@ts-ignore
  fal: faFamilyOf('fal'),
  //@ts-ignore
  fad: faFamilyOf('fad'),
  //@ts-ignore
  fab: faFamilyOf('fab'),
}

function faFamilyOf(manner: 'fas' | 'far' | 'fal' | 'fad' | 'fab') {
  return ({ name, className = '', style = null }) => {
    return (
      <i
        className={clsx('fa-fw', manner, `fa-${name}`, className)}
        style={{
          // fontSize: size/16 + 'rem',
          //@ts-ignore
          ...style,
        }}
      />
    )
  }
}

export type AppConfigType = {
  [key: string]: any
}

export type AppStateType = {
  [key: string]: any
}

// export interface IApi {
//   auth: () => Promise<any | null>
//   load: (name: string, id: string) => Promise<any | null>
// }

export type MessageLevelType = 'info' | 'success' | 'error' | 'warning'

interface IView {
  push<P = {}>(
    content:
      | string
      | [string, P]
      | React.ComponentType<P>
      | [React.ComponentType<P>, P]
      | ReactElement,
    opt?: {
      history?: boolean
    }
  ): void
}

export type TFilter = {
  [key: string]: any
}

export type TEntity = {
  _id?: string,
  [key: string]: any,
}

export type TQueryOption = {
  limit?: number,
  skip?: number,
}

export type TQueryResult = {
  total: number,
  limit: number,
  skip: number,
  data: TEntity[],
}

export interface IAPI {
  login(auth?: {
    strategy: string
    [key: string]: any
  }): Promise<{ [key: string]: any }>

  logout()

  fetch<T>(url: string, schema?: yup.SchemaOf<T>): Promise<T>

  upload(file: File, dir?: string, opt?: {
    onProgress?: (n: number) => void
  })

  find(
    name: string,
    filter: TFilter,
    opt?: TQueryOption,
  ): Promise<TQueryResult>

  findOne(
    name: string,
    filter: TFilter,
  ): Promise<TEntity>

  load(name: string, id: string): Promise<TEntity | null>

  create(name: string): (data: TEntity) => Promise<TEntity>
  create(name: string, data: TEntity): Promise<TEntity>

  patch(name: string, id: string): (change: Partial<any>) => Promise<void>
  patch(name: string, id: string, change: Partial<any>): Promise<void>

  remove(name: string, id: string): Promise<void>
}

export interface IApp extends IView, IAPI {
  //   state: AppStateType
  //   updateState: (updater: (draft: AppStateType) => void) => void

  // readonly id: string
  readonly config: AppConfigType

  loading<T>(
    action: () => Promise<T>,
    opt?: {
      title?: string
      modal?: boolean
      [key: string]: any
    }
  ): Promise<T>

  alert(
    content: string,
    opt?: {
      type?: MessageLevelType
      title?: string
      modal?: boolean
      autoDismiss?: boolean
      [key: string]: any
    }
  ): Promise<void>

  confirm(
    content: string,
    opt?: {
      title?: string
      modal?: boolean
      [key: string]: any
    }
  ): Promise<boolean>

  prompt<P = {}>(
    content:
      | string
      | [string, P]
      | React.ComponentType<P>
      | [React.ComponentType<P>, P]
      | ReactElement,
    opt?: {
      title?: string
      modal?: boolean
      history?: boolean
      [key: string]: any
    }
  ): Promise<any>

  // back(result?: any): void
}

export type DialogInstance = {
  name: string
  Component: ComponentType<{}>
  param?: {
    [key: string]: any
  }
}

export type PageInstance = {
  name: string
  Component: ComponentType<{ name: string }>
  param?: {
    [key: string]: any
  }
}
