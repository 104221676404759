import React from 'react'

import {
  Nav,
  NavItem,
  NavLink,
  Input,
} from 'reactstrap'

import {
  includes,
  pull,
  uniq,
} from 'lodash'

import clsx from 'clsx'
import config from '../config'

export default ({lang, setLang, languages = [] as string[], setLanguages = null}) => {
  return (
    <Nav>
        { config?.languages?.map(l => (
          <NavItem key={l}>
            <div className="d-flex align-items-center mr-3">
            <NavLink active={l === lang} disabled={!includes(languages, l)} onClick={() => setLang(l)} className="p-0">
              <img className={clsx({
                active: (l === lang),
                disabled: !includes(languages, l),
              })} src={`${process.env.PUBLIC_URL}/img/flag_${l}.svg`} />
            </NavLink>
            { setLanguages &&
            <Input type="checkbox" checked={includes(languages, l)} onChange={({target: {checked}}) => {
              let arr = [...languages]
              checked ? arr.push(l as string) : pull(arr, l)
              arr = uniq(arr)
              if(arr.length > 0) {
                //@ts-ignore
                setLanguages(arr)
                setLang(checked ? l : arr[0])
              }
            }} className="d-block position-relative ml-1" />
            }
            </div>
          </NavItem>
        ))}
        <style jsx>{`
          img {
            width: 2rem;
            height: 2rem;
            filter: opacity(0.25);
          }

          img.disabled {
            filter: grayscale(1) opacity(0.5);
          }

          img.active {
            filter: none;
          }
        `}</style>
        </Nav>
  )
}